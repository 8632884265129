import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 100%;
    position: relative;
    
    .loading-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(255, 255, 255, .7);
        z-index: 9;
    }
    .component-spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        width: auto;
        height: auto;
    }

    
    
    .panel-big-calendar {
        .rbc-time-content {
            .rbc-day-slot {
                .work-hour {
                    position: absolute;
                    display: block;
                    width: 100%;
                    background: #C1C1C1;
                    opacity: .25;

                    &.start {
                        top: 0;
                    }

                    &.end {
                        bottom: 0;
                    }
                }
            }
        }

        .rbc-event {
            display: flex !important;
            flex-direction: row !important;
            border-radius: 0 !important; 
            color: white !important;
            text-transform: capitalize;
            padding: 0 !important;

            .rbc-event-label {
                display: block !important;
                width: 100% !important;
                padding: 0 .3em;
            }    
        }

        .rbc-calendar {
            .rbc-time-view {
                position: relative;

                .rbc-event {
                    display: flex !important;
                    flex-direction: row !important;
                    border-radius: 0 !important; 
                    color: white !important;
                    text-transform: capitalize;
                    padding: 0 !important;

                    .rbc-event-label {
                        display: block !important;
                        width: 100% !important;
                        padding: 0 .3em;
                    }

                    .rbc-event-content {
                        width: 100%;
                        height: 100%; 
                    }
                }
            }
        }
    }


    @media (max-width: ${variables.tabletS}) {}
`;
