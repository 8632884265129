import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_PAYU_SINGLE_PAYMENT_URI } from 'Consts/apiResources';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';

export default class AdeptUserOfferGateway extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            singlePaymentUri: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object.isRequired,
    };

    static defaultProps = {
        data: null,
    };

    state = {
        singlePaymentUri: {
            uri: null,
            isLoaded: false,
            isLoading: false,
        },
        isLoading: false,
    };

    componentDidMount = () => {
        const { actions, data } = this.props;
        actions.singlePaymentUri({ id: data.id })
            .then(response => {
                const result = response.payload[API_RESOURCE_PAYU_SINGLE_PAYMENT_URI];
                this.setState(prevState => ({
                    singlePaymentUri: {
                        ...prevState.singlePaymentUri,
                        uri: result,
                        isLoaded: true,
                    },
                }));
            });
    }

    render() {
        const { singlePaymentUri } = this.state;
        
        return (
            <StyledComponent
                className="adept-user-offers-gateway"
                styles={require('./styles')}
            >

                {singlePaymentUri && singlePaymentUri.isLoaded && singlePaymentUri.uri && (
                    <Button
                        type="submit"
                        onClick={() => { window.location.href = singlePaymentUri.uri; }}
                        style="hollow"
                        buttonProps={{
                            formTarget: '_blank',
                        }}
                    >
                        Płacę z PayU
                    </Button>
                )}

            </StyledComponent>
        );
    }
} 