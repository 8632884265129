import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { API_RESOURCE_USER_OFFER_TERMINATION_DATES } from 'Consts/apiResources';

import { toApiFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelUserOffersCancelEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
        actions: PropTypes.shape({
            cancel: PropTypes.func.isRequired,
            terminationDate: PropTypes.func.isRequired,
        }).isRequired,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {
            ...this.props.data, //eslint-disable-line react/destructuring-assignment
        },
    };

    componentDidMount = () => {
        const { formState } = this.state;
        const { data, actions } = this.props;

        this.setState({
            formState: {
                ...data,
            },
        });

        actions.terminationDate({ id: data.id }).then(response => {
            const result = response.payload[API_RESOURCE_USER_OFFER_TERMINATION_DATES];

            this.setState({
                formState: {
                    ...result,
                    ...formState,
                },
            });
        });
    }

    onSubmit = () => {
        const { formState } = this.state;
        const { data, actions, onSuccess } = this.props;
        
        return actions.cancel({
            id: data.id,
            terminatedAt: toApiFormat(formState.terminatedAt, 'datetime', true),
            activeTo: toApiFormat(formState.activeTo, 'datetime', true),
            terminatedTo: toApiFormat(formState.terminatedTo, 'datetime', true),
            terminateReason: formState.terminateReason,
        })
            .then(() => {
                onSuccess();
            });
    }

    render() {
        const { formState } = this.state;
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="panel-user-offers-cancel-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState(prevState => ({
                                ...prevState.formState,
                                formState,
                            }));
                        },
                        elements: [{
                            isVisible: true,
                            type: 'datePicker',
                            name: 'terminatedAt',
                            label: 'Data zgłoszenia wypowiedzenia',
                        }, {
                            isVisible: true,
                            type: 'datePicker',
                            name: 'activeTo',
                            label: 'Data końca okresu wypowiedzenia',
                        }, {
                            isVisible: true,
                            type: 'datePicker',
                            name: 'terminatedTo',
                            label: 'Data terminacji zamówienia',
                        }, {
                            isVisible: true,
                            type: 'textarea',
                            name: 'terminateReason',
                            label: 'Powód wypowiedzenia',
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}