import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { 
    API_RESOURCE_EVENT, 
    API_RESOURCE_USER_OFFERS,
    API_RESOURCE_PRODUCTS, 
    API_RESOURCE_USERS, 
    API_RESOURCE_LOCATIONS,
} from 'Consts/apiResources';
import { 
    ADMIN_EVENTS_MANAGE, 
    ADMIN_USERS_MANAGE, 
    ADMIN_USER_OFFERS_MANAGE,
    ADMIN_EVENT_CYCLE,
} from 'Consts/routes';
import { USER_ROLE_TRAINER, USER_ROLE_ADEPT } from 'Consts/userRoles';
import {
    EVENT_PAID_STATUS_PAID,
    EVENT_PAID_STATUS_NOT_PAID,
    EVENT_PAID_STATUS_CREDIT,
    EVENT_DONE_STATUS_DONE,
    EVENT_DONE_STATUS_NOT_DONE,
    EVENT_DONE_STATUS_CANCELED,
    EVENT_CATEGORIES,
    EVENT_CATEGORY_DEFAULT,
    EVENT_CATEGORY_PRIVATE,
    CANCEL_DIAGNOSTIC_REASON_TO_POSTPONE,
    CANCEL_DIAGNOSTIC_REASON_NO_ANSWER,
    CANCEL_DIAGNOSTIC_REASON_CANCELED,
} from 'Consts/events';
import { USER_OFFER_TYPE_DIAGNOSTIC } from 'Consts/users';
import { USER_PERMISSION_GROUP_EVENTS } from 'Consts/userPermissions';
import {
    USER_OFFER_STATUS_ACTIVE,
    USER_OFFER_STATUS_CLOSED,
} from 'Consts/userOffers';

import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';
import { getPaidStatusLabel, getDoneStatusLabel } from 'Utils/event';
import { getName as getLocationName } from 'Utils/location';
import { fromSelectObject } from 'Utils/object';
import { toApiFormat as dateToApiFormat } from 'Utils/date';
import { isSlotOccupied } from 'Utils/userWorkHours';
import { hasPermission } from 'Utils/permissions';
import { getDurationTypeLabel } from 'Utils/product';
import { translateTimeUnit, getFormattedDate, toAppFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';
import ModalUserEditor from 'Components/admin/modals/UserEditor';
import ModalUserOfferEditor from 'Components/admin/modals/UserOfferEditor';
import { TAB_EVENTS_LIST } from 'Components/pages/admin/UserOffersManage/component';
import ModalEventCycleEnd from 'Components/admin/modals/EventCycleEnd';

export default class PanelEventsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            remove: PropTypes.func.isRequired,
            reset: PropTypes.func.isRequired,
            setPaidStatus: PropTypes.func.isRequired,
            setDoneStatus: PropTypes.func.isRequired,
            listUserOffers: PropTypes.func.isRequired,
            listProducts: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            listLocations: PropTypes.func.isRequired,
            cancelDiagnostic: PropTypes.func.isRequired,
            confirmRequest: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
        userWorkHours: PropTypes.object.isRequired,
        displayModeModal: PropTypes.bool,
        onRemove: PropTypes.func,
        profile: PropTypes.object.isRequired,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
        displayModeModal: false,
    };

    state = {
        formState: {
            type: null,
        },
        products: [],
        isModalEventCycleEndVisible: false,
        isModalCreateUserVisible: false,
        isModalCreateUserOfferVisible: false,
    };

    componentDidMount = () => {
        const { data, predefinedState } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...predefinedState,
                ...this.dataToFormState(data || {}), 
            },
        }));
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { data } = this.props;
        const { formState } = this.state;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }

        if (data && data.product && data.product.durationValue && data.product.durationType 
            && (JSON.stringify(formState.startAt) !== JSON.stringify(prevState.formState.startAt))
        ) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    endAt: moment(formState.startAt).add(data.product.durationValue, data.product.durationType),
                },
            }));
        }
    }

    dataToFormState = data => {
        const { predefinedState } = this.props;

        return {
            ...data,
            userOfferId: predefinedState.userOffer
                ? { value: predefinedState.userOffer.id, label: predefinedState.userOffer?.name }
                : data.userOffer
                    ? { value: data.userOffer.id, label: data.userOffer?.name }
                    : null,
            productId: data.product
                ? { value: data.product.id, label: data.product?.name }
                : null,
            leadId: predefinedState.lead
                ? { value: predefinedState.lead.id, label: getFullName(predefinedState.lead).label }
                : data.lead
                    ? { value: data.lead.id, label: getFullName(data.lead).label }
                    : null,
            locationId: data.location
                ? { value: data.location.id, label: getLocationName(data.location).label }
                : null,
            startAt: data.startAt && moment(data.startAt).toDate(),
            endAt: data.endAt && moment(data.endAt).toDate(),
            category: data.userOffer || predefinedState.userOfferId || data.user || predefinedState.userId || predefinedState.user
                ? EVENT_CATEGORY_DEFAULT
                : data.category,
            userId: data.user 
                ? { value: data?.user?.id, label: getFullName(data.user)?.label } 
                : predefinedState.user 
                    ? { value: predefinedState?.user?.id, label: getFullName(predefinedState.user)?.label }
                    : null,
        };
    }

    formStateToApi = data => ({
        ...data,
        category: fromSelectObject(data.category),
        userOfferId: fromSelectObject(data.userOfferId),
        productId: fromSelectObject(data.productId),
        leadId: fromSelectObject(data.leadId),
        locationId: fromSelectObject(data.locationId),
        startAt: dateToApiFormat(data.startAt, 'datetime', true),
        endAt: dateToApiFormat(data.endAt, 'datetime', true),
    })

    onSubmit = formState => {
        const { data, userWorkHours } = this.props;

        return new Promise((resolve, reject) => {
            if (moment(formState.startAt).isBefore(moment())) {
                if (window.confirm('Czy na pewno chcesz zaplanować trening w przeszłości?')) {
                    if (isSlotOccupied(formState, userWorkHours)) { // resolve if slot is not in users work hours
                        if (window.confirm('Czy na pewno chcesz zaplanować trening poza godzinami pracy trenera?')) {
                            resolve();
                        } else {
                            reject(); // reject on cancel
                        }
                    } else {
                        resolve();
                    }
                } else {
                    reject(); // reject on cancel
                }
            } else if (isSlotOccupied(formState, userWorkHours)) {
                if (window.confirm('Czy na pewno chcesz zaplanować trening poza godzinami pracy trenera?')) {  // resolve if slot is not in users work hours
                    resolve();
                } else {
                    reject();
                }
            } else {
                resolve();
            }
        }).then(() => {
            return data && data.id
                ? this.onUpdate(formState)
                : this.onCreate(formState);
        });
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create({
            ...this.formStateToApi(formState),
        })
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_EVENTS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_EVENT].id }
                    )
                );
            });
    }

    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            id: data.id,
            ...this.formStateToApi(formState),
        });
    }

    onRemove = () => {
        const { data, actions, history, onRemove } = this.props;

        return actions.remove({
            id: data.id,
        })
            .then(() => {
                if (onRemove) {
                    return onRemove();
                }

                if (!data.userOffer && data.lead) {
                    return history.push(
                        withVariables(
                            ADMIN_USERS_MANAGE.path,
                            { id: data.lead?.id || undefined },
                            { tab: TAB_EVENTS_LIST }
                        )
                    );
                }

                return history.push(
                    withVariables(
                        ADMIN_USER_OFFERS_MANAGE.path,
                        { id: data?.userOffer?.id || undefined },
                        { tab: TAB_EVENTS_LIST }
                    )
                );
            })
            .catch((error) => {
                toast.error('Nie udało się wykonać operacji');
            });
    }

    render() {
        const { data, location, history, actions, predefinedState, displayModeModal, profile } = this.props;
        const { formState, products, isModalEventCycleEndVisible, isModalCreateUserVisible, isModalCreateUserOfferVisible } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        const productId = fromSelectObject(formState.productId);
        const currentProduct = products.find(product => productId && product.id === productId);

        console.log(data);

        return (
            <StyledComponent
                className={classnames({
                    'panel-events-editor': true,
                    'modal-display': displayModeModal,
                })}
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data?.id),
                        label: 'Identyfikator',
                        value: data && data.id,
                    }, {
                        visible: Boolean(data && currentProduct),
                        label: 'Czas trwania',
                        value: `${currentProduct && currentProduct.durationValue || 'Brak wartości'} ${currentProduct && currentProduct.durationType || 'Brak typu'}`,
                    }, {
                        visible: Boolean(data && data.user),
                        label: 'Użytkownik',
                        value: data && getFullName(data.user).label,
                        to: data &&  data.user && withVariables(ADMIN_USERS_MANAGE.path, { id: data.user.id }),
                    }, {
                        visible: Boolean(data && data.offer),
                        label: 'Zamówienie',
                        value: data && (data.userOffer?.name || data.offer && data.offer?.name),
                        to: data && data.userOffer && withVariables(ADMIN_USER_OFFERS_MANAGE.path, { id: data.userOffer.id }),
                    }, {
                        visible: Boolean(data && data.category === EVENT_CATEGORY_DEFAULT),
                        label: 'Status opłacenia',
                        value: data && getPaidStatusLabel(data).label,
                    }, {
                        visible: Boolean(data && data.paidReason),
                        label: 'Powód opłacenia',
                        value: data && data.paidReason,
                    }, {
                        visible: Boolean(data && data.category === EVENT_CATEGORY_DEFAULT),
                        label: 'Status wykonania',
                        value: data && getDoneStatusLabel(data).label,
                    }, {
                        visible: Boolean(data?.id),
                        label: 'Slot utworzony przez',
                        value: data 
                            ? data.creator
                                ?  getFullName(data.creator).label
                                : 'System'
                            : '',
                    }, {
                        visible: Boolean(data && data.createReason),
                        label: 'Powod utworzenia',
                        value: data && data.createReason,
                    }, {
                        visible: Boolean(data && data.startAt),
                        label: 'Data dla trenera (czas trwania)',
                        value: toAppFormat(data?.startAt) + ' (' + data?.product?.durationValue + ' ' + getDurationTypeLabel(data?.product).label + ')',
                    }, {
                        visible: Boolean(data && data.startAt),
                        label: 'Data dla klienta (czas trwania)',
                        value: toAppFormat(data?.startAt) + ' (' + data?.product?.displayDurationValue || '' + ' ' + translateTimeUnit(data?.product) + ')',
                    }, {
                        visible: Boolean(data && data.userOffer?.usersType),
                        label: 'Ilość użytkowników biorących udział',
                        value: data?.usersCount
                    }, {
                        visible: Boolean(data && data.eventCycle),
                        label: 'Cykl',
                        value: data && getFormattedDate(data.eventCycle?.startAt),
                        to: data &&  data.eventCycle && withVariables(ADMIN_EVENT_CYCLE.path, { id: data.eventCycle.originEventId }),
                    }]}
                    controls={[{
                        visible: Boolean(!data?.eventCycle && data?.lead),
                        title: 'Zarządzaj cyklem',
                        subtitle: 'Przejdź do zarządzania cyklem',
                        buttonProps: {
                            onClick: () => history.push(
                                withVariables(
                                    ADMIN_EVENT_CYCLE.path,
                                    { id: data?.id },
                                    {}
                                )
                            ),
                            children: 'Cykl',
                        },
                    }, {
                        visible: Boolean(data?.eventCycle),
                        title: 'Zakończ cykl',
                        subtitle: 'Możesz usunąć wydarzenia wg. wybranej opcji',
                        buttonProps: {
                            onClick: () => {
                                this.setState({
                                    isModalEventCycleEndVisible: true,
                                });
                            },
                            children: 'Zakończ cykl'
                        }
                    }, {
                        visible: Boolean(data && data.category === EVENT_CATEGORY_DEFAULT && data.paidStatus !== EVENT_PAID_STATUS_PAID),
                        title: 'Oznacz jako opłacone',
                        subtitle: 'Wydarzenie zostanie oznaczone jako opłacone i będzie można je wykonać',
                        buttonProps: {
                            onClick: () => {
                                const paidReason = window.prompt('Podaj powód opłacenia wydarzenia');

                                return new Promise((resolve, reject) => {
                                    if (paidReason) {
                                        resolve();
                                    } else {
                                        reject();
                                    }
                                })
                                    .then(response => {
                                        toast('Wydarzenie zostało oznaczone jako opłacone');
                                        actions.setPaidStatus({ id: data && data.id, status: EVENT_PAID_STATUS_PAID, paidReason });
                                    })
                                    .catch(error => {
                                        toast('Wystąpił błąd, spróbuj ponownie później.');
                                    });
                            },
                            children: 'Opłacone',
                            confirm: {
                                enabled: false,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.category === EVENT_CATEGORY_DEFAULT && data.paidStatus === EVENT_PAID_STATUS_NOT_PAID),
                        title: 'Oznacz jako kredytowane',
                        subtitle: 'Wydarzenie zostanie oznaczone jako kredytowane i będzie można je wykonać (zostanie oznaczone jako opłacone przy kolejnej płatności)',
                        buttonProps: {
                            onClick: () => actions.setPaidStatus({ id: data && data.id, status: EVENT_PAID_STATUS_CREDIT }),
                            children: 'Kredytowane',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.category === EVENT_CATEGORY_DEFAULT && data.paidStatus !== EVENT_PAID_STATUS_NOT_PAID),
                        title: 'Oznacz jako nieopłacone',
                        subtitle: 'Wydarzenie zostanie oznaczone jako nieopłacone i nie będzie można go wykonać',
                        buttonProps: {
                            onClick: () => actions.setPaidStatus({ id: data && data.id, status: EVENT_PAID_STATUS_NOT_PAID }),
                            children: 'Nieopłacone',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.category === EVENT_CATEGORY_DEFAULT && data.doneStatus !== EVENT_DONE_STATUS_DONE),
                        title: 'Oznacz jako wykonane',
                        subtitle: 'Wydarzenie zostanie oznaczone jako wykonane',
                        buttonProps: {
                            onClick: () => actions.setDoneStatus({ id: data && data.id, status: EVENT_DONE_STATUS_DONE }),
                            children: 'Wykonane',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.category === EVENT_CATEGORY_DEFAULT && data.doneStatus !== EVENT_DONE_STATUS_CANCELED),
                        title: 'Oznacz jako odwołane',
                        subtitle: 'Wydarzenie zostanie oznaczone jako odwołane (Odwołane wydarzenia liczone sa jako wykonane)',
                        buttonProps: {
                            onClick: () => actions.setDoneStatus({ id: data && data.id, status: EVENT_DONE_STATUS_CANCELED }),
                            children: 'Odwołaj',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.category === EVENT_CATEGORY_DEFAULT && data.doneStatus !== EVENT_DONE_STATUS_NOT_DONE),
                        title: 'Oznacz jako niewykonane',
                        subtitle: 'Wydarzenie zostanie oznaczone jako niewykonane',
                        buttonProps: {
                            onClick: () => actions.setDoneStatus({ id: data && data.id, status: EVENT_DONE_STATUS_NOT_DONE }),
                            children: 'Niewykonane',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.category),
                        title: 'Zresetuje wydarzenie',
                        subtitle: 'Zostanie ustawione do stanu początkowego (bez dat, prowadzącego,lokalizacji)',
                        buttonProps: {
                            onClick: () => actions.reset({ id: data && data.id }),
                            children: 'Resetuj',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.paidStatus !== EVENT_PAID_STATUS_PAID && data.category),
                        title: 'Usuń wydarzenie',
                        subtitle: 'Wydarzenie zostanie bezpowrotnie usunięte',
                        buttonProps: {
                            onClick: this.onRemove,
                            children: 'Usuń',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.canUnbonus && hasPermission(profile, USER_PERMISSION_GROUP_EVENTS)),
                        title: 'Odbonusuj',
                        subtitle: 'Wydarzenie zostanie odbonusowane',
                        buttonProps: {
                            onClick: () => actions.unbonus({ id: data && data.id }),
                            children: 'Odbonusuj',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.offer?.type === USER_OFFER_TYPE_DIAGNOSTIC),
                        title: 'Odwołaj trening diagnostyczny - do przełożenia',
                        subtitle: 'Do przełożenia',
                        buttonProps: {
                            onClick: () => actions.cancelDiagnostic({ 
                                id: data.id,
                                type: CANCEL_DIAGNOSTIC_REASON_TO_POSTPONE,
                            }),
                            children: 'Odwołaj',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.offer?.type === USER_OFFER_TYPE_DIAGNOSTIC),
                        title: 'Odwołaj trening diagnostyczny - brak kontaktu',
                        subtitle: 'Brak kontaktu z klientem',
                        buttonProps: {
                            onClick: () => actions.cancelDiagnostic({ 
                                id: data.id,
                                type: CANCEL_DIAGNOSTIC_REASON_NO_ANSWER,
                            }),
                            children: 'Odwołaj',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.offer?.type === USER_OFFER_TYPE_DIAGNOSTIC),
                        title: 'Odwołaj trening diagnostyczny - klient nie chce',
                        subtitle: 'Odwolany - Klient nie chce',
                        buttonProps: {
                            onClick: () => actions.cancelDiagnostic({ 
                                id: data.id,
                                type: CANCEL_DIAGNOSTIC_REASON_CANCELED,
                            }),
                            children: 'Odwołaj',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.canDoneByAdept),
                        title: 'Wyślij prośbę do adepta o odbicie treningu',
                        subtitle: 'Adept dostanie powiadomienie z prośbą o oznaczenie treningu jako wykonany',
                        buttonProps: {
                            onClick: () => actions.confirmRequest({ 
                                id: data.id,
                            }).then(() => {
                                toast('Prośba została wysłana');
                            }),
                            children: 'Wyślij',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: nextFormState => {
                            if (currentProduct && currentProduct.durationType) {
                                nextFormState.endAt = moment(nextFormState.startAt).add(
                                    currentProduct.durationType,
                                    currentProduct.durationValue
                                );
                            }

                            if(!nextFormState.locationId && !predefinedState.lead) {
                                nextFormState.leadId = null;
                            }

                            if(!nextFormState.leadId && !predefinedState.startAt) {
                                nextFormState.startAt = null;
                                nextFormState.endAt = null;
                            }

                            this.setState({
                                formState: { ...nextFormState },
                            });
                        },
                        elements: [{    
                            isVisible: Boolean(predefinedState.lead), 
                            type: 'select',
                            name: 'category',
                            label: 'Kategoria',
                            options: EVENT_CATEGORIES.map(category => ({
                                value: category.key,
                                label: category.label,
                            })),
                            inputProps: {
                                disabled: Boolean(data && data.userOffer || predefinedState?.userOfferId || data && data.user || predefinedState?.userId),
                            },
                        }, {
                            type: 'select',
                            name: 'userId',
                            label: 'Klient',
                            isVisible: fromSelectObject(formState.category) === EVENT_CATEGORY_DEFAULT || !predefinedState.lead,
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                }),
                                onChange: value => this.setState(prevState => ({
                                    formState: {
                                        ...prevState.formState,
                                        userId: fromSelectObject(value)?.value,
                                    },
                                })),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                                link: {
                                    enabled: true,
                                    label: 'Nowy klient',
                                    onClick: () => this.setState({
                                        isModalCreateUserVisible: true,
                                    }),
                                },
                            },
                        }, {
                            type: 'select',
                            name: 'userOfferId',
                            label: 'Zamówienie',
                            isVisible: Boolean(!predefinedState?.userOfferId && fromSelectObject(formState.category) === EVENT_CATEGORY_DEFAULT && formState?.userId),
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUserOffers({
                                    search: query,
                                    userId: fromSelectObject(formState.userId) || data && data.user && data.user.id,
                                }).then(response => {
                                    if (predefinedState.userOffer) {
                                        actions.listProducts({ userOfferId: predefinedState.userOffer.id })
                                            .then(response => {
                                                this.setState(prevState => ({
                                                    formState: {
                                                        ...prevState.formState,
                                                        product: null,
                                                        productId: null,
                                                    },
                                                    products: response.payload[API_RESOURCE_PRODUCTS].elements,
                                                }));
                                            });
                                    }

                                    return response;
                                }),
                                onChange: value => actions.listProducts({
                                    userOfferId: fromSelectObject(value)?.value,
                                }).then(response => {
                                    this.setState(prevState => ({
                                        formState: {
                                            ...prevState.formState,
                                            product: null,
                                            productId: null,
                                        },
                                        products: response.payload[API_RESOURCE_PRODUCTS].elements,
                                    }));
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USER_OFFERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: element.name,
                                }),
                                disabled: Boolean(predefinedState?.userOffer && fromSelectObject(formState.category) === EVENT_CATEGORY_DEFAULT && formState?.userId),
                                link: {
                                    enabled: true,
                                    label: 'Nowe zamówienie',
                                    onClick: () => this.setState({
                                        isModalCreateUserOfferVisible: true,
                                    }),
                                },
                            },
                        }, {
                            type: 'select',
                            name: 'productId',
                            label: 'Produkt',
                            isVisible: Boolean(fromSelectObject(formState.userOfferId) && fromSelectObject(formState.category) === EVENT_CATEGORY_DEFAULT),
                            options: products.map(option => ({
                                value: option.id,
                                label: option.name,
                            })),
                        }, {
                            type: 'select',
                            name: 'locationId',
                            label: 'Lokalizacja',
                            isVisible: fromSelectObject(formState.category) === EVENT_CATEGORY_DEFAULT,
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listLocations({
                                    search: query,
                                    enabled: true,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_LOCATIONS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getLocationName(element).label,
                                }),
                            },
                        }, {
                            type: 'select',
                            name: 'leadId',
                            label: 'Prowadzący',
                            isVisible: Boolean(!predefinedState?.leadId && fromSelectObject(formState.category) === EVENT_CATEGORY_DEFAULT && formState.productId && formState.locationId),
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({
                                    search: query,
                                    enabled: true,
                                    role: USER_ROLE_TRAINER,
                                    canLeadProductId: fromSelectObject(formState.productId) || data && data.product && data.product.id,
                                    locationId: fromSelectObject(formState.locationId) || data && data.location && data.location.id,
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getFullName(element).label,
                                }),
                            },
                        }, {
                            type: 'datePicker',
                            name: 'startAt',
                            label: 'Data rozpoczęcia',
                            isVisible: Boolean(predefinedState?.startAt || fromSelectObject(formState.category) === EVENT_CATEGORY_PRIVATE || formState.leadId),
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'yyyy-MM-dd HH:mm',
                                    showTimeSelect: true,
                                    timeIntervals: 15,
                                },
                            },
                        }, {
                            type: 'datePicker',
                            name: 'endAt',
                            label: 'Data zakończenia',
                            isVisible: Boolean(predefinedState?.startAt || fromSelectObject(formState.category) === EVENT_CATEGORY_PRIVATE || formState.leadId),
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'yyyy-MM-dd HH:mm',
                                },
                            },
                        }, {
                            type: 'textarea',
                            name: 'trainingPlan',
                            label: 'Plan treningowy (widoczny dla adepta)',
                        }, {
                            type: 'textarea',
                            name: 'comment',
                            label: 'Komentarz do treningu (niewidoczny dla adepta)',
                        }],
                    }]}
                />
                <ModalEventCycleEnd
                    location={location}
                    history={history}
                    isOpen={isModalEventCycleEndVisible}
                    onClose={() => this.setState({ isModalEventCycleEndVisible: false })}
                    onSuccess={() => this.setState({ isModalEventCycleEndVisible: false })}
                    data={data}
                />
                <ModalUserEditor
                    location={location}
                    history={history}
                    isOpen={isModalCreateUserVisible}
                    onClose={() => this.setState({ isModalCreateUserVisible: false })}
                    predefinedState={{
                        role: USER_ROLE_ADEPT,
                    }}
                    onSuccess={user => this.setState(prevState => ({
                        ...prevState,
                        isModalCreateUserVisible: false,
                        formState: {
                            ...prevState.formState,
                            userId: { 
                                value: user.id, 
                                label: getFullName(user).label, 
                            },
                        },
                    }))}
                />
                <ModalUserOfferEditor
                    location={location}
                    history={history}
                    isOpen={isModalCreateUserOfferVisible}
                    onClose={() => this.setState({ isModalCreateUserOfferVisible: false })}
                    predefinedState={{
                        userId: formState.userId,
                    }}
                    onSuccess={userOffer => actions.listProducts({
                        offerId: userOffer.offer.id,
                    }).then(response => {
                        this.setState(prevState => ({
                            ...prevState,
                            isModalCreateUserOfferVisible: false,
                            formState: {
                                ...prevState.formState,
                                product: null,
                                productId: null,
                                userOfferId: { 
                                    value: userOffer.id, 
                                    label: userOffer.name, 
                                },
                            },
                            products: response.payload[API_RESOURCE_PRODUCTS].elements,
                        }));
                    })}
                />
            </StyledComponent>
        );
    }
}