import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 80vw;

    .panel-events-editor {
        width: 100%;
    }

    .modal-body-component {
        max-height: none; 
    }
`;
