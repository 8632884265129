import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { API_RESOURCE_REDIRECT_URI } from 'Consts/apiResources';
import { USER_OFFER_STATUS_NEW } from 'Consts/userOffers';

import Logger from 'Services/Logger';

import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import Button from 'Components/layout/Button';
import Gateway from 'Components/adept/userOffers/Gateway';
import PaymentMethods from 'Components/adept/userOffers/PaymentMethods';

export default class AdeptUserOfferConfirmation extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            payment: PropTypes.func.isRequired,
            list: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object.isRequired,
    };
    
    static defaultProps = {
        data: null,
    };
    
    state = {
        isLoading: false,
        paymentMethod: null,
    };

    onPayment = () => {
        const { actions, data } = this.props;
        const { paymentMethod } = this.state;

        this.setState({
            isLoading: true,
        });
        
        actions.payment({ 
            id: data.id,
            paymentMethodId: paymentMethod && paymentMethod.id,
        })
            .then(response => {
                this.setState({
                    isLoading: false,
                });
                if (response.payload[API_RESOURCE_REDIRECT_URI]) {
                    window.location.href = response.payload[API_RESOURCE_REDIRECT_URI];
                } else {
                    toast('Zamówienie zostało opłacone');
                }
            })
            .catch(error => {
                toast('Wystąpił błąd, spróbuj ponownie poźniej');
                Logger.error('[AdeptUserOffersEditor] Failed to payment', error);
                this.setState({
                    isPending: false,
                    error: true,
                });
            });
    }

    render() {
        const { data, location, history } = this.props;
        const { isLoading, paymentMethod } = this.state;

        if (!data) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="adept-user-offers-editor"
                styles={require('./styles')}
            > 
                {isLoading && <Spinner />}
                {
                    data.offer && data.offer.durationType && data.status == USER_OFFER_STATUS_NEW
                        ? ( 
                            <div className="recurring-offer">
                                <PaymentMethods 
                                    location={location}
                                    history={history}
                                    changePaymentMethod={(paymentMethod) => this.setState({ paymentMethod })}
                                /> 
                                <p className="description"> 
                                    Cieszymy się, że chcesz opłacić zamówienie! Pobierzemy z Twojej karty <b> {formatPrice(data.totalPriceGross)}</b>, a Twoje zamówienie zostanie przedłużone o kolejny cykl. 
                                </p> 
                            </div>
                        )
                        : ( 
                            <div className="one-time-offer">
                                <PaymentMethods 
                                    location={location}
                                    history={history}
                                    changePaymentMethod={(paymentMethod) => this.setState({ paymentMethod })}
                                /> 
                                <Gateway 
                                    data={data}
                                    location={location}
                                    history={history}
                                />
                                <p className="description"> 
                                    Cieszymy się, że chcesz opłacić zamówienie! Pobierzemy z Twojej karty  <b> {formatPrice(data.totalPriceGross)}</b>, a status Twojego zamówienia zostanie zmieniony.
                                </p> 
                            </div>
                        )
                }
                <Button 
                    onClick={this.onPayment}
                    style='gradient'
                    disabled={paymentMethod ? false : true}
                > 
                    Zapłać 
                </Button>
            </StyledComponent>
        );
    }
}