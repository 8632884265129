import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { 
    API_RESOURCE_USER_OFFER,
    API_RESOURCE_OFFERS, 
    API_RESOURCE_USERS, 
    API_RESOURCE_PROMO_CODES, 
    API_RESOURCE_INVOICING_STRATEGIES, 
    API_RESOURCE_PAYMENT_METHODS,
    API_RESOURCE_LOCATIONS,
    API_RESOURCE_PRODUCTS,
} from 'Consts/apiResources';
import { ADMIN_USER_OFFERS_MANAGE, ADMIN_OFFERS_MANAGE, ADMIN_USERS_MANAGE, ADMIN_PROMO_CODES_MANAGE, PUBLIC_SHOP_USER_OFFER, OLD_PANEL_ORDER_MANAGE, ADMIN_USER_OFFERS, ADMIN_USER_OFFERS_ADD_PAYMENT } from 'Consts/routes';
import { USER_ROLE_ADEPT, USER_ROLE_TRAINER } from 'Consts/userRoles';
import { 
    USER_OFFER_MEDICAL_PACKAGES,
    USER_OFFER_STATUS_ACTIVE,
    USER_OFFER_STATUS_TERMINATED,
    USER_OFFER_USERS_TYPES,
    USER_OFFER_USERS_TYPE_MULTI,
} from 'Consts/userOffers';
import { OFFER_PERIOD_TYPE_RECURRING, 
    OFFER_DURATION_TYPES, 
    OFFER_PERIOD_TYPES, 
    OFFER_TYPE_DEFAULT,
    OFFER_TYPE_DIAGNOSTIC,
    OFFER_TYPE_DIAGNOSTIC_MASSAGE,
} from 'Consts/offers';
import { FINANCES_VAT_PERCENTS } from 'Consts/finances';
import { USER_PAYMENT_METHOD_CARD_TOKEN_TYPE_RECURRING } from 'Consts/usersPaymentMethods';
import { USER_PERMISSION_GROUP_USER_OFFERS, USER_PERMISSION_RESOURCE_REMOVE, USER_PERMISSION_RESOURCE_CLOSE } from 'Consts/userPermissions';

import { withVariables } from 'Utils/string';
import { getFullName as getUserFullName } from 'Utils/user';
import { getName as getOfferName } from 'Utils/offer';
import { getName as getPromoCodeName } from 'Utils/promoCode';
import { fromSelectObject, filterKeys } from 'Utils/object';
import { formatPrice } from 'Utils/math';
import { getFormattedDate, toApiFormat as dateToApiFormat } from 'Utils/date';
import { getStatusLabel } from 'Utils/userOffer';
import { hasPermission as hasUserPermission } from 'Utils/permissions';
import { parseQueryToObject } from 'Utils/querystring';
import { getName as getLocationName } from 'Utils/location';
import { getName as getProductName } from 'Utils/product';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';
import ModalUserEditor from 'Components/admin/modals/UserEditor';
import ModalCloseEditor from 'Components/admin/modals/UserOfferCloseEditor';
import ModalCancelEditor from 'Components/admin/modals/UserOfferCancelEditor';
import ModalDeletionEditor from 'Components/admin/modals/UserOfferDeletionEditor';
import ModalActivationEditor from 'Components/admin/modals/UserOfferActivationEditor';
import ModalAddPaymentEditor from 'Components/admin/modals/UserOfferAddPaymentEditor';
import ModalCreateEvent from 'Components/admin/modals/UserOfferCreateEvent';

export default class PanelUserOffersEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            extend: PropTypes.func.isRequired,
            payment: PropTypes.func.isRequired,
            notificationShopLink: PropTypes.func.isRequired,
            listOffers: PropTypes.func.isRequired,
            listUsers: PropTypes.func.isRequired,
            listPromoCodes: PropTypes.func.isRequired,
            listInvoicingStrategies: PropTypes.func.isRequired,
            closeOrder: PropTypes.func.isRequired,
            listPaymentMethods: PropTypes.func.isRequired,
            listLocations: PropTypes.func.isRequired,
            listProducts: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        user: PropTypes.object,
        predefinedState: PropTypes.object,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
        onSuccess: null,
    };

    state = {
        formState: {},
        paymentMethods: [],
        isModalCreateUserVisible: false,
        isModalCloseOfferVisible: false,
        isModalCancelOfferVisible: false,
        isModalDeletionOfferVisible: false,
        isModalActivationOfferVisible: false,
        isModalAddPaymentVisible: false,
        isModalCreateEventVisible: false,
        specializations: [],
        offerChangeTimestamp: null,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { formState } = this.state;
        const { data, actions, predefinedState } = this.props;

        actions.listPaymentMethods({ id: data && data.user && data.user.id })
            .then(response => {
                const result = response.payload[API_RESOURCE_PAYMENT_METHODS].elements
                    .filter(paymentMethod => paymentMethod.enabled == true && paymentMethod.cardTokenType == USER_PAYMENT_METHOD_CARD_TOKEN_TYPE_RECURRING);

                this.setState(prevState => ({
                    paymentMethods: result,
                }));
            });

        if (predefinedState.userId) {
            this.setState({
                formState: {
                    ...formState,
                    userId: predefinedState.userId,
                },
            });
        }

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),              
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data, predefinedState } = this.props;
        const { formState } = this.state;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data || {}),
                },
            }));
        }

        if(formState.medicalPackage && formState.vatOverride) {
            this.setState(prevState => ({
                ...prevState,
                formState: {
                    ...prevState.formState,
                    vatOverride: null,
                },
            }));
        }

        if (predefinedState.userId) {
            if (JSON.stringify(predefinedState.userId) !== JSON.stringify(prevProps.predefinedState.userId)) {
                this.setState({
                    formState: {
                        ...formState,
                        userId: predefinedState.userId,
                    },
                });
            }
        }
    }

    dataToFormState = data => ({
        ...data,
        invoicingStrategyId: data.invoicingStrategy
            ? { value: data.invoicingStrategy.id, label: `${data.invoicingStrategy.name} (${data.invoicingStrategy.description})` }
            : null,
        leadId: data.lead
            ? { value: data.lead.id, label: getUserFullName(data.lead).label }
            : null,
    })

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onUpdate = formState => {
        const { actions } = this.props;

        return actions.update({
            ...formState,
            paidTo: dateToApiFormat(formState.paidTo, 'datetime', true),
            activeTo: dateToApiFormat(formState.activeTo, 'datetime', true),
            terminatedTo: dateToApiFormat(formState.terminatedTo, 'datetime', true),
            terminatedAt: dateToApiFormat(formState.terminatedAt, 'datetime', true),
            vatOverride: fromSelectObject(formState.vatOverride),
            invoicingStrategyId: fromSelectObject(formState.invoicingStrategyId),
            leadId: fromSelectObject(formState.leadId),
            medicalPackage: fromSelectObject(formState.medicalPackage),
            firstEventLocationId: fromSelectObject(formState.firstEventLocationId),
            firstEventProductId: fromSelectObject(formState.firstEventProductId)?.id,
            firstEventStartAt: dateToApiFormat(formState.firstEventStartAt, 'datetime', true),
            periodType: fromSelectObject(formState.periodType),
            usersType: fromSelectObject(formState.usersType),
            durationType: fromSelectObject(formState.durationType),
            noticePeriodDurationType: fromSelectObject(formState.noticePeriodDurationType),
        });
    }

    onCreate = formState => {
        const { actions, history, onSuccess } = this.props;

        return actions.create({
            ...formState,
            offerId: fromSelectObject(formState.offerId),
            userId: fromSelectObject(formState.userId),
            promoCodeId: fromSelectObject(formState.promoCodeId),
            leadId: fromSelectObject(formState.leadId),
            firstEventLocationId: fromSelectObject(formState.firstEventLocationId),
            firstEventProductId: fromSelectObject(formState.firstEventProductId)?.id,
            firstEventStartAt: dateToApiFormat(formState.firstEventStartAt, 'datetime', true),
            usersType: fromSelectObject(formState.usersType),
        })
            .then(response => {
                if (onSuccess) {
                    return onSuccess(response);
                }

                history.push(
                    withVariables(
                        ADMIN_USER_OFFERS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_USER_OFFER].id }
                    )
                );
            });
    }

    hasUserPaymentMethods = (paymentMethods) => {
        return paymentMethods && paymentMethods.length > 0;
    }

    onClickAddEvent = () => {
        this.setState({
            isModalCreateEventVisible: true,
        });
    }

    render() {
        const { data, location, history, actions, user } = this.props;
        const { 
            formState, 
            isModalCreateUserVisible, 
            paymentMethods, 
            isModalCloseOfferVisible, 
            isModalCancelOfferVisible, 
            isModalDeletionOfferVisible, 
            isModalActivationOfferVisible, 
            isModalAddPaymentVisible,
            isModalCreateEventVisible,
            offerChangeTimestamp,
        } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-offers-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data),
                        label: 'id',
                        value: data && data.id,
                    }, {
                        visible: Boolean(data),
                        label: 'Status',
                        value: data && getStatusLabel(data).label,
                    }, {
                        visible: Boolean(data && data.offer),
                        label: 'Oferta',
                        value: data && data.offer && getOfferName(data.offer).label,
                        to: data && data.offer && withVariables(ADMIN_OFFERS_MANAGE.path, { id: data.offer.id }),
                    }, {
                        visible: Boolean(data && data.offer),
                        label: 'Cena',
                        value: data && formatPrice(data.totalPriceGross),
                    }, {
                        visible: Boolean(data && data.user),
                        label: 'Użytkownik',
                        value: data && data.user && `${getUserFullName(data.user).label} (Wersja: ${data.user.systemVersion})`,
                        to: data && data.user && withVariables(ADMIN_USERS_MANAGE.path, { id: data.user.id }),
                    }, {
                        visible: Boolean(data && data.promoCode),
                        label: 'Kod promocyjny',
                        value: data && data.promoCode && getPromoCodeName(data.promoCode).label,
                        to: data && data.promoCode && withVariables(ADMIN_PROMO_CODES_MANAGE.path, { id: data.promoCode.id }),
                    }, {
                        visible: Boolean(data && data.paidTo),
                        label: 'Zamówienie opłacone do',
                        value: data && data.paidTo && getFormattedDate(data.paidTo, 'date'),
                    }, {
                        visible: Boolean(data && data.activeTo),
                        label: 'Data zakończenia okresu wypowiedzenia',
                        value: data && data.activeTo && getFormattedDate(data.activeTo, 'date'),
                    }, {
                        visible: Boolean(data && data.terminatedTo),
                        label: 'Data zakończenia okresu terminacji',
                        value: data && data.terminatedTo && getFormattedDate(data.terminatedTo, 'date'),
                    }, {
                        visible: Boolean(data && !data.paidTo),
                        label: 'Link do sklepu',
                        value: data && process.env.APP_URL + withVariables(PUBLIC_SHOP_USER_OFFER.path, { id: data.id }),
                        href: data && withVariables(PUBLIC_SHOP_USER_OFFER.path, { id: data.id }),
                        target: '_blank',
                    }, {
                        visible: Boolean(data && data.migratedOrder),
                        label: 'Link do starego panelu',
                        value: data && data.migratedOrder && withVariables(OLD_PANEL_ORDER_MANAGE, { id: data.migratedOrder.id }),
                        href: data && data.migratedOrder && withVariables(OLD_PANEL_ORDER_MANAGE, { id: data.migratedOrder.id }),
                        target: '_blank',
                    }, {
                        visible: (Boolean(data && data.terminateReason)),
                        label: 'Powód wypowiedzenia',
                        value: data?.terminateReason || null,
                    }, {
                        visible: (Boolean(data && data.closeReason)),
                        label: 'Powód zamknięcia',
                        value: data?.closeReason || null,
                    }, {
                        visible: (Boolean(data && data.removeReason)),
                        label: 'Powód usunięcia',
                        value: data?.removeReason || null,
                    }, {
                        visible: (Boolean(data && data.productCategory)),
                        label: 'Kategoria produktu',
                        value: data?.productCategory?.name,
                    }, {
                        visible: (Boolean(data && data.activeToRollback)),
                        label: 'Data cofnięcia wypowiedzenia',
                        value: data && data.activeToRollback && getFormattedDate(data.activeToRollback),
                    }, {
                        vibible: Boolean(data && data.openTo),
                        label: 'Otwarte do',
                        value: data && data.openTo && getFormattedDate(data.openTo),
                    }]}
                    controls={[{
                        visible: Boolean(data && data.periodType == OFFER_PERIOD_TYPE_RECURRING && this.hasUserPaymentMethods(paymentMethods)),
                        title: 'Przedłuż zamówienie bez płatności',
                        subtitle: 'Zamówienie zostanie przedłużone o kolejny okres rozliczeniowy',
                        buttonProps: {
                            onClick: () => actions.extend({ id: data.id }),
                            children: 'Przedłuż',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data),
                        title: data && data.periodType == OFFER_PERIOD_TYPE_RECURRING && this.hasUserPaymentMethods(paymentMethods) 
                            ? 'Wymuś płatność i przedłuż zamówienie'
                            : 'Nie można wymusić płatności',
                        subtitle: data && data.periodType == OFFER_PERIOD_TYPE_RECURRING && this.hasUserPaymentMethods(paymentMethods) 
                            ? 'Płatność zostanie wymuszona i zamówienie zostanie przedłużone o kolejny okres rozliczeniowy' 
                            :  this.hasUserPaymentMethods(paymentMethods) 
                                ? 'Oferta nie jest cykliczna'
                                : 'Użytkownik nie posiada zweryfikowanej metody płatności',
                        buttonProps: {
                            onClick: () => actions.payment({ id: data.id }),
                            children: 'Wymuś płatność',
                            confirm: {
                                enabled: true,
                                message: 'Czy na pewno chcesz dokonać wymuszenia płatności? Próba pobrania kwoty z karty użytkownika nastąpi od razu.',
                            },
                            disabled: data && data.periodType == OFFER_PERIOD_TYPE_RECURRING && this.hasUserPaymentMethods(paymentMethods) ? false : true,
                        },
                    }, {
                        visible: Boolean(data && data.status === USER_OFFER_STATUS_ACTIVE && !data.activeTo && data.periodType === OFFER_PERIOD_TYPE_RECURRING),
                        title: 'Wypowiedz zamówienie',
                        subtitle: 'Wypowiedzenie zamówienia rozpocznie okres wypowiedzenia',
                        buttonProps: {
                            onClick: () => {
                                this.setState({
                                    isModalCancelOfferVisible: true,
                                });
                            },
                            children: 'Wypowiedz',
                        },
                    }, {
                        visible: Boolean(data && (data.status !== USER_OFFER_STATUS_ACTIVE || data.activeTo)),
                        title: 'Aktywuj zamówienie',
                        subtitle: 'Aktywowanie wypowiedzenia usunie okres wypowiedzenia i aktywuje zamówienie',
                        buttonProps: {
                            onClick: () => {
                                this.setState({
                                    isModalActivationOfferVisible: true,
                                });
                            },
                            children: 'Aktywuj',
                        },
                    }, {
                        visible: Boolean(data && data.id && data.status !== USER_OFFER_STATUS_TERMINATED && data.offer.type === OFFER_TYPE_DEFAULT),
                        title: 'Wyślij link do opłacenia zamówienia',
                        subtitle: 'Zostanie wysłana wiadomość na adres e-mail użytkownika z linkiem do opłacenia zamówienia',
                        buttonProps: {
                            onClick: () => actions.notificationShopLink({ id: data.id }),
                            children: 'Wyślij',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.id && data.status !== USER_OFFER_STATUS_TERMINATED && (data.offer.type === OFFER_TYPE_DIAGNOSTIC || data.offer.type === OFFER_TYPE_DIAGNOSTIC_MASSAGE)),
                        title: 'Wyślij maila z potwierdzeniem wydarzenia diagnostycznego',
                        subtitle: 'Zostanie wysłana wiadomość na adres e-mail użytkownika potwierdzająca wydarzenie diagnostyczne',
                        buttonProps: {
                            onClick: () => actions.notificationShopLink({ id: data.id }),
                            children: 'Wyślij',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.migratedOrder && hasUserPermission(user, USER_PERMISSION_GROUP_USER_OFFERS, USER_PERMISSION_RESOURCE_CLOSE)),
                        title: 'Zamknij zamówienie w starym systemie',
                        subtitle: data && data.migratedOrder && data.migratedOrder.status === 'closed'
                            ? 'Zamówienie jest już zamknięte'
                            : `Zamówienie w starym systemie zostanie zamknięte (Aktualny status: ${data && data.migratedOrder && data.migratedOrder.status})`,
                        buttonProps: {
                            onClick: () => actions.closeOrder({ id: data && data.migratedOrder.id })
                                .then(() => window.location.reload()),
                            children: 'Zamknij',
                            disabled: data && data.migratedOrder && data.migratedOrder.status === 'closed',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.id && data.status !== USER_OFFER_STATUS_TERMINATED && hasUserPermission(user, USER_PERMISSION_GROUP_USER_OFFERS, USER_PERMISSION_RESOURCE_CLOSE)),
                        title: 'Zamknij zamówienie',
                        subtitle: 'Zamówienie zostanie natychmiastowo zamknięte',
                        buttonProps: {
                            onClick: () => this.setState({
                                isModalCloseOfferVisible: true,
                            }),
                            children: 'Zamknij',
                        },
                    }, {
                        visible: Boolean(data && data.id && hasUserPermission(user, USER_PERMISSION_GROUP_USER_OFFERS, USER_PERMISSION_RESOURCE_REMOVE)),
                        title: 'Usuń zamówienie',
                        subtitle: 'Zamówienie zostanie usunięte',
                        buttonProps: {
                            onClick: () => this.setState({
                                isModalDeletionOfferVisible: true,
                            }),
                            children: 'Usuń',
                            confirm: {
                                enabled: false,
                            },
                        },
                    }, {
                        visible: Boolean(data),
                        title: 'Wprowadź wpłatę',
                        subtitle: 'Zostanie wprowadzona wpłata',
                        buttonProps: {
                            onClick: () => this.setState({
                                isModalAddPaymentVisible: true,
                            }),
                            children: 'Wprowadź',
                            confirm: {
                                enabled: false,
                            },
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: nextFormState => {
                            this.setState({
                                offerChangeTimestamp: JSON.stringify(formState.offerId) !== JSON.stringify(nextFormState.offerId)
                                    ? Date.now()
                                    : offerChangeTimestamp,
                                formState: {
                                    ...nextFormState,
                                    firstEventProductId: JSON.stringify(formState.offerId) === JSON.stringify(nextFormState.offerId)
                                        ? nextFormState.firstEventProductId
                                        : null,
                                },
                            });
                        },
                        elements: [{
                            isVisible: Boolean(!data),
                            type: 'select',
                            name: 'offerId',
                            label: 'Oferta',
                            inputProps: {
                                disabled: false,
                                dynamic: true,
                                onLoad: query => actions.listOffers({ page: 1, perPage: 999, search: query, enabled: true }),
                                onMapResponse: response => response.payload[API_RESOURCE_OFFERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getOfferName(element, true).label,
                                }),
                            },
                        }, {
                            isVisible: Boolean(!data),
                            type: 'select',
                            name: 'userId',
                            label: 'Użytkownik',
                            inputProps: {
                                disabled: false,
                                dynamic: true,
                                onLoad: query => actions.listUsers({ search: query, role: USER_ROLE_ADEPT }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getUserFullName(element).label,
                                }),
                                link: {
                                    enabled: true,
                                    label: 'Nowy klient',
                                    onClick: () => this.setState({
                                        isModalCreateUserVisible: true,
                                    }),
                                },
                            },
                        }, {
                            isVisible: Boolean(formState.offerId || data?.offer),
                            type: 'select',
                            name: 'leadId',
                            label: 'Prowadzący',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listUsers({ search: query, role: USER_ROLE_TRAINER, enabled: true }),
                                onMapResponse: response => response.payload[API_RESOURCE_USERS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getUserFullName(element).label,
                                }),
                                link: {
                                    enabled: Boolean(formState.offerId),
                                    label: 'Dodaj wydarzenie',
                                    onClick: () => this.onClickAddEvent(),
                                },
                            },
                        }, {
                            isVisible: Boolean(!data),
                            type: 'select',
                            name: 'promoCodeId',
                            label: 'Kod promocyjny',
                            inputProps: {
                                disabled: false,
                                dynamic: true,
                                onLoad: query => actions.listPromoCodes({ search: query }),
                                onMapResponse: response => response.payload[API_RESOURCE_PROMO_CODES].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getPromoCodeName(element).label,
                                }),
                            },
                        }, {
                            type: 'datePicker',
                            name: 'firstEventStartAt',
                            label: 'Data startu pierwszego wydarzenia',
                            isVisible: Boolean(formState.leadId && formState.offerId),
                            inputProps: {
                                datePickerProps: {
                                    dateFormat: 'yyyy-MM-dd HH:mm',
                                },
                            },
                        }, {
                            isVisible: Boolean(formState.offerId),
                            type: 'select',
                            name: 'firstEventProductId',
                            label: 'Produkt pierwszego wydarzenia',
                            inputProps: {
                                loadTimestamp: offerChangeTimestamp,
                                disabled: false,
                                dynamic: true,
                                onLoad: query => actions.listProducts({
                                    search: query, 
                                    enabled: true,
                                    offerId: fromSelectObject(formState.offerId),
                                }),
                                // Temporary disabled
                                // .then(response => {
                                //     if(response.payload[API_RESOURCE_PRODUCTS].elements.length === 1) {
                                //         const product = response.payload[API_RESOURCE_PRODUCTS].elements[0];
                                //         this.setState({
                                //             formState: {
                                //                 ...formState,
                                //                 firstEventProductId: { value: product.id, label: getProductName(product).label }
                                //             }
                                //         });
                                //     }
                                // }),
                                onMapResponse: response => response.payload[API_RESOURCE_PRODUCTS].elements,
                                onMapOption: element => ({
                                    value: element,
                                    label: getProductName(element).label,
                                }),
                            },
                        }, {
                            isVisible: Boolean(formState.leadId && formState.offerId && formState.firstEventProductId),
                            type: 'select',
                            name: 'firstEventLocationId',
                            label: 'Lokalizacja pierwszego wydarzenia',
                            inputProps: {
                                disabled: false,
                                dynamic: true,
                                onLoad: query => actions.listLocations({ 
                                    search: query, 
                                    enabled: true,
                                    userId: fromSelectObject(formState.leadId),
                                    types: [
                                        fromSelectObject(formState.firstEventProductId)?.locationType,
                                        fromSelectObject(formState.firstEventProductId)?.secondLocationType,
                                        fromSelectObject(formState.firstEventProductId)?.thirdLocationType,
                                    ],
                                }),
                                onMapResponse: response => response.payload[API_RESOURCE_LOCATIONS].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: getLocationName(element).label,
                                }),
                            },
                        }, {
                            isVisible: Boolean(data),
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa wyświetlana',
                        }, {
                            isVisible: Boolean(data && data.periodType === OFFER_PERIOD_TYPE_RECURRING),
                            type: 'datePicker',
                            name: 'paidTo',
                            label: 'Data następnej płatności',
                        }, {	
                            isVisible: Boolean(data && data.periodType === OFFER_PERIOD_TYPE_RECURRING),	
                            type: 'datePicker',	
                            name: 'terminatedAt',	
                            label: 'Data zgłoszenia wypowiedzenia',	
                        }, {	
                            isVisible: Boolean(data && data.periodType === OFFER_PERIOD_TYPE_RECURRING),	
                            type: 'datePicker',	
                            name: 'activeTo',	
                            label: 'Data końca okresu wypowiedzenia',	
                        }, {	
                            isVisible: Boolean(data && data.periodType === OFFER_PERIOD_TYPE_RECURRING),	
                            type: 'datePicker',	
                            name: 'terminatedTo',	
                            label: 'Data terminacji zamówienia',	
                        },  {	
                            isVisible: Boolean(data && data.periodType === OFFER_PERIOD_TYPE_RECURRING),	
                            type: 'datePicker',	
                            name: 'closedAt',	
                            label: 'Data zamknięcia',	
                        }, {	
                            isVisible: Boolean(data && data.periodType === OFFER_PERIOD_TYPE_RECURRING),	
                            type: 'textarea',	
                            name: 'terminateReason',	
                            label: 'Powód wypowiedzenia',	
                        }, {	
                            isVisible: Boolean(data && data.periodType === OFFER_PERIOD_TYPE_RECURRING),	
                            type: 'textarea',	
                            name: 'closeReason',	
                            label: 'Powód zamknięcia',	
                        }, {	
                            isVisible: Boolean(data && data.periodType === OFFER_PERIOD_TYPE_RECURRING),	
                            type: 'textarea',	
                            name: 'deleteReason',	
                            label: 'Powód usunięcia',	
                        }, {
                            isVisible: Boolean(data),
                            disabled: true,
                            type: 'select',
                            name: 'periodType',
                            label: 'Cykliczność',
                            options: OFFER_PERIOD_TYPES.map(periodType => ({
                                value: periodType.key,
                                label: periodType.label,
                            })),
                        }, {
                            isVisible: Boolean(fromSelectObject(formState.periodType) === OFFER_PERIOD_TYPE_RECURRING),
                            type: 'select',
                            name: 'durationType',
                            label: 'Cykl płatności (Typ)',
                            options: OFFER_DURATION_TYPES.map(durationType => ({
                                value: durationType.key,
                                label: durationType.label,
                            })),
                        }, {
                            isVisible: Boolean(fromSelectObject(formState.periodType) === OFFER_PERIOD_TYPE_RECURRING),
                            type: 'input',
                            name: 'durationValue',
                            label: 'Cykl płatności (Wartość)',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            isVisible: Boolean(fromSelectObject(formState.periodType) === OFFER_PERIOD_TYPE_RECURRING),
                            type: 'select',
                            name: 'noticePeriodDurationType',
                            label: 'Czas trwania wypowiedzenia (Typ)',
                            options: OFFER_DURATION_TYPES.map(durationType => ({
                                value: durationType.key,
                                label: durationType.label,
                            })),
                        }, {
                            isVisible: Boolean(fromSelectObject(formState.periodType) === OFFER_PERIOD_TYPE_RECURRING),
                            type: 'input',
                            name: 'noticePeriodDurationValue',
                            label: 'Czas trwania wypowiedzenia (Wartość)',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'select',
                            name: 'medicalPackage',
                            label: 'Pakiet medyczny',
                            options: USER_OFFER_MEDICAL_PACKAGES.map(medicalPackage => ({
                                value: medicalPackage.key,
                                label: medicalPackage.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'vatOverride',
                            label: 'Nadpisanie stawki VAT',
                            isVisible: !formState.medicalPackage,
                            options: FINANCES_VAT_PERCENTS.map(vatPercent => ({
                                value: vatPercent.key,
                                label: vatPercent.label,
                            })),
                        },  {
                            isVisible: Boolean(data),
                            type: 'select',
                            name: 'invoicingStrategyId',
                            label: 'Strategia fakturowania',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listInvoicingStrategies({ page: 1, perPage: 999 }),
                                onMapResponse: response => response.payload[API_RESOURCE_INVOICING_STRATEGIES].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: `${element.name} (${element.description})`,
                                }),
                            },
                        }, {
                            type: 'textarea',
                            name: 'notes',
                            label: 'Notatka do zamówienia',
                        }, {
                            type: 'textarea',
                            name: 'comment',
                            label: 'Komentarz do zamówienia',
                        }, {
                            type: 'select',
                            name: 'usersType',
                            label: 'Typ użytkowników',
                            options: USER_OFFER_USERS_TYPES.map(usersType => ({
                                value: usersType.key,
                                label: usersType.label,
                            })),
                        }, {
                            isVisible: Boolean(fromSelectObject(formState.usersType) === USER_OFFER_USERS_TYPE_MULTI),
                            type: 'input',
                            name: 'usersLimit',
                            label: 'Limit zaproszonych dodatkowych użytkowników',
                            inputProps: {
                                type: 'number',
                            },
                        }],
                    }]}
                />
                <ModalUserEditor
                    location={location}
                    history={history}
                    isOpen={isModalCreateUserVisible}
                    onClose={() => this.setState({ isModalCreateUserVisible: false })}
                    predefinedState={ {
                        role: USER_ROLE_ADEPT,
                    }}
                    onSuccess={user => this.setState(prevState => ({
                        ...prevState,
                        isModalCreateUserVisible: false,
                        formState: {
                            ...prevState.formState,
                            userId: { 
                                value: user.id, 
                                label: getUserFullName(user).label, 
                            },
                        },
                    }))}
                />
                <ModalCloseEditor
                    location={location}
                    history={history}
                    isOpen={isModalCloseOfferVisible}
                    onClose={() => this.setState({ isModalCloseOfferVisible: false })}
                    onSuccess={() => this.setState({ isModalCloseOfferVisible: false })}
                    data={formState}
                />
                <ModalCancelEditor
                    location={location}
                    history={history}
                    isOpen={isModalCancelOfferVisible}
                    onClose={() => this.setState({ isModalCancelOfferVisible: false })}
                    onSuccess={() => this.setState({ isModalCancelOfferVisible: false })}
                    data={formState}
                />
                <ModalDeletionEditor
                    location={location}
                    history={history}
                    isOpen={isModalDeletionOfferVisible}
                    onClose={() => this.setState({ isModalDeletionOfferVisible: false })}
                    onSuccess={data => {
                        history.push(
                            withVariables(
                                ADMIN_USER_OFFERS.path
                            )
                        );
                    }}
                    data={formState}
                /> 
                <ModalActivationEditor
                    location={location}
                    history={history}
                    isOpen={isModalActivationOfferVisible}
                    onClose={() => this.setState({ isModalActivationOfferVisible: false })}
                    onSuccess={() => this.setState({ isModalActivationOfferVisible: false })}
                    data={formState}
                />
                <ModalAddPaymentEditor
                    location={location}
                    history={history}
                    isOpen={isModalAddPaymentVisible}
                    onClose={() => this.setState({ isModalAddPaymentVisible: false })}
                    onSuccess={() => this.setState({ isModalAddPaymentVisible: false })}
                    data={formState}
                />
                <ModalCreateEvent
                    location={location}
                    history={history}
                    isOpen={isModalCreateEventVisible}
                    onClose={() => this.setState({ isModalCreateEventVisible: false })}
                    onSuccess={(slot, lead) => {
                        this.setState({ isModalCreateEventVisible: false, formState: {
                            ...formState,
                            leadId: { value: lead.id, label: getUserFullName(lead).label },
                            firstEventStartAt: slot.start,
                        } });
                    }}
                    data={formState}
                />
            </StyledComponent>
        );
    }
}
