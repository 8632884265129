import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .list {
        max-height: 90vh;
        overflow-y: auto;
        position: relative;
        min-height: 10em;

        .list-element {
            margin-bottom: .5em;
            padding-bottom: .5em;
            border-bottom: .01em solid #EEEEEE;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            .list-element-indicator {
                width: .5em;
                height: .5em;
                background: #CCCCCC;
                border-radius: 36em;
                margin-right: 1em;
            }

            .list-element-title {
                color: #666666;
                font-weight: 300;
                font-size: .9em;
            }

            &.selected {
                .list-element-indicator {
                    background: red;
                }
                .list-element-title {
                    font-weight: 600;
                }
            }
        }
    }
`;
