import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_ORDERS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            type: params.type && params.type !== 'null' && params.type || undefined,
            migrated: params.migrated && params.migrated !== 'null' && params.migrated || undefined,
            userId:  params.userId && params.userId !== 'null' && params.userId || undefined,
            trainerId: params.trainerId && params.trainerId !== 'null' && params.trainerId || undefined,
            category: params.category || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ORDER, params),
        params,
    }));
};

export const close = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.CLOSE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_ORDER_CLOSE, params),
        params,
    }));
};

