import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { API_RESOURCE_USER, API_RESOURCE_USERS } from 'Consts/apiResources';
import { USER_ROLE_ADEPT } from 'Consts/userRoles';

import { fromSelectObject } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layoutAdmin/modals/ModalWrapper';
import ModalBody from 'Components/layoutAdmin/modals/ModalBody';
import ModalTitle from 'Components/layoutAdmin/modals/ModalTitle';
import Button from 'Components/layoutAdmin/Button';
import Spinner from 'Components/layoutAdmin/Spinner';
import MultiSelect from 'Components/forms/MultiSelect';

export default class AdminUsersTransferModal extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSuccess: PropTypes.func,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        sourceUser: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            transfer: PropTypes.func.isRequired,
            list: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        onSuccess: null,
        data: null,
        predefinedState: {},
    };
    state = {};

    onSuccess = response => {
        const { onSuccess } = this.props;
        const data = response.payload[API_RESOURCE_USER];
        return onSuccess(data);
    }

    onSubmit = () => {
        const { actions, sourceUser } = this.props;
        const { targetUser } = this.state;

        this.setState({ isPending: true }, () => {
            return actions.transfer({ id: sourceUser.id, userId: fromSelectObject(targetUser) })
                .then(response => {
                    this.setState({ isPending: false });
                    toast.success('Transfer zakończony pomyślnie');
                    return this.onSuccess(response);
                })
                .catch(error => {
                    this.setState({ isPending: false });
                    toast.error('Transfer zakończony niepowodzeniem');
                });
        });
    }

    render() {
        const { location, history, isOpen, onClose, actions } = this.props;
        const { isPending, targetUser } = this.state;
        
        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
            >
                <StyledComponent className="admin-users-transfer-modal" styles={require('./styles')}>
                    <ModalBody>
                        <ModalTitle title="Transferuj użytkownika" />
                        {isPending 
                            ? (<Spinner />)
                            : (
                            <>
                                <MultiSelect
                                    name="targetUser"
                                    label="Użytkownik docelowy"
                                    placeholder="Użytkownik docelowy"
                                    required={true}
                                    dynamic={true}
                                    value={targetUser}
                                    onChange={({ value }) => this.setState({ targetUser: value })}
                                    onLoad={query => actions.list({ search: query, role: USER_ROLE_ADEPT })}
                                    onMapResponse={response => response.payload[API_RESOURCE_USERS].elements}
                                    onMapOption={user => ({
                                        value: user.id,
                                        label: `${user.surname} ${user.name} (${user.email})`,
                                    })}
                                />
                                <Button onClick={this.onSubmit}>
                                    Transferuj
                                </Button>
                        </>
                            )}
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
