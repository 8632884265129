import { connect } from 'react-redux';
import Component from './component';

import { list as listUsers } from 'Redux/modules/admin/users/actions';
import { list as listSpecializations } from 'Redux/modules/admin/specializations/actions';
import { list as listLocations } from 'Redux/modules/admin/locations/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            listUsers: dispatch(listUsers),
            listSpecializations: dispatch(listSpecializations),
            listLocations: dispatch(listLocations),
        },
    })
)(Component);
