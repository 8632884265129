import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { ENDPOINT_ADMIN_USER_PRESIGN_AVATAR } from 'Consts/api';
import { API_RESOURCE_USER } from 'Consts/apiResources';
import { USER_ROLES, USER_ROLE_ADEPT, USER_ROLE_TRAINER } from 'Consts/userRoles';
import { ADMIN_USERS_MANAGE, ADMIN_USERS, PUBLIC_RESERVATIONS_ONLINE, PUBLIC_RESERVATIONS, ADMIN_GR_HISTORY_LOGS } from 'Consts/routes';
import { LEAD_MEDIUM_LABELS, LEAD_SOURCE_LABELS } from 'Consts/leads';
import { GR_HISTORY_LOGS_CATEGORY_CREATE } from 'Consts/grHistoryLogs';
import { DATE_DEFAULT_FORMAT } from 'Consts/date';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';
import { getFormattedDate, toAppFormat, toApiFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';
import TransferModal from 'Components/admin/users/TransferModal';

export default class AdminUsersEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSuccess: PropTypes.func,
        actions: PropTypes.shape({
            update: PropTypes.func.isRequired,
            create: PropTypes.func.isRequired,
            notificationActivate: PropTypes.func.isRequired,
            removeUser: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
            createTVAccessCode: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
    };
    static defaultProps = {
        onSuccess: null,
        data: null,
        predefinedState: {},
    };

    state = {
        formState: {},
        isTransferModalVisible: false,
    };

    componentDidMount = () => {
        const { data, predefinedState } = this.props;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...this.dataToFormState(data || {}),
                ...predefinedState,
            },
        }));
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        height: data.userData?.height,
        weight: data.userData?.weight,
        birthdate: data.userData?.birthdate,
        ...data,
    })

    onSubmit = formState => {
        const { data } = this.props;

        formState = {
            ...formState,
            systemVersion: fromSelectObject(formState.systemVersion),
            displayTrainingBalance: fromSelectObject(formState.displayTrainingBalance),
            birthdate: toApiFormat(formState.birthdate),
        };

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history, onSuccess } = this.props;

        return actions.create({
            ...formState,
            role: fromSelectObject(formState.role),
        })
            .then(response => {
                if (onSuccess) {
                    return onSuccess(response);
                }

                history.push(
                    withVariables(
                        ADMIN_USERS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_USER].id }
                    )
                );
            });
    }
    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...formState,
            id: data.id,
            role: fromSelectObject(formState.role),
        });
    }

    render() {
        const { data, location, history, actions, predefinedState } = this.props;
        const { formState, isTransferModalVisible } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="admin-users-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data),
                        label: 'id',
                        value: data && data.id,
                    }, {
                        visible: Boolean(data && data.slug),
                        label: 'slug',
                        value: data && data.slug,
                    }, {
                        visible: Boolean(data),
                        label: 'stan rejestracji w GR',
                        value: data && data.grHistoryLogsLastCreate
                            ? data.grHistoryLogsLastCreate.success
                                ? 'Poprawny'
                                : 'Błąd'
                            : 'Nieznany',
                        to: data && withVariables(ADMIN_GR_HISTORY_LOGS.path, {}, { category: GR_HISTORY_LOGS_CATEGORY_CREATE, userId: data.id }),

                    }, {
                        visible: Boolean(data && data.role === USER_ROLE_ADEPT),
                        label: 'TV access code',
                        value: data?.accessCode?.code  || 'Brak',
                    }, {
                        visible: Boolean(data && data.role === USER_ROLE_ADEPT && data?.accessCode?.code),
                        label: 'Kod TV aktywny do',
                        value: data?.accessCode?.validTo || 'Brak',
                    }, {
                        visible: Boolean(data),
                        label: 'Posiada hasło?',
                        value: data && data.hasPassword
                            ? 'Tak'
                            : 'Nie',
                    }, {
                        visible: Boolean(data),
                        label: 'Data rejestracji',
                        value: data && data.createdAt
                            ? getFormattedDate(data.createdAt)
                            : 'Brak',
                    }, {
                        visible: Boolean(data),
                        label: 'Data ostatniego logowania',
                        value: data && data.lastLoginDate
                            ? toAppFormat(data.lastLoginDate)
                            : 'Brak',
                    }, {
                        visible: Boolean(data),
                        label: 'Data ostatniego logowania do aplikacji mobilnej',
                        value: data && data.mobileLastVisit
                            ? toAppFormat(data.mobileLastVisit)
                            : 'Brak',
                    }, {
                        visible: Boolean(data && data.role === USER_ROLE_ADEPT),
                        label: 'Posiada pakiet medyczny w aktywnych zamówieniach?',
                        value: data 
                            ? data.hasMedicalPackageCount && !data.doesntHaveMedicalPackageCount 
                                ? 'Tak' 
                                : !data.hasMedicalPackageCount
                                    ? 'Nie'
                                    : 'Tak: ' + (data.hasMedicalPackageCount || 0) + ' / ' + 'Nie: ' + (data.doesntHaveMedicalPackageCount || 0)
                            : null,
                    }, {
                        visible: Boolean(data && data.userData && data.userData.height),
                        label: 'Wzrost',
                        value: data && data.userData && data.userData.height
                            ? data.userData.height
                            : null,
                    }, {
                        visible: Boolean(data && data.userData && data.userData.weight),
                        label: 'Waga',
                        value: data && data.userData && data.userData.weight
                            ? data.userData.weight
                            : null,
                    }, {
                        visible: Boolean(data && data.userData && data.userData.birthdate),
                        label: 'Data urodzin',
                        value: data && data.userData && data.userData.birthdate
                            ? getFormattedDate(data.userData?.birthdate, DATE_DEFAULT_FORMAT)
                            : null,
                    }]}
                    controls={[{
                        visible: Boolean(data && data.id && data.role === USER_ROLE_ADEPT),
                        title: 'Wyślij wiadomość aktywacyjną',
                        subtitle: 'Zostanie wysłana wiadomość aktywacyjna na adres e-mail użytkownika',
                        buttonProps: {
                            onClick: () => actions.notificationActivate({ id: data.id }),
                            children: 'Wyślij',
                            confirm: {
                                enabled: true,
                            },
                        },
                    }, {
                        visible: Boolean(data && data.id && data.role === USER_ROLE_ADEPT),
                        title: 'Transferuj dane',
                        subtitle: 'Transferuj dane na inne konto',
                        buttonProps: {
                            onClick: () => this.setState({ isTransferModalVisible: true }),
                            children: 'Transferuj',
                        },
                    }, {
                        visible: Boolean(data && data.id && data.role === USER_ROLE_TRAINER && !data.enabled),
                        title: 'Aktywuj konto',
                        buttonProps: {
                            onClick: () => actions.update({ ...data, enabled: true }),
                            children: 'Aktywuj',
                        },
                    },{
                        visible: Boolean(data && data.id && data.role === USER_ROLE_ADEPT),
                        title: 'TV access code',
                        subtitle: 'Generuj TV access code',
                        buttonProps: {
                            onClick: () => actions.createTVAccessCode({ ...data })
                                .then(() => {
                                    toast.success('Kod został wygenerowany');
                                }),
                            children: 'Generuj',
                        },
                    }, {
                        visible: Boolean(data && data.id && data.role === USER_ROLE_TRAINER && data.enabled),
                        title: 'Dezaktywuj konto',
                        buttonProps: {
                            onClick: () => actions.update({ ...data, enabled: false }),
                            children: 'Dezaktywuj',
                            confirm: {
                                enabled: true,
                                message: 'Czy na pewno chcesz dezaktyować konto użytkownika?',
                            },
                        },
                    }, {
                        visible: Boolean(data && data.id && data.role === USER_ROLE_TRAINER && data.enabled),
                        title: 'Otwórz system rezerwacji online',
                        buttonProps: {
                            onClick: () => window.open(`${process.env.APP_URL}${withVariables(PUBLIC_RESERVATIONS_ONLINE.path, {}, { leadId: data.id })}`),
                            children: 'Otwórz',
                        },
                    }, {
                        visible: Boolean(data && data.id && data.role === USER_ROLE_TRAINER && data.enabled),
                        title: 'Otwórz system rezerwacji sobieski',
                        buttonProps: {
                            onClick: () => window.open(`${process.env.APP_URL}${withVariables(PUBLIC_RESERVATIONS.path, { slug: 'sobieski' }, { leadId: data.id })}`),
                            children: 'Otwórz',
                        },
                    }, {
                        visible: Boolean(data && data.id),
                        title: 'Usuń użytkownika',
                        subtitle: 'Użytkownik zostanie usunięty',
                        buttonProps: {
                            onClick: () => actions.removeUser({ id: data.id }).then(() => {
                                return history.push(ADMIN_USERS.path);
                            }),
                            children: 'Usuń',
                            confirm: {
                                enabled: true,
                                message: 'Czy na pewno chcesz usunąć tego użytkownika? Ta operacja jest nieodwracalna.',
                            },
                        },
                    }]}
                    additionalBlocks={[{
                        visible: data?.role === USER_ROLE_ADEPT,
                        key: 'userSource',
                        title: 'Dane pozyskania usera',
                        data: [{
                            label: 'Medium',
                            value: LEAD_MEDIUM_LABELS[data?.userSource?.medium] || data?.userSource?.medium ||'Brak',
                        }, {
                            label: 'Source',
                            value: LEAD_SOURCE_LABELS[data?.userSource?.source] || data?.userSource?.source || 'Brak',
                        }, {
                            label: 'Referer',
                            value: data?.userSource?.referer || 'Brak',
                        }, {
                            label: 'utm_source',
                            value: data?.userSource?.utmSource || 'Brak',
                        }, {
                            label: 'utm_medium',
                            value: data?.userSource?.utmMedium || 'Brak',
                        }, {
                            label: 'utm_campaign',
                            value: data?.userSource?.utmCampaign || 'Brak',
                        }, {
                            label: 'utm_term',
                            value: data?.userSource?.utmTerm || 'Brak',
                        }, {
                            label: 'utm_content',
                            value: data?.userSource?.utmContent || 'Brak',
                        }],
                    }]}
                    forms={[{
                        title: 'Dane',
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Imię',
                            required: true,
                        }, {
                            type: 'input',
                            name: 'surname',
                            label: 'Nazwisko',
                            required: true,
                        }, {
                            type: 'input',
                            name: 'email',
                            label: 'Adres e-mail',
                            inputProps: {
                                type: 'email',
                            },
                            required: true,
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_TRAINER),
                            type: 'input',
                            name: 'slug',
                            label: 'Slug',
                            required: true,
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_TRAINER),
                            type: 'switch',
                            name: 'visibleOnPage',
                            label: 'Widoczność na stronie',
                        }, {
                            isVisible: Boolean(!data),
                            type: 'select',
                            name: 'role',
                            label: 'Rola',
                            options: USER_ROLES.map(userRole => ({
                                value: userRole.key,
                                label: userRole.label,
                            })),
                            inputProps: {
                                disabled: Boolean(predefinedState.role),
                            },
                        }, {
                            isVisible: Boolean(data),
                            type: 'input',
                            name: 'height',
                            label: 'Wzrost',
                        }, {
                            isVisible: Boolean(data),
                            type: 'input',
                            name: 'weight',
                            label: 'Waga',
                        }, {
                            isVisible: Boolean(data),
                            type: 'datePicker',
                            name: 'birthdate',
                            label: 'Data urodzenia',
                            inputProps: {
                                datePickerProps: {
                                    showTimeSelect: false,
                                    dateFormat: 'yyyy-MM-dd',
                                    showMonthDropdown: true,
                                    showYearDropdown: true,
                                },
                            },
                        }, {
                            isVisible: Boolean(data),
                            type: 'input',
                            name: 'phone',
                            label: 'Numer telefonu',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_ADEPT),
                            type: 'input',
                            name: 'address',
                            label: 'Adres',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_ADEPT),
                            type: 'input',
                            name: 'postalCode',
                            label: 'Kod pocztowy',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_ADEPT),
                            type: 'input',
                            name: 'city',
                            label: 'Miasto',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_ADEPT),
                            type: 'input',
                            name: 'companyName',
                            label: 'Nazwa firmy',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_ADEPT),
                            type: 'input',
                            name: 'nip',
                            label: 'NIP',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_ADEPT),
                            type: 'input',
                            name: 'medium',
                            label: 'Medium pozyskania',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_ADEPT),
                            type: 'input',
                            name: 'source',
                            label: 'Źródło pozyskania',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_ADEPT),
                            type: 'textarea',
                            name: 'comment',
                            label: 'Komentarz',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_TRAINER),
                            type: 'input',
                            name: 'score',
                            label: 'Score(1-100)',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_TRAINER),
                            type: 'input',
                            name: 'googleMeetUrl',
                            label: 'Link do Google Meet',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_TRAINER),
                            type: 'textarea',
                            name: 'bioHeader',
                            label: 'Nagłówek biografii',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_TRAINER),
                            type: 'textarea',
                            name: 'bio',
                            label: 'O tobie',
                        }, {
                            isVisible: Boolean(data && data.role === USER_ROLE_TRAINER),
                            type: 's3FileUpload',
                            name: 'avatar',
                            label: 'Avatar',
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: withVariables(ENDPOINT_ADMIN_USER_PRESIGN_AVATAR, { id: data && data.id }),
                                },
                            },
                        }],
                    }]}
                />
                {isTransferModalVisible && (
                    <TransferModal
                        isOpen={true}
                        location={location}
                        history={history}
                        sourceUser={data}
                        onClose={() => this.setState({ isTransferModalVisible: false })}
                        onSuccess={targetUser => {
                            this.setState({ isTransferModalVisible: false });
                            history.push(
                                withVariables(ADMIN_USERS_MANAGE.path, { id: targetUser.id })
                            );
                        }}
                    />
                )}
            </StyledComponent>
        );
    }
}