import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PAYMENT_TRANSACTION_STATUS_COMPLETED, PAYMENT_TRANSACTION_STATUS_CESSION } from 'Consts/paymentTransactions';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getStatusLabel, getTypeLabel } from 'Utils/paymentTransaction';
import { getTypeLabel as getPaymentMethodTypeLabel } from 'Utils/paymentMethods';
import { getFormattedDate } from 'Utils/date';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';
import PaymentTransactionStatusModal from 'Components/admin/paymentTransactions/PaymentTransactionStatusModal';
import PaymentTransactionRefundModal from 'Components/admin/paymentTransactions/PaymentTransactionRefundModal';

export default class AdminPaymentTransactionsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            setAsPaid: PropTypes.func.isRequired,
            status: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
        }).isRequired,
        paymentTransactions: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapControls: PropTypes.func,
        onMapAdditionals: PropTypes.func,
        onMapFilters: PropTypes.func,
        onMapLabels: PropTypes.func,
        onMapTitle: PropTypes.func,
        onMapSubTitle: PropTypes.func,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapControls: (elem, value) => value,
        onMapAdditionals: (elem, value) => value,
        onMapFilters: value => value,
        onMapLabels: (elem, value) => value,
        onMapTitle: (elem, value) => value,
        onMapSubTitle: (elem, value) => value,
    };

    state = {
        refundModalVisible: false,
        refundModalData: {},
        statusModalVisible: false,
        statusModalData: null,
    }

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        promoCodeId: undefined,
        timestamp: undefined,
        ...this.props.predefinedQuery, //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;

        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(predefinedQuery) !== JSON.stringify(prevProps.predefinedQuery)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    showStatusModal = element => {
        const { actions } = this.props;

        this.setState({ statusModalVisible: true }, () => {
            actions.status({ id: element.id })
                .then(data => {
                    const { paymentTransactionStatus } = data.payload;
                    
                    this.setState({ statusModalData: paymentTransactionStatus });
                });
        });
    }

    closeStatusModal = () => {
        this.setState({ statusModalVisible: false, statusModalData: null });
    }

    showRefundModal = element => {
        const { id } = element;
        this.setState({ refundModalData: { id }, refundModalVisible: true });
    }

    closeRefundModal = () => {
        this.setState({ refundModalData: {}, refundModalVisible: false });
    }

    render() {
        const {
            actions,
            paymentTransactions,
            location,
            history,
            onMapControls,
            onMapAdditionals,
            onMapFilters,
            onMapLabels,
            onMapTitle,
            onMapSubTitle,
        } = this.props;
        const { statusModalVisible, statusModalData, refundModalVisible, refundModalData } = this.state;

        return (
            <StyledComponent
                className="admin-payment-transactions-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={paymentTransactions}
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={onMapTitle(element, formatPrice(element.value) + ` (${getFormattedDate(element.createdAt)})`)}
                            subtitle={onMapSubTitle(element, element.userOffer && element.userOffer.offer
                                ? element.userOffer.name || element.userOffer.offer.name
                                : getTypeLabel(element).label
                            )}
                            labels={onMapLabels(element, [{
                                isVisible: true,
                                label: getStatusLabel(element).label,
                                state: getStatusLabel(element).stateColor,
                            }])}
                            additionals={onMapAdditionals(element, [{
                                visible: Boolean(element.paymentMethod),
                                name: 'Typ płatności',
                                value: getPaymentMethodTypeLabel(element.paymentMethod).label,
                            }, {
                                visible: Boolean(element.refundAt),
                                name: 'Data zwrotu płatności',
                                value: element.refundAt,
                            }])}
                            controls={onMapControls(element, [{
                                type: 'button',
                                label: 'Pobierz  fakturę',
                                visible: Boolean(element.invoice && element.invoice.url),
                                href: element.invoice && element.invoice.url,
                                target: '_blank',
                            }, {
                                type: 'button',
                                label: 'Oznacz jako opłaconą',
                                visible: false,
                                // visible: Boolean(element.status !== PAYMENT_TRANSACTION_STATUS_COMPLETED),
                                onClick: () => actions.setAsPaid({ id: element.id }),
                                buttonProps: {
                                    confirm: {
                                        enabled: true,
                                    },
                                },
                            }, {
                                type: 'button',
                                visible: element.status === PAYMENT_TRANSACTION_STATUS_COMPLETED,
                                label: 'Zwrot płatności',
                                onClick: () => this.showRefundModal(element),
                            }, {
                                type: 'button',
                                label: 'Status płatności',
                                onClick: () => this.showStatusModal(element),
                            }, {
                                type: 'button',
                                visible: element.status === PAYMENT_TRANSACTION_STATUS_COMPLETED,
                                label: 'Cesja',
                                onClick: () => actions.update({ id: element.id, status: PAYMENT_TRANSACTION_STATUS_CESSION }),
                                buttonProps: {
                                    confirm: {
                                        enabled: true,
                                    },
                                },
                            }])}
                        />
                    )}
                    filters={onMapFilters([])}
                />
                {statusModalVisible && (
                    <PaymentTransactionStatusModal
                        location={location}
                        history={history}
                        isOpen={true}
                        onClose={this.closeStatusModal}
                        data={statusModalData}
                    />
                )}
                <PaymentTransactionRefundModal
                    location={location}
                    history={history}
                    isOpen={refundModalVisible}
                    onClose={this.closeRefundModal}
                    data={refundModalData}
                />
            </StyledComponent>
        );
    }
}
