export const OFFER_TYPE_DEFAULT = 'default';
export const OFFER_TYPE_DIAGNOSTIC = 'diagnostic';
export const OFFER_TYPE_DIAGNOSTIC_MASSAGE = 'diagnosticMassage';
export const OFFER_TYPES = [{
    key: OFFER_TYPE_DEFAULT,
    label: 'Abonamentowa',
}, {
    key: OFFER_TYPE_DIAGNOSTIC,
    label: 'Diagnostyczna',
}, {
    key: OFFER_TYPE_DIAGNOSTIC_MASSAGE,
    label: 'Masaż diagnostyczny',
}];

export const OFFER_VISIBILITY_HIDDEN = 'hidden';
export const OFFER_VISIBILITY_PRICING = 'pricing';
export const OFFER_VISIBILITY_PRIVATE = 'private';
export const OFFER_VISIBILITIES = [{
    key: OFFER_VISIBILITY_HIDDEN,
    label: 'Ukryta',
}, {
    key: OFFER_VISIBILITY_PRICING,
    label: 'Widoczna w cenniku',
}, {
    key: OFFER_VISIBILITY_PRIVATE,
    label: 'Prywatna',
}];

export const OFFER_DURATION_TYPE_DAYS = 'days';
export const OFFER_DURATION_TYPE_MONTHS = 'months';
export const OFFER_DURATION_TYPE_YEARS = 'years';
export const OFFER_DURATION_TYPES = [{
    key: OFFER_DURATION_TYPE_DAYS,
    label: 'Dni',
}, {
    key: OFFER_DURATION_TYPE_MONTHS,
    label: 'Miesiące',
}, {
    key: OFFER_DURATION_TYPE_YEARS,
    label: 'Lata',
}];

export const OFFER_PERIOD_TYPE_SINGLE = 'single';
export const OFFER_PERIOD_TYPE_RECURRING = 'recurring';
export const OFFER_PERIOD_TYPES = [{
    key: OFFER_PERIOD_TYPE_SINGLE,
    label: 'Pojedyncza',
}, {
    key: OFFER_PERIOD_TYPE_RECURRING,
    label: 'Cykliczna',
}];

export const OFFER_SHOP_CONFIGURATOR_OFFLINE_METAMORPHOSIS = 'konfigurator-offline-metamorfoza';
export const OFFER_SHOP_CONFIGURATOR_OFFLINE_FLEXIBLE = 'konfigurator-offline-elastyczna';
export const OFFER_SHOP_CONFIGUTATOR_OFFLINE_SINGLE = 'konfigurator-offline-jednorazowa';
export const OFFER_SHOP_CONFIGURATOR_ONLINE_METAMORPHOSIS = 'konfigurator-online-metamorfoza';
export const OFFER_SHOP_CONFIGURATOR_ONLINE_FLEXIBLE = 'konfigurator-online-elastyczna';
export const OFFER_SHOP_CONFIGUTATOR_ONLINE_SINGLE = 'konfigurator-online-jednorazowa';
export const OFFER_SHOP_CONFIGUTATOR_MASSAGE_SINGLE = 'konfigurator-masaz-jednorazowa';
export const OFFER_SHOP_CONFIGUTATOR_MASSAGE_FLEXIBLE = 'konfigurator-masaz-elastyczna';

export const OFFER_FIRST_PAYMENT_METHOD_TYPE_BOTH = 'both';
export const OFFER_FIRST_PAYMENT_METHOD_TYPE_RECURRING = 'recurring';
export const OFFER_FIRST_PAYMENT_METHOD_TYPE_GATEWAY = 'gateway';
export const OFFER_FIRST_PAYMENT_METHOD_TYPES = [{
    key: OFFER_FIRST_PAYMENT_METHOD_TYPE_BOTH,
    label: 'Wybór klienta',
}, {
    key: OFFER_FIRST_PAYMENT_METHOD_TYPE_RECURRING,
    label: 'Karty',
}, {
    key: OFFER_FIRST_PAYMENT_METHOD_TYPE_GATEWAY,
    label: 'Bramka płatności',
}];
