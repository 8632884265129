import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        &.modal-display {
            .admin-element-editor {
                .header-wrapper {
                    display: none;
                }

                .editor-wrapper {
                    .forms-wrapper {
                        width: 65%;
                        padding-right: 2%;

                        .form-wrapper {
                            margin-top: 0;
                            padding-top: 0;
                            border-top: none;

                            .form-elements {
                                display: flex;
                                flex-wrap: wrap;
                                
                                .multi-select.style-default {
                                    &:last-of-type:nth-child(odd) {
                                        width: 100%;
                                    }
                                }
                                
                                .date-picker {
                                    width: 100%;
                                }
                                
                                & > * {
                                    width: 48%;
                                    margin-right: 2%;
                                }

                            }
                        }
                    }

                    .data-wrapper {
                        width: 35%;

                        .details-wrapper {
                            margin-top: 0;
                            padding-top: 0;
                            border-top: none;
                        }
                    }
                }
            }
        }
    `;
