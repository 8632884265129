import { withVariables } from 'Utils/string';
import { reduxRequest as request } from 'Services/Api';

import * as types from './types';
import * as endpoints from 'Consts/api';

export const list = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.LIST,
        method: 'GET',
        path: endpoints.ENDPOINT_ADMIN_PAYMENT_TANSACTIONS,
        params,
        requestParams: {
            page: params.page || 1,
            perPage: params.perPage || 10,
            search: params.search || undefined,
            orderBy: params.orderBy || 'createdAt',
            status: params.status || undefined,
            userOfferId: params.userOfferId || undefined,
            userId: params.userId || undefined,
            promoCodeId: params.promoCodeId || undefined,
        },
    }));
};

export const single = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SINGLE,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PAYMENT_TANSACTION, params),
        params,
    }));
};

export const update = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.UPDATE,
        method: 'PUT',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PAYMENT_TANSACTION, params),
        params,
        requestParams: params,
    }));
};

export const setAsPaid = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.SET_AS_PAID,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PAYMENT_TANSACTION_COMPLETE, params),
        params,
        requestParams: params,
    }));
};

export const refund = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.REFUND,
        method: 'POST',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PAYMENT_TANSACTION_REFUND, params),
        params,
        requestParams: params,
    }));
};

export const status = dispatch => (params = {}) => {
    return dispatch(request({
        reduxType: types.STATUS,
        method: 'GET',
        path: withVariables(endpoints.ENDPOINT_ADMIN_PAYMENT_TRANSACTION_STATUS, params),
        params,
        requestParams: params,
    }));
};
