import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import StyledComponent from 'Components/core/StyledComponent';
import UsersList from 'Components/admin/events/CalendarUsersList';
import Calendar from 'Components/admin/events/Calendar2';

export default class PanelEventsSelectCalendar extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        onSelectSlot: PropTypes.func,
        data: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
        onSelectSlot: null,
        data: null,
    };
    state = {
        selectedSpecialization: null,
        selectedLocation: null,
        activeUsers: [],
    };

    render() {
        const { location, history, onSelectSlot, data } = this.props;
        const { activeUsers, selectedSpecialization, selectedLocation } = this.state;

        return (
            <StyledComponent
                className="admin-events-select-calendar"
                styles={require('./styles')}
            >
                <div className="calendar-wrapper">
                    <Calendar 
                        location={location}
                        history={history}
                        shouldLoadData={activeUsers.length > 0}
                        predefinedQuery={{
                            leadIds: activeUsers.length >= 1
                                ? activeUsers.map(activeUser => activeUser.id)
                                : null,
                            lead: activeUsers.length === 1
                                ? activeUsers[0]
                                : null,
                        }}
                        onSelectSlot={(slot) => {
                            if(activeUsers.length === 0) {
                                toast.warn('Wybierz prowadzącego');
                                return false;
                            }

                            if(activeUsers.length > 1) {
                                toast.warn('Wybierz tylko jednego prowadzącego');
                                return false;
                            }

                            if(onSelectSlot) {
                                onSelectSlot(slot, activeUsers[0]);
                                return false;
                            }
                            return true;
                        }}
                    />
                </div>
                <div className="users-list-wrapper">
                    <UsersList
                        location={location}
                        history={history}
                        activeUsers={activeUsers}
                        onChangeActiveUsers={(activeUsers) => this.setState({ activeUsers })}
                        selectedSpecialization={selectedSpecialization}
                        selectedLocation={selectedLocation}
                        onChangeSpecialization={(specialization) => this.setState({ selectedSpecialization: specialization })}
                        onChangeLocation={(location) => this.setState({ selectedLocation: location })}
                        data={data}
                    />
                </div>
            </StyledComponent>
        );
    }
}
