import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';

export default class PanelUserOfferChangeLog extends Component {
    static propTypes = {
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {};

    getReactJson = (props) => {
        if (typeof window !== 'undefined') {
            const ReactJson = require('react-json-view').default; // ssr workaround
            return <ReactJson {...props} />;
        }

        return null;
    }

    render() {
        const { data } = this.props;

        return (
            <StyledComponent
                className="panel-user-offer-change-log"
                styles={require('./styles')}
            >
                {this.getReactJson(data)}
            </StyledComponent>
        );
    }
}