import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 60vw;

    .loading {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 1em;
    }

    .data {
        span {
            font-size: 1.2em;
        }

        .row {
            margin-bottom: .5em;
        }

        .label {
            font-weight: bold;
        }
    }
`;
