export const ACTIVITY_LOGS_DATA_TYPE_EVENT = 'Event';
export const ACTIVITY_LOGS_DATA_TYPE_OFFER = 'Offer';
export const ACTIVITY_LOGS_DATA_TYPE_USER_OFFER = 'UserOffer';
export const ACTIVITY_LOGS_DATA_TYPE_LOCATION = 'Location';
export const ACTIVITY_LOGS_DATA_TYPE_MEASUREMENT = 'Measurement';
export const ACTIVITY_LOGS_DATA_TYPE_MEDICAL_CARD = 'MedicalCard';
export const ACTIVITY_LOGS_DATA_TYPE_OFFER_PRODUCT = 'OfferProduct';
export const ACTIVITY_LOGS_DATA_TYPE_PAYMENT = 'PaymentTransaction';
export const ACTIVITY_LOGS_DATA_TYPE_PRODUCT_CATEGORY = 'ProductCategory';
export const ACTIVITY_LOGS_DATA_TYPE_PRODUCT_SPECIALIZATION = 'ProductSpecialization';
export const ACTIVITY_LOGS_DATA_TYPE_PRODUCT = 'Product';
export const ACTIVITY_LOGS_DATA_TYPE_PROMO_CODE = 'PromoCode';
export const ACTIVITY_LOGS_DATA_TYPE_SPECIALIZATION = 'Specialization';
export const ACTIVITY_LOGS_DATA_TYPE_TRAINER_USER = 'LeadUser';
export const ACTIVITY_LOGS_DATA_TYPE_TRAINING_TARGET = 'TrainingTarget';
export const ACTIVITY_LOGS_DATA_TYPE_USER_CONSENT = 'UserConsent';
export const ACTIVITY_LOGS_DATA_TYPE_USER_NOTES = 'UserNote';
export const ACTIVITY_LOGS_DATA_TYPE_USER_SOURCES = 'UserSource';
export const ACTIVITY_LOGS_DATA_TYPE_USER_OFFER_DIAGNOSTIC_REPORT = 'UserOfferDiagnosticReport';
export const ACTIVITY_LOGS_DATA_TYPE_USER_PAYMENT_METHOD = 'UserPaymentMethod';
export const ACTIVITY_LOGS_DATA_TYPE_USER_SPECIALIZATION = 'UserSpecialization';
export const ACTIVITY_LOGS_DATA_TYPE_USER_SURVEY = 'UserSurvey';
export const ACTIVITY_LOGS_DATA_TYPE_USER_TRANSFER = 'UserTransfer';
export const ACTIVITY_LOGS_DATA_TYPE_USER_WORK_HOURS = 'UserWorkHours';
export const ACTIVITY_LOGS_DATA_TYPE_USER = 'User';
export const ACTIVITY_LOGS_DATA_TYPE_AUTH_TOKEN = 'AuthToken';
export const ACTIVITY_LOGS_DATA_TYPE_USER_PERMISSION = 'UserPermission';
export const ACTIVITY_LOGS_DATA_TYPE_USER_HOMEWORK = 'UserHomework';
export const ACTIVITY_LOGS_DATA_TYPE_SHOP_PAGE = 'ShopPage';
export const ACTIVITY_LOGS_DATA_TYPE_USER_EFFECT = 'UserEffect';
export const ACTIVITY_LOGS_DATA_TYPE_CUSTOM_EVENT = 'CustomEvent';

export const ACTIVITY_LOGS_DATA_TYPES = [
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_SURVEY,
        label: 'Ankieta',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_TRAINING_TARGET,
        label: 'Cel treningowy',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_EFFECT,
        label: 'Efekt',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_WORK_HOURS,
        label: 'Godziny pracy',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_MEDICAL_CARD,
        label: 'Karta medyczna',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_PRODUCT_CATEGORY,
        label: 'Kategoria produktu',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_PROMO_CODE,
        label: 'Kod promocyjny',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_LOCATION,
        label: 'Lokacja',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_PAYMENT_METHOD,
        label: 'Metoda płatności',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_NOTES,
        label: 'Notatka użytkownika',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_OFFER,
        label: 'Oferta',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_HOMEWORK,
        label: 'Praca domowa',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_MEASUREMENT,
        label: 'Pomiar',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_PRODUCT,
        label: 'Produkt',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_OFFER_PRODUCT,
        label: 'Produkt w ofercie',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_TRAINER_USER,
        label: 'Przypisanie adepta do trenera',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_PAYMENT,
        label: 'Płatność',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_OFFER_DIAGNOSTIC_REPORT,
        label: 'Raport diagnostyczny',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_SPECIALIZATION,
        label: 'Specjalizacja',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_PRODUCT_SPECIALIZATION,
        label: 'Specjalizacja produktu',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_SPECIALIZATION,
        label: 'Specjalizacja użytkownika',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_SHOP_PAGE,
        label: 'Sklep',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_AUTH_TOKEN,
        label: 'Token autoryzacji',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_TRANSFER,
        label: 'Transfer',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_PERMISSION,
        label: 'Uprawnienia',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER,
        label: 'Użytkownik',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_EVENT,
        label: 'Wydarzenie',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_CUSTOM_EVENT,
        label: 'Wydarzenie niestandardowe',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_OFFER,
        label: 'Zamówienie',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_CONSENT,
        label: 'Zgody użytkownika',
    },
    {
        key: ACTIVITY_LOGS_DATA_TYPE_USER_SOURCES,
        label: 'Źródła użytkownika',
    },
];

export const ACTIVITY_LOGS_EVENT_TYPE_CREATING = 'creating';
export const ACTIVITY_LOGS_EVENT_TYPE_UPDATING = 'updating';
export const ACTIVITY_LOGS_EVENT_TYPE_DELETING = 'deleting';

export const ACTIVITY_LOGS_EVENT_TYPES = [
    {
        key: ACTIVITY_LOGS_EVENT_TYPE_CREATING,
        label: 'Utworzenie',
        state: 'success',
    },
    {
        key: ACTIVITY_LOGS_EVENT_TYPE_UPDATING,
        label: 'Edycja',
        state: 'warning',
    },
    {
        key: ACTIVITY_LOGS_EVENT_TYPE_DELETING,
        label: 'Usunięcie',
        state: 'alert',
    },
];
