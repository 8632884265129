import { connect } from 'react-redux';
import Component from './component';

import {
    stats,
} from 'Redux/modules/admin/events/actions';

export default connect(
    state => ({
        stats: state.adminEvents.stats,
    }),
    dispatch => ({
        actions: {
            stats: dispatch(stats),
        },
    })
)(Component);
