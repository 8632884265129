import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { 
    ADMIN_USER_OFFERS, 
    ADMIN_USER_OFFERS_MANAGE, 
    ADMIN_USER_OFFER_PRODUCTS_CREATE, 
    ADMIN_USER_OFFER_INVOICE_ITEMS_CREATE, 
    ADMIN_EVENTS_ADD,
    ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_CREATE,
} from 'Consts/routes';
import { ACTIVITY_LOGS_DATA_TYPE_USER_OFFER } from 'Consts/activityLogs';
import { USER_OFFER_USERS_TYPE_MULTI } from 'Consts/userOffers';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/userOffers/Editor';
import EventsList from 'Components/admin/events/List';
import EventsCalendar from 'Components/admin/events/Calendar';
import ProductsList from 'Components/admin/userOfferProducts/List';
import InvoiceItemsList from 'Components/admin/userOfferInvoiceItems/List';
import PaymentTransactionsList from 'Components/admin/paymentTransactions/List';
import EventsStats from 'Components/admin/events/Stats';
import { TAB_USER_OFFERS } from 'Components/pages/admin/UserOffers/component';
import Logs from 'Components/admin/userOffers/Logs';
import DiagnosticReportsList from 'Components/admin/userOfferDiagnosticReports/List';
import LogsList from 'Components/admin/activityLogs/List';
import InvitedUsersList from 'Components/admin/userOffers/InvitedUsersList';
import UserOfferInvite from 'Components/admin/modals/UserOfferInviteUser';

export const TAB_DATA = 'data';
export const TAB_EVENTS_LIST = 'eventsList';
export const TAB_EVENTS_CALENDAR = 'eventsCalendar';
export const TAB_PRODUCTS_LIST = 'productsList';
export const TAB_INVOICE_ITEMS_LIST = 'invoiceItems';
export const TAB_EVENTS_STATS = 'eventsStats';
export const TAB_PAYMENT_TRANSACTIONS_LIST = 'paymentTransactionsList';
export const TAB_LOGS = 'logs';
export const TAB_DIAGNOSTIC_REPORTS = 'diagnosticReports';
export const TAB_USER_OFFER_LOGS = 'userOfferLogs';
export const TAB_USER_OFFER_SUB_USERS = 'userOfferSubUsers';

export default class AdminUserOffersManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        userOffer: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {
        isModalEventAddVisible: false,
        invite: false,
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { userOffer, location, history } = this.props;
        const { invite } = this.state;
        const { search } = location;

        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="admin-user-offers-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={userOffer}
                >
                    {userOffer && userOffer.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(ADMIN_USER_OFFERS.path),
                                label: 'Zamówienia',
                            }, {
                                to: withVariables(ADMIN_USER_OFFERS.path, {}, { tab: TAB_USER_OFFERS }),
                                label: 'Lista zamówień',
                            }, {
                                to: withVariables(ADMIN_USER_OFFERS_MANAGE.path, { id: userOffer.data.id }, { tab: TAB_DATA }),
                                label: `Edytuj zamówienie: ${ userOffer.data.name }`,
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Edytuj zamówienie"
                                controls={[{
                                    visible: Boolean(queryObject.tab === TAB_EVENTS_LIST),
                                    key: 'addEvents',
                                    label: 'Dodaj sloty wydarzeń',
                                    onClick: () => history.push(
                                        withVariables(
                                            ADMIN_EVENTS_ADD.path,
                                            {},
                                            { userOfferId: userOffer.data.id }
                                        )
                                    ),
                                }, {
                                    visible: Boolean(queryObject.tab === TAB_DIAGNOSTIC_REPORTS),
                                    key: 'createDiagnosticReport',
                                    label: 'Nowy raport diagnostyczny',
                                    onClick: () => history.push(
                                        withVariables( 
                                            ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_CREATE.path,
                                            {},
                                            { userOfferId: userOffer.data.id }
                                        )
                                    ),
                                }, {
                                    visible: Boolean(queryObject.tab === TAB_PRODUCTS_LIST),
                                    key: 'assignProduct',
                                    label: 'Przypisz produkt',
                                    onClick: () => history.push(
                                        withVariables(
                                            ADMIN_USER_OFFER_PRODUCTS_CREATE.path,
                                            {},
                                            { userOfferId: userOffer.data.id }
                                        )
                                    ),
                                }, {
                                    visible: Boolean(queryObject.tab === TAB_INVOICE_ITEMS_LIST),
                                    key: 'createInvoiceItem',
                                    label: 'Nowa pozycja FV',
                                    onClick: () => history.push(
                                        withVariables(
                                            ADMIN_USER_OFFER_INVOICE_ITEMS_CREATE.path,
                                            {},
                                            { userOfferId: userOffer.data.id }
                                        )
                                    ),
                                }, {
                                    visible: Boolean(queryObject.tab === TAB_USER_OFFER_SUB_USERS),
                                    key: 'inviteUser',
                                    label: 'Zaproś użytkownika',
                                    onClick: () => this.setState({ invite: true }),
                                }]}                                
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={userOffer.data}
                                        />
                                    ),
                                }, {
                                    key: TAB_EVENTS_LIST,
                                    label: 'Lista wydarzeń',
                                    children: (
                                        <EventsList
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                userOfferId: userOffer.data.id,
                                            }}
                                        />
                                    ),
                                }, {
                                    key: TAB_EVENTS_CALENDAR,
                                    label: 'Kalendarz wydarzeń',
                                    children: (
                                        <EventsCalendar
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                userOfferId: userOffer.data.id,
                                                user: userOffer?.data?.user,
                                                userOffer: userOffer.data,
                                            }}
                                        />
                                    ),
                                }, {
                                    key: TAB_PRODUCTS_LIST,
                                    label: 'Lista produktów',
                                    children: (
                                        <ProductsList
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                userOfferId: userOffer.data.id,
                                            }}
                                        />
                                    ),
                                }, {
                                    key: TAB_INVOICE_ITEMS_LIST,
                                    label: 'Lista pozycji FV',
                                    children: (
                                        <InvoiceItemsList
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                userOfferId: userOffer.data.id,
                                            }}
                                        />
                                    ),
                                }, {                                    
                                    key: TAB_PAYMENT_TRANSACTIONS_LIST,
                                    label: 'Płatności',
                                    children: (
                                        <PaymentTransactionsList
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                userOfferId: userOffer.data.id,
                                            }}
                                        />
                                    ),
                                }, {
                                    key: TAB_EVENTS_STATS,
                                    label: 'Statystyki',
                                    children: (
                                        <EventsStats
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                userOfferId: userOffer.data.id,
                                            }}
                                        />
                                    ),
                                }, {
                                    key: TAB_LOGS,
                                    label: 'Historia zmian',
                                    children: (
                                        <Logs 
                                            history={history}
                                            location={location}
                                            predefinedQuery={{
                                                userOfferId: userOffer.data.id,
                                            }}  
                                        />
                                    ),
                                }, {
                                    key: TAB_DIAGNOSTIC_REPORTS,
                                    label: 'Raporty diagnostyczne',
                                    children: (
                                        <DiagnosticReportsList
                                            history={history}
                                            location={location}
                                            predefinedQuery={{
                                                userOfferId: userOffer.data.id,
                                            }}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_USER_OFFER_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_USER_OFFER,
                                                dataId: userOffer.data.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_USER_OFFER_SUB_USERS,
                                    label: 'Zaproszeni użytkownicy',
                                    visible: Boolean(userOffer.data.usersType === USER_OFFER_USERS_TYPE_MULTI),
                                    children: (
                                        <InvitedUsersList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                userOfferId: userOffer.data.id,
                                            }}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
                <UserOfferInvite
                    location={location}
                    history={history}
                    isOpen={Boolean(invite)}
                    onClose={() => this.setState({ invite: false })}
                    data={{ userOffer, invite }}
                />
            </StyledComponent>
        );
    }
}

