import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ADMIN_USER_OFFERS_MANAGE } from 'Consts/routes';
import { DATE_UNICODE_FORMAT } from 'Consts/date';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getStatusLabel } from 'Utils/userOffer';
import { getFullName as getUserFullName } from 'Utils/user';
import { getName as getPromoCodeName } from 'Utils/promoCode';
import { getFormattedDate } from 'Utils/date';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';
import DatePicker from 'Components/forms/DatePicker';

export default class PanelUserOffersIncomeList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            income: PropTypes.func.isRequired,
        }).isRequired,
        userOffers: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        paidToFrom: undefined,
        paidToTo: moment()
            .endOf('month')
            .toDate(),
        ...this.props.predefinedQuery,  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.income({ ...queryObject });
        }, 500);
    }

    render() {
        const { userOffers, location, history } = this.props;
        const queryObject = this.getQueryConfig();

        return (
            <StyledComponent
                className="panel-user-offers-income-list"
                styles={require('./styles')}
            >
                <div className="summary">
                    <div className="summary-label">
                        Kwota przychodów za dany okres
                    </div>
                    <div className="summary-value">
                        {userOffers && !isNaN(userOffers.income) && formatPrice(userOffers.income) || '...'}
                    </div>
                </div>
                <div className="date-select">
                    <span className="time-section time-section-picker">
                        <DatePicker
                            name="paidToFrom"
                            label="Data od"
                            onChange={({ value }) => history.push(
                                withVariables(
                                    location.pathname,
                                    {},
                                    { ...parseQueryToObject(location.search), paidToFrom: value.toDate() }
                                )
                            )}
                            value={queryObject.paidToFrom && moment(queryObject.paidToFrom).toDate() || null}
                            dateTimePickerProps={{
                                maxDate: queryObject.paidToFrom && moment(queryObject.paidToFrom).toDate() || undefined,
                                showTimeSelect: false,
                                dateFormat: DATE_UNICODE_FORMAT,
                            }}
                        />
                    </span>
                    <span className="time-section time-section-picker">
                        <DatePicker
                            name="paidToTo"
                            label="Data do"
                            onChange={({ value }) => history.push(
                                withVariables(
                                    location.pathname,
                                    {},
                                    { ...parseQueryToObject(location.search), paidToTo: value.toDate() }
                                )
                            )}
                            value={queryObject.paidToTo && moment(queryObject.paidToTo).toDate() || null}
                            dateTimePickerProps={{
                                minDate: queryObject.paidToTo && moment(queryObject.paidToTo).toDate() || undefined,
                                showTimeSelect: false,
                                dateFormat: DATE_UNICODE_FORMAT,
                            }}
                        />
                    </span>
                </div>

                <PaginatedList
                    location={location}
                    history={history}
                    collection={userOffers}
                    title="Lista przychodów"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.name}
                            subtitle={element.user && getUserFullName(element.user).label || 'Brak'}
                            additionals={[{
                                name: 'Czy ma aktywną metodę płatności',
                                value: element.hasActivePaymentMethod ? 'Tak' : 'Nie',
                            }, {
                                name: 'Data utworzenia',
                                value: element.createdAt && getFormattedDate(element.createdAt) || 'Brak',
                            }, {
                                name: 'Opłacone do',
                                value: element.paidTo && getFormattedDate(element.paidTo, 'date') || 'Brak',
                            }, {
                                name: 'Aktywne do',
                                value: element.activeTo && getFormattedDate(element.activeTo, 'date') || 'Brak',
                            }, {
                                name: 'Obłsugiwane do',
                                value: element.terminatedTo && getFormattedDate(element.terminatedTo, 'date') || 'Brak',
                            }, {
                                name: 'Kod promocyjny',
                                value: element.promoCode && getPromoCodeName(element.promoCode).label || 'Brak',
                            }, {
                                name: 'Status',
                                value: element.status && getStatusLabel(element).label || 'Brak',
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Zamówienie',
                                visible: true,
                                to: withVariables(ADMIN_USER_OFFERS_MANAGE.path, { id: element.id }),
                            }]}
                        />
                    )}
                    filters={[
                        {
                            name: 'search',
                            label: 'Szukaj',
                            type: 'text',
                        },
                    ]}
                />
            </StyledComponent>
        );
    }
}
