import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { toApiFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelUserOffersCloseEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
        actions: PropTypes.shape({
            close: PropTypes.func.isRequired,
        }).isRequired,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {
            ...this.props.data, //eslint-disable-line react/destructuring-assignment
            closedAt: this.props.data?.closedAt || moment().toDate(), //eslint-disable-line react/destructuring-assignment
        },
    };
    
    onSubmit = () => {
        const { formState } = this.state;
        const { data, actions, onSuccess } = this.props;

        return actions.close({
            id: data.id,
            closedAt: toApiFormat(formState.closedAt, 'datetime', true),
            closeReason: formState.closeReason,
        })
            .then(() => {
                onSuccess();
            });
    }

    render() {
        const { location, history, data } = this.props;

        return (
            <StyledComponent
                className="panel-user-offers-close-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            isVisible: true,
                            type: 'datePicker',
                            name: 'closedAt',
                            label: 'Data zamknięcia',
                        }, {
                            isVisible: true,
                            type: 'textarea',
                            name: 'closeReason',
                            label: 'Powód zamknięcia',
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}