import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .calendar-wrapper {
        width: 65%;
        background: white;
        padding: 1em;
    }

    .users-list-wrapper {
        width: 30%;
    }
`;
