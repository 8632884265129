import { connect } from 'react-redux';
import Component from './component';

import {
    list,
    single,
} from 'Redux/modules/admin/activityLogs/actions';

export default connect(
    state => ({
        activityLogs: state.adminActivityLogs.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            single: dispatch(single),
        },
    })
)(Component);
