import { connect } from 'react-redux';
import Component from './component';

import {
    logs,
} from 'Redux/modules/admin/userOffers/actions';

export default connect(
    state => ({
        userOfferLogs: state.adminUserOffers.logs,
    }),
    dispatch => ({
        actions: {
            logs: dispatch(logs),
        },
    }),
)(Component);