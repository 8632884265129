import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 100%;
    height: 100%;
    padding: 0 .3em;

    &.state-private {
        background: #787878;
    }

    &.state-done {
        background: #1e8000
    }

    &.state-notDone {
        background: #D83143;
    }

    &.state-notDoneExpired {
        background: #D83143;
    }

    &.state-plannedPaid {
        background: #5f72ff;
    }

    &.state-plannedNotPaid {
        background: #5f72ff;
    }

    &.state-canceled {
        background: #545454;
    }
`;
