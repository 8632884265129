import { connect } from 'react-redux';
import Component from './component';

import {
    list,
    setAsPaid,
    status,
    update,
} from 'Redux/modules/admin/paymentTransactions/actions';

export default connect(
    state => ({
        paymentTransactions: state.adminPaymentTransactions.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            setAsPaid: dispatch(setAsPaid),
            status: dispatch(status),
            update: dispatch(update),
        },
    })
)(Component);
