import { connect } from 'react-redux';
import Component from './component';

import {
    cancel,
    terminationDate,
} from 'Redux/modules/admin/userOffers/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            cancel: dispatch(cancel),
            terminationDate: dispatch(terminationDate),
        },
    }),
)(Component);