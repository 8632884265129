import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .payment-methods {
            .user-payment-methods {
                .user-payment-methods-headline {
                    text-align: left;
                    font-size: 1.2em;
                    font-weight: 300;
                    color: white;
                }
                .user-payment-methods-list {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 1em;
                    .user-payment-method {
                        margin-bottom: 1em;
                        margin-right: 1em;
                        padding: 1em;
                        background: #333333;
                    }
                }
                .new-payment-method {
                    display: block;
                    margin-top: 2em;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            .user-payment-methods {
                .user-payment-methods-headline {
                    margin-top: 1em;
                    font-size: 2.8em;
                    font-weight: 600;
                    color: white;
                    text-align: left;
                }
                .user-payment-methods-list {
                    margin-top: 3em;
                    .user-payment-method {
                        position: relative;
                        margin-bottom: 1em;
                        background: transparent;
                        color: #FFFFFF;
                        font-weight: 300;
                        font-size: .9em;
                        cursor: pointer;
                        border: 1px solid #3E3E3E;
                        padding-right: 4em;
                        padding-left: 1em;
                        b {
                            font-weight: 600;
                        }
                        &:hover {
                            background: #222222;
                        }
                        &.selected {
                            box-shadow: 0 0 0 .1em #FF0031 inset;
                            box-shadow: 0px 0px 5px #FF003166;
                            color: #FFFFFF;
                            border-radius: .2em;
                            font-weight: 500;
                            border: 1px solid #DF3846;
                            transition: all .3s ease-in-out;
                            text-transform: uppercase;
                            &:hover {
                                background: ${variables.dpc_backgroundWhite};
                            }
                            background-image: linear-gradient(to left, #DA3143, #FF6254) ;
                        }
                    }
                }
            }
        }
    `;