import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ACTIVITY_LOGS_DATA_TYPES } from 'Consts/activityLogs';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getFullName as getUserFullName } from 'Utils/user';
import { getFormattedDate } from 'Utils/date';
import { getTypeNameLabel, getEventTypeLabel, getElementName } from 'Utils/activityLog';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';
import ModalActivityLogsDetails from 'Components/admin/modals/ActivityLogsDetails';
import ModalActivityLogsChanges from 'Components/admin/modals/ActivityLogsChanges';

export default class AdminActivityLogsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        activityLogs: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapFilters: PropTypes.func,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapFilters: value => value,
    };
    
    state = {
        isModalActivityLogsDetailsVisible: false,
        activityLogsDetailsModalData: null,
        isModalActivityLogsChangesVisible: false,
        activityLogsChangesModalData: null,
    }

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        dataType: undefined,
        ...this.props.predefinedQuery, //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            {
                ...queryObject,
                ...predefinedQuery,
            },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const queryObject = this.getQueryConfig();

        if (queryObject.dataType) {
            this.loadData();
        }
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;

        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (queryObject.dataType) {
            if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
                this.loadData();
            }

            if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(predefinedQuery)) {
                this.loadData();
            }
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    showActivityLogsDetailsModal = element => {
        const { actions } = this.props;

        this.setState({ isModalActivityLogsDetailsVisible: true }, () => {
            actions.single({ id: element.id })
                .then(data => {
                    this.setState({ activityLogsDetailsModalData: data.payload });
                });
        });
    }

    showActivityLogsChangesModal = element => {
        const { actions } = this.props;

        this.setState({ isModalActivityLogsChangesVisible: true }, () => {
            actions.single({ id: element.id })
                .then(data => {
                    this.setState({ activityLogsChangesModalData: data.payload });
                });
        });
    }

    render() {
        const { activityLogs, location, history, onMapFilters } = this.props;
        const { isModalActivityLogsDetailsVisible, activityLogsDetailsModalData, isModalActivityLogsChangesVisible, activityLogsChangesModalData } = this.state;

        return (
            <StyledComponent
                className="admin-activity-logs-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={activityLogs}
                    title="Lista logów aktywności"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={`${getTypeNameLabel(element).label}: ${getElementName(element).label}`}
                            subtitle={element.dataId}
                            labels={[{
                                isVisible: true,
                                label: getEventTypeLabel(element).label,
                                state: getEventTypeLabel(element).state,
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Szczegóły',
                                visible: true,
                                onClick: () => this.showActivityLogsDetailsModal(element),
                            }]}
                            additionals={[{
                                name: 'Data zdarzenia',
                                value: element.createdAt && getFormattedDate(element.createdAt) || 'Brak',
                            }, {
                                name: 'Utworzone przez',
                                value: element.isCli
                                    ? 'System'
                                    : element.user && getUserFullName(element.user).label || 'Brak',
                            }]}
                        />
                    )}
                    filters={onMapFilters([{
                        name: 'dataType',
                        label: 'Typ',
                        type: 'select',
                        inputProps: {
                            options: [
                                ...ACTIVITY_LOGS_DATA_TYPES.map(option => ({ ...option, value: option.key })),
                            ],
                        },
                    }])}
                />
                <ModalActivityLogsDetails
                    location={location}
                    history={history}
                    isOpen={isModalActivityLogsDetailsVisible}
                    onClose={() => this.setState({ isModalActivityLogsDetailsVisible: false })}
                    onSuccess={() => this.setState({ isModalActivityLogsDetailsVisible: false })}
                    data={activityLogsDetailsModalData}
                />
                <ModalActivityLogsChanges
                    location={location}
                    history={history}
                    isOpen={isModalActivityLogsChangesVisible}
                    onClose={() => this.setState({ isModalActivityLogsChangesVisible: false })}
                    onSuccess={() => this.setState({ isModalActivityLogsChangesVisible: false })}
                    data={activityLogsChangesModalData}
                />
            </StyledComponent>
        );
    }
}
