import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 80vw;

    .panel-user-offers-close-editor {
        width: 100%;

        .editor-wrapper {
            .forms-wrapper {
                width: 100%;
            }

            .data-wrapper {
                display: none;
            }
        }
    }
`;
