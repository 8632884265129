import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/admin/events/actions';
import { list as listUsers } from 'Redux/modules/admin/users/actions';
import {
    list as listProducts,
} from 'Redux/modules/admin/products/actions';

export default connect(
    state => ({
        events: state.adminEvents.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            listUsers: dispatch(listUsers),
            listProducts: dispatch(listProducts),
        },
    })
)(Component);
