import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';

export default class PanelValuesList extends Component {
    static propTypes = {
        elements: PropTypes.arrayOf(
            PropTypes.shape({
                visible: PropTypes.bool,
                key: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                value: PropTypes.any,
                type: PropTypes.oneOf([
                    'string',
                    'number',
                    'date',
                ]),
            })
        ).isRequired,
    };
    static defaultProps = {
        groups: [],
    };

    getValue = (value, type) => {
        switch (type) {
            case 'date':
                return getFormattedDate(value) || 'Brak';
            default:
                return value;
        }
    }

    getElement = ({ key, label, value, to, type }) => (
        <div className="element" key={key}>
            {to
                ? (
                    <>
                        <Link className="element-label element-label-link" to={to}>
                            {label}
                        </Link>
                        <span className="element-value">
                            {this.getValue(value, type)}
                        </span>
                    </>
                )
                : (
                    <>
                        <span className="element-label">
                            {label}
                        </span>
                        <span className="element-value">
                            {this.getValue(value, type)}
                        </span>
                    </>
                )
            }
        </div>
    )

    render() {
        let { elements } = this.props;
        elements = elements.filter(element => element.visible !== false);

        return (
            <StyledComponent
                styles={require('./styles')}
                className="panel-values-list"
            >
                <div className="elements">
                    {elements.map(this.getElement)}
                </div>
            </StyledComponent>
        );
    }
}
