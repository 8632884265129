import {
    ACTIVITY_LOGS_DATA_TYPES,
    ACTIVITY_LOGS_EVENT_TYPES,
    ACTIVITY_LOGS_DATA_TYPE_USER,
    ACTIVITY_LOGS_DATA_TYPE_EVENT,
    ACTIVITY_LOGS_DATA_TYPE_OFFER,
    ACTIVITY_LOGS_DATA_TYPE_USER_OFFER,
    ACTIVITY_LOGS_DATA_TYPE_LOCATION,
    ACTIVITY_LOGS_DATA_TYPE_MEASUREMENT,
    ACTIVITY_LOGS_DATA_TYPE_MEDICAL_CARD,
    ACTIVITY_LOGS_DATA_TYPE_OFFER_PRODUCT,
    ACTIVITY_LOGS_DATA_TYPE_PAYMENT,
    ACTIVITY_LOGS_DATA_TYPE_PRODUCT_CATEGORY,
    ACTIVITY_LOGS_DATA_TYPE_PRODUCT_SPECIALIZATION,
    ACTIVITY_LOGS_DATA_TYPE_PRODUCT,
    ACTIVITY_LOGS_DATA_TYPE_PROMO_CODE,
    ACTIVITY_LOGS_DATA_TYPE_SPECIALIZATION,
    ACTIVITY_LOGS_DATA_TYPE_TRAINER_USER,
    ACTIVITY_LOGS_DATA_TYPE_TRAINING_TARGET,
    ACTIVITY_LOGS_DATA_TYPE_USER_CONSENT,
    ACTIVITY_LOGS_DATA_TYPE_USER_NOTES,
    ACTIVITY_LOGS_DATA_TYPE_USER_SOURCES,
    ACTIVITY_LOGS_DATA_TYPE_USER_OFFER_DIAGNOSTIC_REPORT,
    ACTIVITY_LOGS_DATA_TYPE_USER_PAYMENT_METHOD,
    ACTIVITY_LOGS_DATA_TYPE_USER_SPECIALIZATION,
    ACTIVITY_LOGS_DATA_TYPE_USER_SURVEY,
    ACTIVITY_LOGS_DATA_TYPE_USER_TRANSFER,
    ACTIVITY_LOGS_DATA_TYPE_USER_WORK_HOURS,
    ACTIVITY_LOGS_DATA_TYPE_AUTH_TOKEN,
    ACTIVITY_LOGS_DATA_TYPE_USER_PERMISSION,
} from 'Consts/activityLogs';

export const getTypeNameLabel = activityLog => {
    if(!activityLog) {
        return { found: false, label: 'Nieznana' };
    }

    const { typeName } = activityLog;
    const option = ACTIVITY_LOGS_DATA_TYPES.find(activityLogDataType => activityLogDataType.key === typeName);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label };
};

export const getEventTypeLabel = activityLog => {
    if(!activityLog) {
        return { found: false, label: 'Nieznana' };
    }

    const { eventType } = activityLog;
    const option = ACTIVITY_LOGS_EVENT_TYPES.find(activityLogEventType => activityLogEventType.key === eventType);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label, state: option.state };
};

export const getElementName = activityLog => {
    if(!activityLog) {
        return { found: false, label: 'Nieznana' };
    }

    const { newData, typeName } = activityLog;
    const newDataObject = JSON.parse(newData);
    
    switch (typeName) {
        case ACTIVITY_LOGS_DATA_TYPE_USER:
            return { found: true, label: `${newDataObject.name} ${newDataObject.surname}` };
        case ACTIVITY_LOGS_DATA_TYPE_EVENT:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_OFFER:
            return { found: true, label: `${newDataObject.name}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_OFFER: 
            return { found: true, label: `${newDataObject.name}` };
        case ACTIVITY_LOGS_DATA_TYPE_LOCATION: 
            return { found: true, label: `${newDataObject.name}` };
        case ACTIVITY_LOGS_DATA_TYPE_MEASUREMENT:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_MEDICAL_CARD:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_OFFER_PRODUCT:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_PAYMENT:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_PRODUCT_CATEGORY:
            return { found: true, label: `${newDataObject.name}` };
        case ACTIVITY_LOGS_DATA_TYPE_PRODUCT_SPECIALIZATION:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_PRODUCT:
            return { found: true, label: `${newDataObject.name}` };
        case ACTIVITY_LOGS_DATA_TYPE_PROMO_CODE:
            return { found: true, label: `${newDataObject.code}` };
        case ACTIVITY_LOGS_DATA_TYPE_SPECIALIZATION:
            return { found: true, label: `${newDataObject.name}` };
        case ACTIVITY_LOGS_DATA_TYPE_TRAINER_USER:
            return { found: true, label: `${newDataObject.id} ` };
        case ACTIVITY_LOGS_DATA_TYPE_TRAINING_TARGET:
            return { found: true, label: `${newDataObject.name}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_CONSENT:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_NOTES:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_SOURCES:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_OFFER_DIAGNOSTIC_REPORT:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_PAYMENT_METHOD:
            return { found: true, label: `${newDataObject.maskedCard}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_SPECIALIZATION:
            return { found: true, label: `${newDataObject.type}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_SURVEY:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_TRANSFER:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_WORK_HOURS:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_AUTH_TOKEN:
            return { found: true, label: `${newDataObject.id}` };
        case ACTIVITY_LOGS_DATA_TYPE_USER_PERMISSION:
            return { found: true, label: `${newDataObject.id}` };
    }
    return { found: false, label: 'Nieznana' };
};