import { RESERVATIONS_LOCATIONS_SLUGS } from 'Consts/locations';

export const getName = location => {
    if(!location) {
        return { found: false, label: 'Nieznana' };
    }

    const { name, city, address, type } = location;
    return { found: true, label: `${name}, ${address} ${city} (${type})` };
};

export const getLocationByReservationSlug = reservationSlug => {
    if (!reservationSlug) {
        return {}
    }

    const slug = RESERVATIONS_LOCATIONS_SLUGS.find(locations => locations.reservationSlug === reservationSlug);
    if (!slug) {
        return { found: false, slug: undefined };
    }

    return { ...slug, found: true };
}