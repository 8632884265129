export const LOCATION_TYPE_GYM = 'gym';
export const LOCATION_TYPE_ONLINE = 'online';
export const LOCATION_TYPE_OUTSIDE = 'outside';
export const LOCATION_TYPE_HOME = 'home';
export const LOCATION_TYPE_OFFICE = 'office';
export const LOCATION_TYPE_DOCTOR_OFFICE = 'doctorOffice';
export const LOCATION_TYPE_MASSAGE_OFFICE = 'massageOffice';

export const SOBIESKI_MASSAGE_LOCATION_SLUG = 'gabinet-masazu-radisson-sobieski';
export const RC_MASSAGE_LOCATION_SLUG = 'radisson-collection-grzybowska';
export const RC_MASSAGE_NOW_LOCATION_SLUG = 'radisson-collection-grzybowska-now';

export const SOBIESKI_MASSAGE_RESERVATION_SLUG = 'sobieski-masaze';
export const RC_MASSAGE_RESERVATION_SLUG = 'rc-masaze';
export const RC_MASSAGE_NOW_RESERVATION_SLUG = 'rc-masaze-now';

export const LOCATION_TYPES = [
    {
        key: LOCATION_TYPE_GYM,
        label: 'Siłownia',
    },{
        key: LOCATION_TYPE_ONLINE,
        label: 'Online',
    },{
        key: LOCATION_TYPE_OUTSIDE,
        label: 'Plener',
    },{
        key: LOCATION_TYPE_HOME,
        label: 'Dom klienta',
    },{
        key: LOCATION_TYPE_OFFICE,
        label: 'Biuro',
    },{
        key: LOCATION_TYPE_DOCTOR_OFFICE,
        label: 'Gabinet lekarski',
    },{
        key: LOCATION_TYPE_MASSAGE_OFFICE,
        label: 'Gabinet masażu',
    }
];

export const RESERVATIONS_LOCATIONS_SLUGS = [
    {
        locationSlug: SOBIESKI_MASSAGE_LOCATION_SLUG,
        reservationSlug: SOBIESKI_MASSAGE_RESERVATION_SLUG,
    },
    {
        locationSlug: RC_MASSAGE_LOCATION_SLUG,
        reservationSlug: RC_MASSAGE_RESERVATION_SLUG,
    },
    {
        locationSlug: RC_MASSAGE_NOW_LOCATION_SLUG,
        reservationSlug: RC_MASSAGE_NOW_RESERVATION_SLUG,
    }
]

export const LOCATION_ID_ONLINE = process.env.APP_ENV == 'development' || process.env.APP_ENV == 'staging' ? '0fe0b5f9-b09e-4681-a2fe-6ef387fec832' : '316c9794-a67e-4e58-9c46-96fd3c13532c';