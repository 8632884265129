import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 60vw;

    .admin-element-editor {
        width: 100%;

        .editor-wrapper {
            justify-content: center !important;

            .data-wrapper {
                display: none;
            }
    
            .forms-wrapper {
                padding-right: 0;

                .block.form-wrapper {
                    border: none;
                    padding-top: 0;
                    margin-top: 0;
                }
            }
        }
    }
`;
