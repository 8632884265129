import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USER_OFFERS_MANAGE } from 'Consts/routes';
import { USER_OFFER_STATUSES, USER_OFFER_USERS_TYPE_MULTI } from 'Consts/userOffers';
import { OFFER_PERIOD_TYPE_SINGLE, OFFER_PERIOD_TYPE_RECURRING, OFFER_TYPES } from 'Consts/offers';
import { API_RESOURCE_PRODUCT_CATEGORIES } from 'Consts/apiResources';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getStatusLabel, getPeriodTypeLabel } from 'Utils/userOffer';
import { getFullName as getUserFullName } from 'Utils/user';
import { getName as getPromoCodeName } from 'Utils/promoCode';
import { getFormattedDate } from 'Utils/date';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelUserOffersList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            listCategories: PropTypes.func.isRequired,
        }).isRequired,
        userOffers: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapLabels: PropTypes.func,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapLabels: (elem, results) => results,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        status: '',
        hasPaidTo: '',
        hasActiveTo: '',
        hasEvents: '',
        overduePayments: '',
        offerType: '',
        periodType: '',
        promoCodeId: undefined,
        timestamp: undefined,
        productCategory: undefined,
        completedTransactions: undefined,
        ...this.props.predefinedQuery,  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { userOffers, location, history, actions, onMapLabels } = this.props;
        const queryObject = this.getQueryConfig();

        return (
            <StyledComponent
                className="panel-user-offers-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userOffers}
                    title="Lista zamówień"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.name}
                            subtitle={element.user && getUserFullName(element.user).label || 'Brak'}
                            labels={onMapLabels(element, [{
                                isVisible: Boolean(!element.isOwner && queryObject.userId),
                                label: 'Zaproszony',
                                state: 'success',
                            }])}
                            additionals={[{
                                name: 'Data utworzenia',
                                value: element.createdAt && getFormattedDate(element.createdAt) || 'Brak',
                            },{
                                name: 'Opłacone do',
                                value: element.paidTo && getFormattedDate(element.paidTo, 'date') || 'Brak',
                            }, {
                                name: 'Aktywne do',
                                value: element.activeTo && getFormattedDate(element.activeTo, 'date') || 'Brak',
                            }, {
                                name: 'Obłsugiwane do',
                                value: element.terminatedTo && getFormattedDate(element.terminatedTo, 'date') || 'Brak',
                            }, {
                                name: 'Kod promocyjny',
                                value: element.promoCode && getPromoCodeName(element.promoCode).label || 'Brak',
                            }, {
                                name: 'Status',
                                value: element.status && getStatusLabel(element).label || 'Brak',
                            }, {
                                name: 'Cena',
                                value: element.totalPriceGross ? formatPrice(element.totalPriceGross) : 'Brak', 
                            }, {
                                name: 'Typ użytkowników',
                                value: element.usersType === USER_OFFER_USERS_TYPE_MULTI
                                    ? 'Multi'
                                    : 'Pojedyncze'
                            }, {
                                name: 'Ilość użytkowników',
                                value: element.usersLimit,
                                visible: Boolean(element.usersType === USER_OFFER_USERS_TYPE_MULTI),
                            }, {
                                name: 'Cykliczność',
                                value: element.periodType && getPeriodTypeLabel(element).label || 'Brak',
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_USER_OFFERS_MANAGE.path, { id: element.id }),
                            }]}
                        />
                    )}
                    filters={[
                        {
                            name: 'search',
                            label: 'Szukaj',
                            type: 'text',
                        }, {
                            name: 'status',
                            label: 'Status',
                            type: 'select',
                            inputProps: {
                                options: [
                                    {
                                        label: 'Wszystkie',
                                        value: '',
                                    }, 
                                    ...USER_OFFER_STATUSES.map(option => ({ ...option, value: option.key })),
                                ],
                            },
                        }, {
                            name: 'hasPaidTo',
                            label: 'Ma datę następnej płatności',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Wszystkie',
                                    value: '',
                                }, {
                                    label: 'Tak',
                                    value: 'true',
                                }, {
                                    label: 'Nie',
                                    value: 'false',
                                }],
                            },
                        }, {
                            name: 'overduePayments',
                            label: 'Ma przeterminowana płatność',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Wszystkie',
                                    value: '',
                                }, {
                                    label: 'Tak',
                                    value: 'true',
                                }, {
                                    label: 'Nie',
                                    value: 'false',
                                }],
                            },
                        }, {
                            name: 'hasActiveTo',
                            label: 'Ma wypowiedzenie',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Wszystkie',
                                    value: '',
                                }, {
                                    label: 'Tak',
                                    value: 'true',
                                }, {
                                    label: 'Nie',
                                    value: 'false',
                                }],
                            },
                        }, {
                            name: 'hasEvents',
                            label: 'Posiada wydarzenia',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Wszystkie',
                                    value: '',
                                }, {
                                    label: 'Tak',
                                    value: 'true',
                                }, {
                                    label: 'Nie',
                                    value: 'false',
                                }],
                            },
                        }, {
                            name: 'periodType',
                            label: 'Typ okresu',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Wszystkie',
                                    value: '',
                                }, {
                                    label: 'Cykliczne',
                                    value: OFFER_PERIOD_TYPE_RECURRING,
                                }, {
                                    label: 'Pojedyńcze',
                                    value: OFFER_PERIOD_TYPE_SINGLE,
                                }],
                            },
                        }, {
                            name: 'offerType',
                            label: 'Typ ofery',
                            type: 'select',
                            inputProps: {
                                options: [
                                    { label: 'Wszystkie', value: '' },
                                    ...OFFER_TYPES.map(option => ({ label: option.label, value: option.key })),
                                ],
                            },
                        }, {
                            name: 'productCategory',
                            label: 'Kategoria',
                            type: 'select',
                            inputProps: {
                                dynamic: true,
                                onLoad: query => actions.listCategories({ search: query, perPage: 999 }),
                                onMapResponse: response => response.payload[API_RESOURCE_PRODUCT_CATEGORIES].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: element.name,
                                }),
                            },
                        }, {
                            name: 'completedTransactions',
                            label: 'Liczba dokonanych płatności',
                            type: 'text',
                            inputProps: {
                                type: 'number',
                            },
                        },
                    ]}
                />
            </StyledComponent>
        );
    }
}
