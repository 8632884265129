import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ADMIN_EVENTS_MANAGE } from 'Consts/routes';
import { 
    EVENT_PAID_STATUSES, 
    EVENT_DONE_STATUSES, 
    EVENT_CATEGORY_DEFAULT, 
    EVENT_CATEGORY_PRIVATE, 
    EVENT_DONE_STATUS_DONE,
    EVENT_PAID_STATUS_PAID,
} from 'Consts/events';
import { USER_OFFER_TYPE_DIAGNOSTIC } from 'Consts/users';
import { DATE_UNICODE_FORMAT } from 'Consts/date';
import { API_RESOURCE_PRODUCTS } from 'Consts/apiResources';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getPaidStatusLabel, getDoneStatusLabel, getCategoryLabel, getCancelReasonLabel } from 'Utils/event';
import { getFullName as getUserFullName } from 'Utils/user';
import { getName as getLocationName } from 'Utils/location';
import { getFormattedDate } from 'Utils/date';
import { getDurationTypeLabel } from 'Utils/product';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';
import DatePicker from 'Components/forms/DatePicker';

export default class PanelEventsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            listProducts: PropTypes.func.isRequired,
        }).isRequired,
        events: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onMapFilters: PropTypes.func,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapFilters: elems => elems,
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: 'endAt',
        timestamp: undefined,
        paidStatus: undefined,
        doneStatus: undefined,
        planned: undefined,
        startAtFrom:  undefined,
        startAtTo:  undefined,
        category: undefined,
        productId: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { events, location, history, onMapFilters, actions } = this.props;
        const queryObject = this.getQueryConfig();

        return (
            <StyledComponent
                className="panel-events-list"
                styles={require('./styles')}
            >
                <div className="date-select">
                    <span className="time-section time-section-picker">
                        <DatePicker
                            name="startAtFrom"
                            label="Data od"
                            onChange={({ value }) => history.push(
                                withVariables(
                                    location.pathname,
                                    {},
                                    { ...parseQueryToObject(location.search), startAtFrom: value?.toDate() || undefined }
                                )
                            )}
                            value={queryObject.startAtFrom && moment(queryObject.startAtFrom).toDate() || null}
                            dateTimePickerProps={{
                                maxDate: queryObject.startAtFrom && moment(queryObject.startAtFrom).toDate() || undefined,
                                showTimeSelect: false,
                                dateFormat: DATE_UNICODE_FORMAT,
                            }}
                        />
                    </span>
                    <span className="time-section time-section-picker">
                        <DatePicker
                            name="startAtTo"
                            label="Data do"
                            onChange={({ value }) => history.push(
                                withVariables(
                                    location.pathname,
                                    {},
                                    { ...parseQueryToObject(location.search), startAtTo: value?.toDate() || undefined }
                                )
                            )}
                            value={queryObject.startAtTo && moment(queryObject.startAtTo).toDate() || null}
                            dateTimePickerProps={{
                                minDate: queryObject.startAtTo && moment(queryObject.startAtTo).toDate() || undefined,
                                showTimeSelect: false,
                                dateFormat: DATE_UNICODE_FORMAT,
                            }}
                        />
                    </span>
                </div>
                <PaginatedList
                    location={location}
                    history={history}
                    collection={events}
                    title="Lista wydarzeń"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => {
                        const durationLabel = element?.product?.durationValue
                            ? `, Czas trwania: ${element.product.durationValue} ` + getDurationTypeLabel(element.product).label
                            : '';
                        const durationDisplayLabel = element?.product?.displayDurationValue
                            ? `, Czas trwania: ${element.product.displayDurationValue} ` + getDurationTypeLabel(element.product).label
                            : '';
                        const cancelReason = getCancelReasonLabel(element);
                            
                        return (
                            <PaginatedListElement
                                key={element.id}
                                title={element.category === EVENT_CATEGORY_DEFAULT
                                    ? element.product && element.product.name
                                    : getCategoryLabel(element).label
                                }
                                subtitle={'Data dla trenera: ' + `${element.startAt ? getFormattedDate(element.startAt) : 'Brak daty rozpoczęcia'}${durationLabel}`}
                                description={'Data dla klienta: ' + `${element.startAt ? getFormattedDate(element.startAt) : 'Brak daty rozpoczęcia'}${durationDisplayLabel}`}
                                labels={[{
                                    isVisible: Boolean(!element.startAt && !element.endAt),
                                    label: 'Wolny',
                                    state: 'success',
                                }, {
                                    isVisible: Boolean(element.startAt || element.endAt) && getDoneStatusLabel(element).key !== EVENT_DONE_STATUS_DONE ,
                                    label: 'Zaplanowany',
                                    state: 'warning',
                                }, {
                                    isVisible: element.category === EVENT_CATEGORY_DEFAULT,
                                    label: getPaidStatusLabel(element).label,
                                    state: getPaidStatusLabel(element).stateColor,
                                }, {
                                    isVisible: element.category === EVENT_CATEGORY_DEFAULT,
                                    label: getDoneStatusLabel(element).label,
                                    state: getDoneStatusLabel(element).stateColor,
                                }, {
                                    isVisible: element.category === EVENT_CATEGORY_PRIVATE,
                                    label: getCategoryLabel(element).label,
                                    state: getCategoryLabel(element).stateColor,
                                }, {
                                    isVisible: Boolean(element.order),
                                    label: `Trening: ${element.order || '-'}/${element.totalCount}`, 
                                }, {
                                    isVisible: Boolean(element.isBonus),
                                    label: 'Bonusowy',
                                    state: 'warning',
                                }, {
                                    isVisible: Boolean(cancelReason?.found && element.offer?.type === USER_OFFER_TYPE_DIAGNOSTIC),
                                    label: cancelReason?.label || 'Brak',
                                    state: 'warning',
                                }]}
                                additionals={[{
                                    visible: element.category === EVENT_CATEGORY_DEFAULT,
                                    name: 'Produkt',
                                    value: element.product && element.product.name || 'Brak',
                                }, {
                                    visible: element.category === EVENT_CATEGORY_DEFAULT,
                                    name: 'Prowadzący',
                                    value: element.lead && getUserFullName(element.lead).label || 'Brak',
                                }, {
                                    visible: element.category === EVENT_CATEGORY_DEFAULT,
                                    name: 'Klient',
                                    value: element.user && getUserFullName(element.user).label || 'Brak',
                                }, {
                                    name: 'Kategoria',
                                    value: element.category && getCategoryLabel(element).label || 'Brak',
                                }, {
                                    visible: element.category === EVENT_CATEGORY_DEFAULT,
                                    name: 'Lokalizacja',
                                    value: element.location && getLocationName(element.location).label || 'Brak',
                                }]}
                                controls={[{
                                    type: 'button',
                                    label: 'Zarządzaj',
                                    visible: true,
                                    to: withVariables(ADMIN_EVENTS_MANAGE.path, { id: element.id }),
                                }]}
                            />
                        );
                    }}
                    filters={onMapFilters([{
                        name: 'search',
                        label: 'Szukaj',
                        type: 'text',
                    }, {
                        name: 'planned',
                        label: 'Status zaplanowania',
                        type: 'select',
                        inputProps: {
                            options: [
                                {
                                    label: 'Wszystkie',
                                    value: '',
                                },  {
                                    label: 'Zaplanowane',
                                    value: 'true',
                                }, {
                                    label: 'Niezaplanowane',
                                    value: 'false',
                                },
                            ],
                        },
                    }, {
                        name: 'paidStatus',
                        label: 'Status płatności',
                        type: 'select',
                        inputProps: {
                            options: [
                                {
                                    label: 'Wszystkie',
                                    value: '',
                                }, 
                                ...EVENT_PAID_STATUSES.map(option => ({ ...option, value: option.key })),
                            ],
                        },
                    }, {
                        name: 'doneStatus',
                        label: 'Status wykonania',
                        type: 'select',
                        inputProps: {
                            options: [
                                {
                                    label: 'Wszystkie',
                                    value: '',
                                }, 
                                ...EVENT_DONE_STATUSES.map(option => ({ ...option, value: option.key })),
                            ],
                        },
                    }, {
                        name: 'productId',
                        label: 'Produkt',
                        type: 'select',
                        inputProps: {
                            dynamic: true,
                            onLoad: query => actions.listProducts({
                                search: query,
                                enabled: true,
                                userOfferId: queryObject.userOfferId,
                            }),
                            onMapResponse: response => response.payload[API_RESOURCE_PRODUCTS].elements,
                            onMapOption: element => ({
                                value: element.id,
                                label: element.name,
                            }),
                        },
                    }])}
                />
            </StyledComponent>
        );
    }
}
