import { connect } from 'react-redux';
import Component from './component';

import {
    addPayment,
} from 'Redux/modules/admin/userOffers/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            addPayment: dispatch(addPayment),
        },
    }),
)(Component);