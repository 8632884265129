import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    notificationActivate,
    removeUser,
    presignFile,
    createTVAccessCode,
} from 'Redux/modules/admin/users/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            notificationActivate: dispatch(notificationActivate),
            removeUser: dispatch(removeUser),
            presignFile: dispatch(presignFile),
            createTVAccessCode: dispatch(createTVAccessCode)
        },
    }),
)(Component);