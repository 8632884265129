
import {
    PRODUCT_DURATION_TYPES,
    PRODUCT_DURATION_TYPE_HOURS,
    PRODUCT_DURATION_TYPE_DAYS,
    PRODUCT_VISIBILITIES,
} from 'Consts/products';

export const getName = product => {
    if(!product) {
        return { found: false, label: 'Nieznany' };
    }

    const { name } = product;
    if (!name) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: name };
};

export const getDurationTypeLabel = product => {
    if(!product) {
        return { found: false, label: 'Nieznany' };
    }

    const { durationType } = product;
    const option = PRODUCT_DURATION_TYPES.find(productDurationType => productDurationType.key === durationType);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label };
};

export const getVisibilityLabel = product => {
    if(!product) {
        return { found: false, label: 'Nieznany' };
    }

    const { visibility } = product;
    const option = PRODUCT_VISIBILITIES.find(productVisibility => productVisibility.key === visibility);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label };
};

export const getDurationMinutes = (durationType, durationValue) => {
    switch (durationType) {
        case PRODUCT_DURATION_TYPE_HOURS:
            return durationValue * 60;    
        case PRODUCT_DURATION_TYPE_DAYS:
            return durationValue * 24 * 60;    
    }

    return durationValue;
};