export const FINANCES_VAT_PERCENT_ZW = 'ZW';
export const FINANCES_VAT_PERCENT_0 = '0';
export const FINANCES_VAT_PERCENT_3 = '3';
export const FINANCES_VAT_PERCENT_7 = '7';
export const FINANCES_VAT_PERCENT_23 = '23';
export const FINANCES_VAT_PERCENTS = [
    {
        key: FINANCES_VAT_PERCENT_ZW,
        label: 'ZW',
    },
    {
        key: FINANCES_VAT_PERCENT_0,
        label: '0',
    },
    {
        key: FINANCES_VAT_PERCENT_3,
        label: '3',
    },
    {
        key: FINANCES_VAT_PERCENT_7,
        label: '7',
    },
    {
        key: FINANCES_VAT_PERCENT_23,
        label: '23',
    },
];
