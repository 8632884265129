import { css } from '@emotion/core';

export default (props, state) =>
    css`
        .admin-element-editor {
            .editor-wrapper {
                display: contents !important;

                .forms-wrapper{
                    width: auto !important;
                    padding: 0 !important;

                    .form-wrapper {
                        padding: 0;
                        border: 0;

                        .admin-form-generator {
                            .form-elements {
                                padding: 1em 0;
                            }
                        }
                    }
                }
            }
        }
    `;
