import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { API_RESOURCE_PAYMENT_METHODS } from 'Consts/apiResources';

import StyledComponent from 'Components/core/StyledComponent';

export default class AdeptUserOfferPaymentMethods extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listUserPaymentMethods: PropTypes.func.isRequired,
        }).isRequired,
        userPaymentMethods: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }),
        changePaymentMethod: PropTypes.func,
    };

    static defaultProps = {
        data: null,
    };

    state = {
        isLoading: false,
        paymentMethod: null,
    };

    componentDidMount = () => {
        const { actions, changePaymentMethod } = this.props;
        actions.listUserPaymentMethods()
            .then(response => {
                const result = response.payload[API_RESOURCE_PAYMENT_METHODS].elements
                    .filter(paymentMethod => paymentMethod.default == true);

                this.setState({
                    paymentMethod: result[0],
                });

                changePaymentMethod(result[0]);
            });
    }

    render() {
        const { userPaymentMethods, changePaymentMethod } = this.props;
        const { paymentMethod } = this.state;

        if (userPaymentMethods.elements.length < 1) {
            return (
                <a>
                    Nie posiadasz żadnej karty w metodach płatności.
                </a>
            );
        }

        return (
            <StyledComponent
                className="adept-user-offers-payment-methods"
                styles={require('./styles')}
            >
                <div className="payment-methods">
                    {userPaymentMethods.isLoaded && (
                        <>
                            {Boolean(userPaymentMethods.elements.length > 0) && (
                                <div className="user-payment-methods">
                                    <h4 className="user-payment-methods-headline">
                                        Dostepne metody płatności
                                    </h4>
                                    <div className="user-payment-methods-list">
                                        {userPaymentMethods.elements.map((userPaymentMethod, index) => (
                                            <div
                                                key={userPaymentMethod.id}
                                                className={classNames({
                                                    'user-payment-method': true,
                                                    selected: paymentMethod
                                                        ? userPaymentMethod.id === paymentMethod.id
                                                        : index === 0,
                                                })}
                                                onClick={() => {
                                                    changePaymentMethod(userPaymentMethod);
                                                    this.setState({ paymentMethod: userPaymentMethod });
                                                }}
                                            >
                                                {userPaymentMethod.maskedCard}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </StyledComponent>
        );
    }
} 