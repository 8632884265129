export const FREQUENCY_TYPE_EVERY_DAY = 'everyDay';
export const FREQUENCY_TYPE_EVERY_MONDAY = 'everyMonday';
export const FREQUENCY_TYPE_EVERY_TUESDAY = 'everyTuesday';
export const FREQUENCY_TYPE_EVERY_WEDNESDAY = 'everyWednesday';
export const FREQUENCY_TYPE_EVERY_THURSDAY = 'everyThursday';
export const FREQUENCY_TYPE_EVERY_FRIDAY = 'everyFriday';
export const FREQUENCY_TYPE_EVERY_SATURDAY = 'everySaturday';
export const FREQUENCY_TYPE_EVERY_SUNDAY = 'everySunday';
export const FREQUENCY_TYPE_EVERY_WEEK = 'everyWeek';
export const FREQUENCY_TYPE_EVERY_2_WEEKS = 'every2Weeks';
export const FREQUENCY_TYPE_EVERY_3_WEEKS = 'every3Weeks';
export const FREQUENCY_TYPE_EVERY_MONTH = 'everyMonth';
export const FREQUENCT_TYPE_UNKNOWN = 'Nieznana';
export const FREQUENCY_TYPES = [{
    key: FREQUENCY_TYPE_EVERY_WEEK,
    label: 'Co tydzień',
}, {
    key: FREQUENCY_TYPE_EVERY_2_WEEKS,
    label: 'Co dwa tygodnie',
}];

export const END_OPTION_REMOVE_ALL = 'all';
export const END_OPTION_REMOVE_FUTURE = 'future';
export const END_OPTION_REMOVE_THIS = 'this';
export const END_OPTION_REMOVE_AFTER_THIS = 'afterThis';
export const END_OPTIONS = [{
    key: END_OPTION_REMOVE_ALL,
    label: 'Usuń wszystkie',
}, {
    key: END_OPTION_REMOVE_FUTURE,
    label: 'Usuń wszystkie w przyszłości',
}, {
    key: END_OPTION_REMOVE_THIS,
    label: 'Usuń to wydarzenie',
}, {
    key: END_OPTION_REMOVE_AFTER_THIS,
    label: 'Usuń wszystkie następujące po tym',
}];