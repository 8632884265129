import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`

    overflow: auto;

    .cols {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    
        .col {
            padding: 1em;
            background: white;
            width: 48%;
        }
    }
`;
