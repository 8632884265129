import {
    PAYMENT_TRANSACTION_STATUSES,
    PAYMENT_TRANSACTION_TYPES,
} from 'Consts/paymentTransactions';

export const getStatusLabel = paymentTransaction => {
    if(!paymentTransaction) {
        return { found: false, label: 'Nieznany' };
    }

    const { status } = paymentTransaction;
    const option = PAYMENT_TRANSACTION_STATUSES.find(paymentTransactionStatus => paymentTransactionStatus.key === status);
    if (!option) {
        return { found: false, label: 'Nieznana', stateColor: 'alert' };
    }

    return { found: true, label: option.label, stateColor: option.stateColor };
};

export const getTypeLabel = paymentTransaction => {
    if(!paymentTransaction) {
        return { found: false, label: 'Nieznany' };
    }

    const { type } = paymentTransaction;
    const option = PAYMENT_TRANSACTION_TYPES.find(paymentTransactionType => paymentTransactionType.key === type);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label };
};

