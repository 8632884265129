import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFullName as getUserFullName } from 'Utils/user';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';
import LogViewModal from 'Components/admin/modals/LogView';

export default class PanelUserOfferChangeLogs extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            logs: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        userOfferLogs: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {
        showLogsModal: false,
        currentChangeLog: null,
    };

    componentDidMount = () => {
        const { actions, predefinedQuery } = this.props;

        actions.logs({ userOfferId: predefinedQuery.userOfferId });
    }

    hasStatusChanged = (data) => {
        return data.statusNew !== data.statusOld;
    };

    render() {
        const { currentChangeLog, showLogsModal } = this.state;
        const { location, history, userOfferLogs } = this.props;

        if (!userOfferLogs) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-user-offer-changes-logs"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userOfferLogs}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={getFormattedDate(element.createdAt)}
                            subtitle={`Autor zmiany - ${getUserFullName(element.creator).label}`}
                            additionals={[{
                                visible: this.hasStatusChanged(element),
                                name: 'Nowy status',
                                value: element.statusNew,
                            }, {
                                visible: this.hasStatusChanged(element),
                                name: 'Stary status',
                                value: element.statusOld,
                            }]}
                            labels={[{
                                isVisible: this.hasStatusChanged(element),
                                label: 'Zmiana statusu',
                                state: 'success',
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Szczegóły',
                                visible: true,
                                onClick: () => this.setState({ showLogsModal: true, currentChangeLog: element }),
                            }]}
                        />
                    )}
                />
                <LogViewModal 
                    isOpen={showLogsModal}
                    onClose={() => this.setState({ showLogsModal: false })}
                    data={{
                        src: currentChangeLog,
                        collapsed: true,
                    }}
                />
            </StyledComponent>
        );
    }
}