import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { BIG_CALENDAR_TRAINER_DEFAULT_VIEW, BIG_CALENDAR_DEFAULT_TIMESLOTS } from 'Consts/bigCalendar';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getFullName } from 'Utils/user';
import { getCategoryLabel, getCalendarEventStatus } from 'Utils/event';
import { workHourToTimeSlot } from 'Utils/bigCalendar';

import StyledComponent from 'Components/core/StyledComponent';
import BigCalendar from 'Components/layoutAdmin/panel/BigCalendar';
import CalendarLegend from 'Components/admin/events/CalendarLegend';
import ModalEventEditor from 'Components/admin/events/ModalEditor';
import Spinner from 'Components/layoutAdmin/Spinner';

const CustomEvent = (event) => { 
    return ( 
        <div className={`custom-event ${event.event.stateColor}`}> 
            <strong> {event.title} </strong>
        </div> 
    );
};

export default class PanelEventsCalendar extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            listWorkHours: PropTypes.func.isRequired,
        }).isRequired,
        events: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        userWorkHoursList: PropTypes.object.isRequired,
        userWorkHours: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        perPage: 9999,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    state = {
        selectedEvent: null,
        selectedSlot: null,
        calendarDate: undefined,
        calendarView: BIG_CALENDAR_TRAINER_DEFAULT_VIEW,
    };

    calendarRef = React.createRef();

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.setState({
            calendarDate: moment().startOf('week')
                .toDate(),
            calendarView: BIG_CALENDAR_TRAINER_DEFAULT_VIEW,
        });
        
        this.loadData();
        setTimeout(this.renderWorkHoursOverlay, 0);
    }

    componentDidUpdate = (prevProps, prevState) => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }

        if (prevState.calendarDate !== this.state.calendarDate || prevState.calendarView !== this.state.calendarView) { //eslint-disable-line react/destructuring-assignment
            this.loadData();
        } 
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();
        const { calendarDate, calendarView } = this.state;

        const eventsQuery = this.getEvents(calendarView, calendarDate);
        
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ 
                ...queryObject,
                ...eventsQuery,
            });
            actions.listWorkHours({
                userId: queryObject.leadId,
            });
        }, 500);
    }

    getTitle = event => {
        const { predefinedQuery } = this.props;

        if(predefinedQuery.leadId) {
            return event.product 
                ? `${event.product && event.product.name} ${(event.user && '- ' + getFullName(event.user).label + (Boolean(event.order) && `(Trening: ${event.order}/${event.totalCount})`)) || ''}`
                : `${getCategoryLabel(event).label} ${(event.user && '- ' + getFullName(event.user).label + (Boolean(event.order) && `(Trening: ${event.order}/${event.totalCount})`)) || ''}`;
        }

        return event.product 
            ? `${event.product && event.product.name} ${(event.lead && '- ' + getFullName(event.lead).label + (Boolean(event.order) && `(Trening: ${event.order}/${event.totalCount})`)) || ''}`
            : `${getCategoryLabel(event).label} ${(event.lead && '- ' + getFullName(event.lead).label + (Boolean(event.order) && `(Trening: ${event.order}/${event.totalCount})`)) || ''}`;
    }

    getEvents = (view, date) => {
        switch (view) {
            case 'day':
                return {
                    fromDate: moment(date).startOf('day')
                        .toDate(),
                    untilDate: moment(date).endOf('day')
                        .toDate(),
                };
            case 'week': 
                return {
                    fromDate: moment(date).startOf('week')
                        .toDate(),
                    untilDate: moment(date).endOf('week')
                        .toDate(),
                };
            case 'month':
                return {
                    fromDate: moment(date).startOf('month')
                        .subtract(1, 'week')
                        .toDate(),
                    untilDate: moment(moment(date).add(1, 'week')).endOf('month')
                        .add(1, 'week')
                        .toDate(),
                };
        }
    } 

    renderWorkHoursOverlay = (date) => {
        const { userWorkHours } = this.props;
        const { calendarView } = this.state;
        const { calendarRef } = this;
        
        let calendarDaySlots = document.querySelectorAll('.rbc-calendar > .rbc-time-view > .rbc-time-content > .rbc-day-slot');
        
        if (calendarRef && calendarRef.current && calendarRef.current.state && calendarRef.current.state.defaultView == 'week') {
            if (calendarDaySlots && calendarDaySlots.length > 0) {
                const calendarTimeViewHeight = calendarDaySlots[0].clientHeight;
                const calendarTimeSlotHeight = calendarTimeViewHeight / (24 * BIG_CALENDAR_DEFAULT_TIMESLOTS);
              
                if (document.querySelectorAll('.rbc-time-view > .rbc-time-content > .rbc-day-slot > .work-hour').length == 0) {
                    calendarDaySlots = Array.from(calendarDaySlots);
                    calendarDaySlots.map((daySlot, index) => {
                        const startWorkHourNode = document.createElement('div');
                        const endWorkHourNode = document.createElement('div');
                        const startMultiplier = workHourToTimeSlot(userWorkHours, 'start', index);
                        const endMultiplier = workHourToTimeSlot(userWorkHours, 'end', index);
            
                        startWorkHourNode.classList.add('work-hour', 'start');
                        startWorkHourNode.style.height = `${calendarTimeSlotHeight * startMultiplier}px`;
                        endWorkHourNode.classList.add('work-hour', 'end');
                        endWorkHourNode.style.height = `${calendarTimeSlotHeight * endMultiplier}px`;
                        
                        daySlot.prepend(startWorkHourNode, endWorkHourNode);
                    });
                }
            }
        }

        if (calendarRef && calendarRef.current && calendarRef.current.state && calendarRef.current.state.defaultView == 'day' || calendarView == 'day') {
            if (calendarDaySlots) {
                const calendarTimeViewHeight = calendarDaySlots[0].clientHeight;
                const calendarTimeSlotHeight = calendarTimeViewHeight / (24 * BIG_CALENDAR_DEFAULT_TIMESLOTS);
                calendarDaySlots = Array.from(calendarDaySlots);
                const startWorkHourNode = document.createElement('div');
                const endWorkHourNode = document.createElement('div');
                const startMultiplier = workHourToTimeSlot(userWorkHours, 'start', moment(date).day(), 'moment');
                const endMultiplier = workHourToTimeSlot(userWorkHours, 'end', moment(date).day(), 'moment');
    
                startWorkHourNode.classList.add('work-hour', 'start');
                startWorkHourNode.style.height = `${calendarTimeSlotHeight * startMultiplier}px`;
                endWorkHourNode.classList.add('work-hour', 'end');
                endWorkHourNode.style.height = `${calendarTimeSlotHeight * endMultiplier}px`;
                
                if (document.querySelector('.rbc-time-view > .rbc-time-content > .rbc-day-slot > .work-hour')) {
                    document.querySelector('.rbc-time-view > .rbc-time-content > .rbc-day-slot > .work-hour').remove();
                }

                calendarDaySlots[0].prepend(startWorkHourNode, endWorkHourNode);
            }
        }
    }
    
    renderEventsLoadingOverlay = () => {
        const { events } = this.props;
        const calendarTimeView = document.querySelector('.rbc-calendar > .rbc-time-view');
        let loadingOverlay = document.querySelector('.rbc-calendar > .rbc-time-view > .overlay');

        if (!document.querySelector('.rbc-calendar > .rbc-time-view > .overlay')) {
            loadingOverlay = document.createElement('div');
            loadingOverlay.classList.add('overlay');
            calendarTimeView && calendarTimeView.appendChild(loadingOverlay);
        }
       
        if (loadingOverlay) {
            if (events && Boolean(events.isLoading) && Boolean(!events.isLoaded)) {
                loadingOverlay.classList.add('visible');
            }
    
            if (events && Boolean(!events.isLoading) && Boolean(events.isLoaded)) {
                loadingOverlay.classList.remove('visible');
            }
        }
    }

    render() {
        const { location, history, events, predefinedQuery } = this.props;
        const { selectedEvent, selectedSlot } = this.state;
        this.renderEventsLoadingOverlay();

        return (
            <StyledComponent
                className="admin-events-calendar"
                styles={require('./styles')}
            >
                {events && events.isLoading && Boolean(!events.isLoaded) && (
                    <Spinner />
                )}
                <BigCalendar
                    ref={this.calendarRef}
                    calendarProps={{
                        selectable: true,
                        onView: view => {
                            this.setState({ calendarView: view }, () => {
                                setTimeout(this.renderWorkHoursOverlay, 0);
                            });
                        },
                        onNavigate: date => {
                            this.setState({ calendarDate: date });
                            this.renderWorkHoursOverlay(date);
                        },
                        onSelectSlot: slot => this.setState({ selectedSlot: slot }),
                        onSelectEvent: event => this.setState({ selectedEvent: event }),
                        events: events.elements.map(event => ({
                            title: this.getTitle(event),
                            start: moment(event.startAt).toDate(),
                            end: moment(event.endAt).toDate(),
                            resource: event,
                            stateColor: getCalendarEventStatus(event).stateColor,
                        })),
                        components: {
                            event: CustomEvent,
                        },
                    }}
                />
                <CalendarLegend />
                <ModalEventEditor
                    location={location}
                    history={history}
                    isOpen={Boolean(selectedEvent || selectedSlot)}
                    onClose={() => this.setState({ selectedEvent: null, selectedSlot: null })}
                    data={selectedEvent && selectedEvent.resource || 
                        selectedSlot && { 
                            ...selectedSlot,
                            startAt: selectedSlot.start,
                            endAt: selectedSlot.end,
                        } || null 
                    }
                    onRemove={() => {
                        this.setState({ selectedEvent: null, selectedSlot: null });
                        this.loadData();
                    }}
                    predefinedState={{
                        lead: predefinedQuery?.lead || null,
                        user: predefinedQuery?.user || null,
                        userOffer: predefinedQuery?.userOffer || null,
                        leadId: predefinedQuery.leadId || undefined,
                    }}
                />
            </StyledComponent>
        );
    }
}
