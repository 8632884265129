import { connect } from 'react-redux';
import Component from './component';

import { refund } from 'Redux/modules/admin/paymentTransactions/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            refund: dispatch(refund),
        },
    })
)(Component);
