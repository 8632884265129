import { BIG_CALENDAR_DEFAULT_TIMESLOTS } from 'Consts/bigCalendar';

import { parseWorkHours } from 'Utils/userWorkHours';

export const workHourToTimeSlot = (workHours, countFor, index, idType = 'element') => {
    const parsedWorkHours = parseWorkHours(workHours, true);

    let multiplier, multiplierDecimal = null;

    if (!parsedWorkHours) return null;

    if (idType == 'element') {
        switch (countFor) {
            case 'start': 
                multiplierDecimal = parsedWorkHours.find(workHour => workHour.elementId == index).startAtMinute/60;
                multiplier = (parsedWorkHours.find(workHour => workHour.elementId == index).startAtHour + multiplierDecimal) * BIG_CALENDAR_DEFAULT_TIMESLOTS;
                break;
            case 'end':
                multiplierDecimal = parsedWorkHours.find(workHour => workHour.elementId == index).endAtMinute/60;
                multiplier = (24 - parsedWorkHours.find(workHour => workHour.elementId == index).endAtHour - multiplierDecimal) * BIG_CALENDAR_DEFAULT_TIMESLOTS;
                break;
            default: 
                return null;
        }
    } 

    if (idType == 'moment') {
        switch (countFor) {
            case 'start': 
                multiplierDecimal = parsedWorkHours.find(workHour => workHour.momentId == index).startAtMinute/60;
                multiplier = (parsedWorkHours.find(workHour => workHour.momentId == index).startAtHour + multiplierDecimal) * BIG_CALENDAR_DEFAULT_TIMESLOTS;
                break;
            case 'end':
                multiplierDecimal = parsedWorkHours.find(workHour => workHour.momentId == index).endAtMinute/60;
                multiplier = (24 - parsedWorkHours.find(workHour => workHour.momentId == index).endAtHour - multiplierDecimal) * BIG_CALENDAR_DEFAULT_TIMESLOTS;
                break;
            default: 
                return null;
        }
    }

    return multiplier;
};
