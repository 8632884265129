import {
    PROMO_CODE_LIMIT_TYPES,
    PROMO_CODE_USE_LIMIT_TYPES,
    PROMO_CODE_VALUE_TYPES,
    PROMO_CODE_BY_LOCATION,
    PROMO_CODE_VALUE_TYPE_AMOUNT,
} from 'Consts/promoCodes';

export const getName = promoCode => {
    if(!promoCode) {
        return { found: false, label: 'Nieznany' };
    }

    const { code } = promoCode;
    if (!code) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: code };
};

export const getLimitTypeLabel = promoCode => {
    if(!promoCode) {
        return { found: false, label: 'Nieznany' };
    }

    const { limitType } = promoCode;
    const option = PROMO_CODE_LIMIT_TYPES.find(promoCodeLimitType => promoCodeLimitType.key === limitType);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label };
};

export const getUseLimitTypeLabel = promoCode => {
    if(!promoCode) {
        return { found: false, label: 'Nieznany' };
    }

    const { useLimitType } = promoCode;
    const option = PROMO_CODE_USE_LIMIT_TYPES.find(promoCodeUseLimitType => promoCodeUseLimitType.key === useLimitType);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label };
};

export const getValueTypeLabel = promoCode => {
    if(!promoCode) {
        return { found: false, label: 'Nieznany' };
    }

    const { valueType } = promoCode;
    const option = PROMO_CODE_VALUE_TYPES.find(promoCodeValueType => promoCodeValueType.key === valueType);
    if (!option) {
        return { found: false, label: 'Nieznany' };
    }

    return { found: true, label: option.label };
};

export const getActivePromoCodeInLocation = location => {
    if (!location) {
        return null;
    }

    const promoCode = PROMO_CODE_BY_LOCATION.find(promoCode => promoCode.slug == location.slug);
    return promoCode
        ? promoCode.value
        : null;
}; 

export const getPromoCodeValue = promoCode => {
    if (!promoCode) {
        return null;
    }

    const { code, value, valueType } = promoCode;

    const unit = valueType === PROMO_CODE_VALUE_TYPE_AMOUNT ? 'zł' : '%';
    const result = code + ' (' + value + ' ' + unit + ')';

    return result;
}