import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .elements {
        width: 100%;
        
        .element {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .5em 0;
            margin: .5em 0;
            font-size: 1.1em;
            border-bottom: 1px dashed #CCCCCC;

            .element-label {
                color: #666666;
            }
            .element-value {
                color: #333333;
            }
        }
    }
`;
