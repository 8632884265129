import { connect } from 'react-redux';
import Component from './component';

import {
    create,
    update,
    extend,
    payment,
    notificationShopLink,
} from 'Redux/modules/admin/userOffers/actions';
import { 
    list as listUsers, 
} from 'Redux/modules/admin/users/actions';
import { list as listSpecializations } from 'Redux/modules/admin/specializations/actions';

import { list as listOffers } from 'Redux/modules/admin/offers/actions';
import { list as listPromoCodes } from 'Redux/modules/admin/promoCodes/actions';
import { list as listInvoicingStrategies } from 'Redux/modules/admin/invoicingStrategies/actions';
import { list as listPaymentMethods } from 'Redux/modules/admin/paymentMethods/actions';
import { close as closeOrder } from 'Redux/modules/admin/orders/actions';
import { list as listLocations } from 'Redux/modules/admin/locations/actions';
import { list as listProducts } from 'Redux/modules/admin/products/actions';
export default connect(
    state => ({
        user: state.user.profile,
    }),
    dispatch => ({
        actions: {
            create: dispatch(create),
            update: dispatch(update),
            extend: dispatch(extend),
            payment: dispatch(payment),
            notificationShopLink: dispatch(notificationShopLink),
            listUsers: dispatch(listUsers),
            listOffers: dispatch(listOffers),
            listPromoCodes: dispatch(listPromoCodes),
            listInvoicingStrategies: dispatch(listInvoicingStrategies),
            listPaymentMethods: dispatch(listPaymentMethods),
            closeOrder: dispatch(closeOrder),
            listLocations: dispatch(listLocations),
            listProducts: dispatch(listProducts),
            listSpecializations: dispatch(listSpecializations),
        },
    }),
)(Component);