import moment from 'moment';

export const parseWorkHours = (workHours, toNumber = false) => {
    let parsedWorkHours = [];

    if (workHours) {
        switch (toNumber) {
            case true: 
                parsedWorkHours = [{
                    startAtHour: Number(workHours.mondayStart.split(':')[0]),
                    startAtMinute: Number(workHours.mondayStart.split(':')[1]),
                    endAtHour: Number(workHours.mondayEnd.split(':')[0]),
                    endAtMinute:  Number(workHours.mondayEnd.split(':')[1]),
                    momentId: 1, 
                    elementId: 0,
                }, {
                    startAtHour: Number(workHours.tuesdayStart.split(':')[0]),
                    startAtMinute: Number(workHours.tuesdayStart.split(':')[1]),
                    endAtHour: Number(workHours.tuesdayEnd.split(':')[0]),
                    endAtMinute: Number(workHours.tuesdayEnd.split(':')[1]),
                    momentId: 2, 
                    elementId: 1,
                }, {
                    startAtHour: Number(workHours.wednesdayStart.split(':')[0]),
                    startAtMinute: Number(workHours.wednesdayStart.split(':')[1]),
                    endAtHour: Number(workHours.wednesdayEnd.split(':')[0]),
                    endAtMinute: Number(workHours.wednesdayEnd.split(':')[1]),
                    momentId: 3, 
                    elementId: 2,
                }, {
                    startAtHour: Number(workHours.thursdayStart.split(':')[0]),
                    startAtMinute: Number(workHours.thursdayStart.split(':')[1]),
                    endAtHour: Number(workHours.thursdayEnd.split(':')[0]),
                    endAtMinute: Number(workHours.thursdayEnd.split(':')[1]),
                    momentId: 4, 
                    elementId: 3,
                }, {
                    startAtHour: Number(workHours.fridayStart.split(':')[0]),
                    startAtMinute: Number(workHours.fridayStart.split(':')[1]),
                    endAtHour: Number(workHours.fridayEnd.split(':')[0]),
                    endAtMinute: Number(workHours.fridayEnd.split(':')[1]),
                    momentId: 5, 
                    elementId: 4,
                }, {
                    startAtHour: Number(workHours.saturdayStart.split(':')[0]),
                    startAtMinute: Number(workHours.saturdayStart.split(':')[1]),
                    endAtHour: Number(workHours.saturdayEnd.split(':')[0]),
                    endAtMinute: Number(workHours.saturdayEnd.split(':')[1]),
                    momentId: 6, 
                    elementId: 5,
                }, {
                    startAtHour: Number(workHours.sundayStart.split(':')[0]),
                    startAtMinute: Number(workHours.sundayStart.split(':')[1]),
                    endAtHour: Number(workHours.sundayEnd.split(':')[0]),
                    endAtMinute: Number(workHours.sundayEnd.split(':')[1]),
                    momentId: 0,
                    elementId: 6, 
                }];
                break;
            case false:
                parsedWorkHours = [{
                    startAt: workHours.mondayStart,
                    endAt: workHours.mondayEnd,
                    momentId: 1, 
                }, {
                    startAt: workHours.tuesdayStart,
                    endAt: workHours.tuesdayEnd,
                    momentId: 2,
                }, {
                    startAt: workHours.wednesdayStart,
                    endAt: workHours.wednesdayEnd,
                    momentId: 3, 
                }, {
                    startAt: workHours.thursdayStart,
                    endAt: workHours.thursdayEnd,
                    momentId: 4, 
                }, {
                    startA: workHours.fridayStart,
                    endAt: workHours.fridayEnd,
                    momentId: 5, 
                }, {
                    startAt: workHours.saturdayStart,
                    endAt: workHours.saturdayEnd,
                    momentId: 6, 
                }, {
                    startAt: workHours.sundayStart,
                    endAt: workHours.sundayEnd,
                    momentId: 0, 
                }];
                break;
        }
    
        return parsedWorkHours;
    }
    
    return null;
};

export const isSlotOccupied = (slot, userWorkHours) => {
    if (slot && userWorkHours) {
        const { endAt, startAt } = slot;
        const parsedWorkHours = parseWorkHours(userWorkHours, true);
        const startAtToMomentDay = moment(moment(startAt).toDate()).day();    
        const foundDay = parsedWorkHours.find(workHour => workHour.momentId == startAtToMomentDay);    
    
        if (foundDay) {
            const formattedStartAt = moment(startAt).set('hour', foundDay.startAtHour)
                .set('minute', foundDay.startAtMinute)
                .toDate();
            const formattedEndAt = moment(endAt).set('hour', foundDay.endAtHour)
                .set('minute', foundDay.endAtMinute)
                .toDate();

            return moment(startAt).isBefore(formattedStartAt) || moment(endAt).isAfter(formattedEndAt);
        }
    }

    return null;
};