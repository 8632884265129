import React, { Component } from 'react';

import StyledComponent from 'Components/core/StyledComponent';

export default class AdminEventsCalendarLegend extends Component {
    static propTypes = {};
    static defaultProps = {};

    render() {
        return (
            <StyledComponent
                className="admin-events-calendar-legend"
                styles={require('./styles')}
            >
                <div className="tooltips-legend">
                    Legenda
                </div>
                <div className="tooltips-wrapper">
                    <div className="tooltip-block" title="Wykonany">
                        <div className="color-block done" />
                        <div className="label">
                            Wykonany
                        </div>
                    </div>
                    <div className="tooltip-block" title="Zaplanowany">
                        <div className="color-block planned" />
                        <div className="label">
                            Zaplanowany
                        </div>
                    </div>
                    <div className="tooltip-block" title="Niewykonany">
                        <div className="color-block notDone" />
                        <div className="label">
                            Niewykonany
                        </div>
                    </div>
                    <div className="tooltip-block" title="Prywatny">
                        <div className="color-block private" />
                        <div className="label">
                            Prywatny
                        </div>
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
