import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/admin/events/actions';

import {
    list as listWorkHours,
} from 'Redux/modules/admin/userWorkHours/actions';

export default connect(
    state => ({
        events: state.adminEvents.list,
        userWorkHoursList: state.adminUserWorkHours.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            listWorkHours: dispatch(listWorkHours),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        userWorkHours: stateProps.userWorkHoursList.elements.find(userWorkHour => userWorkHour.user && userWorkHour.user.id === ownProps?.predefinedQuery?.leadId),
    })
)(Component);
