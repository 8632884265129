import moment from 'moment';
import { 
    USER_OFFER_STATUS_ACTIVE,
    USER_OFFER_STATUSES,
    USER_OFFER_LABEL_VALUE_NOTICE,
    USER_OFFER_PERIOD_TYPES,
} from 'Consts/userOffers';

export const getStatusLabel = userOffer => {
    if(!userOffer) {
        return { found: false, value: null, label: 'Nieznana', stateColor: 'alert' };
    }

    const { status, activeTo } = userOffer;

    if(status === USER_OFFER_STATUS_ACTIVE) {   
        if(activeTo) {
            return { found: true, value: USER_OFFER_LABEL_VALUE_NOTICE, label: 'W trakcie wypowiedzenia', stateColor: 'warning' };
        }
    }

    const option = USER_OFFER_STATUSES.find(userOfferStatus => userOfferStatus.key === status);
    if (!option) {
        return { found: false, value: null, label: 'Nieznana', stateColor: 'alert' };
    }

    return { found: true, value: option.value, label: option.label, stateColor: option.stateColor };
};

export const getPeriodTypeLabel = userOffer => {
    if(!userOffer) {
        return { found: false, label: 'Nieznany' };
    }

    const { periodType } = userOffer;
    const option = USER_OFFER_PERIOD_TYPES.find(userOfferPeriodType => userOfferPeriodType.key === periodType);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label };
};