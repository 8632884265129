import { connect } from 'react-redux';
import Component from './component';

import {
    close,
} from 'Redux/modules/admin/userOffers/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            close: dispatch(close),
        },
    }),
)(Component);