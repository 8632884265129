import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'node_modules/react-toastify/index';

import { toApiFormat as dateToApiFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layoutAdmin/modals/ModalWrapper';
import ModalBody from 'Components/layoutAdmin/modals/ModalBody';
import ModalTitle from 'Components/layoutAdmin/modals/ModalTitle';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class AdminPaymentTransactionRefundModal extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        actions: PropTypes.shape({
            refund: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object.isRequired,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        data: {},
    };

    state = {
        formState: {},
    };

    formStateToApi = data => ({
        ...data,
        refundAt: dateToApiFormat(data.refundAt, 'datetime', true),
    })

    onSubmit = formState => {
        const { actions, onClose, data } = this.props;

        return actions.refund({ 
            ...this.formStateToApi(formState),
            ...data,
        })
            .finally(() => { onClose(); });
    }

    render() {
        const { location, history, isOpen, onClose } = this.props;
        const { formState } = this.state;

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
                styleVariant="default"
            >
                <StyledComponent className="admin-modal-payment-transaction-refund" styles={require('./styles')}>
                    <ModalBody styleVariant='default'>
                        <ModalTitle title='Zwrot płatności' />
                        <ElementEditor
                            location={location}
                            history={history}
                            forms={[{
                                title: 'Dane',
                                name: 'editor',
                                submitAction: this.onSubmit,
                                data: formState,
                                onStateChange: formState => this.setState({ formState }),
                                elements: [{
                                    type: 'datePicker',
                                    name: 'refundAt',
                                    label: 'Data zwrotu',
                                }],
                            }]}
                        />
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
