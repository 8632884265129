import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 77em;
    background: white;

    .modal-body-component {
        overflow-x: auto;

        .admin-events-select-calendar {
            overflow-x: overlay;
        }
    }
`;
