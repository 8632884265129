export const PROMO_CODE_LIMIT_TYPE_OPEN = 'open';
export const PROMO_CODE_LIMIT_TYPE_USE_LIMIT = 'useLimit';
export const PROMO_CODE_LIMIT_TYPES = [
    {
        key: PROMO_CODE_LIMIT_TYPE_OPEN,
        label: 'Brak limitów',
    },
    {
        key: PROMO_CODE_LIMIT_TYPE_USE_LIMIT,
        label: 'Limit zamówień',
    },
];

export const PROMO_CODE_USE_LIMIT_TYPE_OPEN = 'open';
export const PROMO_CODE_USE_LIMIT_TYPE_TRANSACTION_LIMIT = 'transactionLimit';
export const PROMO_CODE_USE_LIMIT_TYPES = [
    {
        key: PROMO_CODE_USE_LIMIT_TYPE_OPEN,
        label: 'Brak limitów',
    }, {
        key: PROMO_CODE_USE_LIMIT_TYPE_TRANSACTION_LIMIT,
        label: 'Limit transakcji',
    },
];

export const PROMO_CODE_VALUE_TYPE_AMOUNT = 'amount';
export const PROMO_CODE_VALUE_TYPE_PERCENT = 'percent';
export const PROMO_CODE_VALUE_TYPES = [
    {
        key: PROMO_CODE_VALUE_TYPE_AMOUNT,
        label: 'Kwotowy',
    },
    {
        key: PROMO_CODE_VALUE_TYPE_PERCENT,
        label: 'Procentowy',
    },
];

export const PROMO_CODE_BY_LOCATION = [
    {
        slug: 'sobieski',
        value: 'SOBIESKI',
    },
]; 

export const PROMO_CODE_OWNER_TYPE_OPEN = 'open';
export const PROMO_CODE_OWNER_TYPE_USER = 'user';
export const PROMO_CODE_OWNER_TYPES = [
    {
        key: PROMO_CODE_OWNER_TYPE_OPEN,
        label: 'Brak przypisania',
    }, {
        key: PROMO_CODE_OWNER_TYPE_USER,
        label: 'Użytkownik',
    },
];