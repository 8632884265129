import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 100%;
    
    .panel-big-calendar {
        .rbc-time-content {
            .rbc-day-slot {
                .work-hour {
                    position: absolute;
                    display: block;
                    width: 100%;
                    background: #C1C1C1;
                    opacity: .25;

                    &.start {
                        top: 0;
                    }

                    &.end {
                        bottom: 0;
                    }
                }
            }
        }

        .rbc-event {
            display: flex !important;
            flex-direction: row !important;
            border-radius: 0 !important; 
            color: white !important;
            text-transform: capitalize;
            padding: 0 !important;

            .rbc-event-label {
                display: block !important;
                width: 100% !important;
                padding: 0 .3em;
            }    
        }

        .rbc-calendar {
            .rbc-time-view {
                position: relative;

                .rbc-event {
                    display: flex !important;
                    flex-direction: row !important;
                    border-radius: 0 !important; 
                    color: white !important;
                    text-transform: capitalize;
                    padding: 0 !important;

                    .rbc-event-label {
                        display: block !important;
                        width: 100% !important;
                        padding: 0 .3em;
                    }

                    .rbc-event-content {
                        width: 100%;
                        height: 100%; 

                        .custom-event {
                            width: 100%;
                            height: 100%;
                            padding: 0 .3em;


                            &.private {
                                background: #787878;
                            }

                            &.done {
                                background: #1e8000
                            }

                            &.notDone {
                                background: #D83143;
                            }

                            &.notDoneExpired {
                                background: #D83143;
                            }

                            &.plannedPaid {
                                background: #5f72ff;
                            }

                            &.plannedNotPaid {
                                background: #5f72ff;
                            }

                            &.canceled {
                                background: #545454;
                            }
                        }
                    }
                }

                .overlay {
                    position: absolute;
                    display: none;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    background: white;
                    opacity: 0.9;

                    &.visible {
                        display: block;
                    }
                }
            }
        }
    }


    @media (max-width: ${variables.tabletS}) {}
`;
