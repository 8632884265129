export const USER_OFFER_LABEL_VALUE_NOTICE = 'notice';

export const USER_OFFER_STATUS_NEW = 'new';
export const USER_OFFER_STATUS_ACTIVE = 'active';
export const USER_OFFER_STATUS_CLOSED = 'closed';
export const USER_OFFER_STATUS_BLOCKED = 'blocked';
export const USER_OFFER_STATUS_TERMINATED = 'terminated';
export const USER_OFFER_STATUS_TERMINATION = 'termination';
export const USER_OFFER_STATUSES = [{
    key: USER_OFFER_STATUS_NEW,
    value: USER_OFFER_STATUS_NEW,
    label: 'Nowe',
    stateColor: 'warning',
}, {
    key: USER_OFFER_STATUS_ACTIVE,
    value: USER_OFFER_STATUS_ACTIVE,
    label: 'Aktywne',
    stateColor: 'success',
}, {
    key: USER_OFFER_STATUS_CLOSED,
    value: USER_OFFER_STATUS_CLOSED,
    label: 'Okres zamknięcia',
    stateColor: 'alert',
}, {
    key: USER_OFFER_STATUS_BLOCKED,
    value: USER_OFFER_STATUS_BLOCKED,
    label: 'Zablokowane',
    stateColor: 'alert',
}, {
    key: USER_OFFER_STATUS_TERMINATED,
    value: USER_OFFER_STATUS_TERMINATED,
    label: 'Zamknięte',
    stateColor: 'warning',
}];

// Medical packages
export const USER_OFFER_MEDICAL_PACKAGE_HEALTHY_SPINE = 'healthySpine';
export const USER_OFFER_MEDICAL_PACKAGE_HEALTHY_DIET = 'healthyDiet';
export const USER_OFFER_MEDICAL_PACKAGE_OVERWEIGHT = 'overweight';
export const USER_OFFER_MEDICAL_PACKAGE_UNDERWEIGHT = 'underweight';
export const USER_OFFER_MEDICAL_PACKAGE_POSTURE_DEFECTS = 'postureDefects';
export const USER_OFFER_MEDICAL_PACKAGE_MUSCULAR_SYSTEM_DEFECTS = 'muscularSystemDefects';
export const USER_OFFER_MEDICAL_PACKAGE_OVERLOAD_CHANGES = 'overloadChanges';
export const USER_OFFER_MEDICAL_PACKAGE_BIRTH_DEFECTS = 'birthDefects';
export const USER_OFFER_MEDICAL_PACKAGE_PREVENTION_OF_OSTEOARTHRITIS = 'preventionOfOsteoarthritis';
export const USER_OFFER_MEDICAL_PACKAGE_INDICATIONS_FOR_REGULAR_PHYSICAL_ACTIVITY = 'indicationsForRegularPhysicalActivity';
export const USER_OFFER_MEDICAL_PACKAGE_PHYSIOTHERAPY_CLASSES = 'physiotherapyClasses';

export const USER_OFFER_MEDICAL_PACKAGES = [{
    key: USER_OFFER_MEDICAL_PACKAGE_HEALTHY_SPINE,
    label: 'Zdrowy kręgosłup (choroby kręgosłupa)',
}, {
    key: USER_OFFER_MEDICAL_PACKAGE_HEALTHY_DIET,
    label: 'Zdrowa dieta',
}, {
    key: USER_OFFER_MEDICAL_PACKAGE_OVERWEIGHT,
    label: 'Walka z nadwagą lub otyłością',
}, {
    key: USER_OFFER_MEDICAL_PACKAGE_UNDERWEIGHT,
    label: 'Walka z niedowagą',
}, {
    key: USER_OFFER_MEDICAL_PACKAGE_POSTURE_DEFECTS,
    label: 'Indywidualna terapia ruchowa walka z wadami postawy',
}, {
    key: USER_OFFER_MEDICAL_PACKAGE_MUSCULAR_SYSTEM_DEFECTS,
    label: 'Walka ze schorzeniami układu mięśniowego',
}, {
    key: USER_OFFER_MEDICAL_PACKAGE_OVERLOAD_CHANGES,
    label: 'Walka ze zmianami przeciążeniowymi',
}, {
    key: USER_OFFER_MEDICAL_PACKAGE_BIRTH_DEFECTS,
    label: 'Terapia stabilizująca wady wrodzone',
}, {
    key: USER_OFFER_MEDICAL_PACKAGE_PREVENTION_OF_OSTEOARTHRITIS,
    label: 'Zapobieganie osteoporozie',
}, {
    key: USER_OFFER_MEDICAL_PACKAGE_INDICATIONS_FOR_REGULAR_PHYSICAL_ACTIVITY,
    label: 'Wskazania do regularnej aktywności ruchowej',
}, {
    key: USER_OFFER_MEDICAL_PACKAGE_PHYSIOTHERAPY_CLASSES,
    label: 'Zajęcia fizjoterapeutyczne',
}];

// Sell propabilities
export const USER_OFFER_SELL_PROPABILITY_LOW = 'low';
export const USER_OFFER_SELL_PROPABILITY_MEDIUM = 'medium';
export const USER_OFFER_SELL_PROPABILITY_HIGH = 'high';
export const USER_OFFER_SELL_PROPABILITIES = [{
    label: 'Niskie',
    key: USER_OFFER_SELL_PROPABILITY_LOW,
}, {
    label: 'Średnie',
    key: USER_OFFER_SELL_PROPABILITY_MEDIUM,
}, {
    label: 'Wysokie',
    key: USER_OFFER_SELL_PROPABILITY_HIGH,
}];

export const USER_OFFER_TERMINATE_REASON_1 = 'reason1';
export const USER_OFFER_TERMINATE_REASON_2 = 'reason2';
export const USER_OFFER_TERMINATE_REASON_3 = 'reason3';
export const USER_OFFER_TERMINATE_REASON_4 = 'reason4';
export const USER_OFFER_TERMINATE_REASON_5 = 'reason5';
export const USER_OFFER_TERMINATE_REASON_6 = 'reason6';
export const USER_OFFER_TERMINATE_REASON_7 = 'reason7';
export const USER_OFFER_TERMINATE_REASON_8 = 'reason8';
export const USER_OFFER_TERMINATE_REASON_9 = 'reason9';
export const USER_OFFER_TERMINATE_REASON_OTHER = 'other';
export const USER_OFFER_TERMINATE_REASONS = [
    {
        key: USER_OFFER_TERMINATE_REASON_1,
        label: 'powód1',
    }, {
        key: USER_OFFER_TERMINATE_REASON_2,
        label: 'powód2',
    }, {
        key: USER_OFFER_TERMINATE_REASON_3,
        label: 'powód3',
    }, {
        key: USER_OFFER_TERMINATE_REASON_4,
        label: 'powód4',
    }, {
        key: USER_OFFER_TERMINATE_REASON_5,
        label: 'powód5',
    }, {
        key: USER_OFFER_TERMINATE_REASON_6,
        label: 'powód6',
    }, {
        key: USER_OFFER_TERMINATE_REASON_7,
        label: 'powód7',
    }, {
        key: USER_OFFER_TERMINATE_REASON_8,
        label: 'powód8',
    }, {
        key: USER_OFFER_TERMINATE_REASON_9,
        label: 'powód9',
    }, {
        key: USER_OFFER_TERMINATE_REASON_OTHER,
        label: 'Inny...',
    },
];

export const USER_OFFER_USERS_TYPE_SINGLE = 'single';
export const USER_OFFER_USERS_TYPE_MULTI = 'multi';
export const USER_OFFER_USERS_TYPES = [
    {
        key: USER_OFFER_USERS_TYPE_SINGLE,
        label: 'Pojedynczy',
    },
    {
        key: USER_OFFER_USERS_TYPE_MULTI,
        label: 'Multi',
    },
];

export const USER_OFFER_PERIOD_TYPE_SINGLE = 'single';
export const USER_OFFER_PERIOD_TYPE_RECURRING = 'recurring';
export const USER_OFFER_PERIOD_TYPES = [{
    key: USER_OFFER_PERIOD_TYPE_SINGLE,
    label: 'Pojedyncze',
}, {
    key: USER_OFFER_PERIOD_TYPE_RECURRING,
    label: 'Cykliczne',
}];