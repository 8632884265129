import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    remove,
    reset,
    setPaidStatus,
    setDoneStatus,
    unbonus,
    cancelDiagnostic,
    confirmRequest,
} from 'Redux/modules/admin/events/actions';

import { list as listUserOffers } from 'Redux/modules/admin/userOffers/actions';
import { list as listProducts } from 'Redux/modules/admin/products/actions';
import { list as listUsers } from 'Redux/modules/admin/users/actions';
import { list as listLocations } from 'Redux/modules/admin/locations/actions';

import {
    list as listWorkHours,
} from 'Redux/modules/admin/userWorkHours/actions';

export default connect(
    state => ({
        userWorkHoursList: state.adminUserWorkHours.list,
        profile: state.user.profile,
    }),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            remove: dispatch(remove),
            reset: dispatch(reset),
            setPaidStatus: dispatch(setPaidStatus),
            setDoneStatus: dispatch(setDoneStatus),
            listUserOffers: dispatch(listUserOffers),
            listProducts: dispatch(listProducts),
            listUsers: dispatch(listUsers),
            listLocations: dispatch(listLocations),
            listWorkHours: dispatch(listWorkHours),
            unbonus: dispatch(unbonus),
            cancelDiagnostic: dispatch(cancelDiagnostic),
            confirmRequest: dispatch(confirmRequest),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        userWorkHours: stateProps.userWorkHoursList.elements.find(userWorkHour => userWorkHour.user && ownProps.predefinedState && userWorkHour.user.id === ownProps.predefinedState.leadId),
    })
)(Component);