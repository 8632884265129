export const USER_PAYMENT_METHOD_TYPE_CARD_TOKEN = 'CARD_TOKEN';

export const USER_PAYMENT_METHOD_TYPES = [
    {
        key: USER_PAYMENT_METHOD_TYPE_CARD_TOKEN,
        label: 'Karta płatnicza',
    },
];

export const USER_PAYMENT_METHOD_CARD_TOKEN_TYPE_FIRST = 'first';
export const USER_PAYMENT_METHOD_CARD_TOKEN_TYPE_RECURRING = 'recurring';
export const USER_PAYMENT_METHOD_CARD_TOKEN_TYPES = [
    {
        key: USER_PAYMENT_METHOD_CARD_TOKEN_TYPE_FIRST,
        label: 'Krótkoterminowy',
    },
    {
        key: USER_PAYMENT_METHOD_CARD_TOKEN_TYPE_RECURRING,
        label: 'Długoterminowy',
    },
];