import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import { BIG_CALENDAR_TRAINER_DEFAULT_VIEW } from 'Consts/bigCalendar';

import { getFullName } from 'Utils/user';
import { getCategoryLabel, getCalendarEventStatus } from 'Utils/event';

import StyledComponent from 'Components/core/StyledComponent';
import BigCalendar from 'Components/layoutAdmin/panel/BigCalendar';
import CalendarLegend from 'Components/admin/events/CalendarLegend';
import CalendarEvent from 'Components/admin/events/CalendarEvent';
import ModalEventEditor from 'Components/admin/events/ModalEditor';
import Spinner from 'Components/layoutAdmin/Spinner';

export default class PanelEventsCalendar extends Component {
    static propTypes = {
        // Pass props
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
        onSelectSlot: PropTypes.func,
        onSelectEvent: PropTypes.func,
        shouldLoadData: PropTypes.bool,

        // Redux props
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        events: PropTypes.object.isRequired,
    };
    static defaultProps = {
        onSelectSlot: null,
        onSelectEvent: null,
        predefinedQuery: {},
        shouldLoadData: true,
    };

    state = {
        selectedEvent: null,
        selectedSlot: null,
        calendarDate: undefined,
        calendarView: BIG_CALENDAR_TRAINER_DEFAULT_VIEW,
    };

    calendarRef = React.createRef();

    componentDidMount = () => {
        this.setState({
            calendarDate: moment().startOf('week')
                .toDate(),
            calendarView: BIG_CALENDAR_TRAINER_DEFAULT_VIEW,
        }, () => {
            this.loadData();
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }

        if (prevState.calendarDate !== this.state.calendarDate || prevState.calendarView !== this.state.calendarView) { //eslint-disable-line react/destructuring-assignment
            this.loadData();
        } 
    }

    loadData = () => {
        const { actions, predefinedQuery, shouldLoadData } = this.props;
        const { calendarDate, calendarView } = this.state;

        if (!shouldLoadData) {
            return;
        }

        let query = { 
            page: 1,
            perPage: 9999,
            ...predefinedQuery, 
        };

        switch (calendarView) {
            case 'day':
                query = {
                    fromDate: moment(calendarDate).startOf('day')
                        .toDate(),
                    untilDate: moment(calendarDate).endOf('day')
                        .toDate(),
                    ...query,
                };
                break;
            case 'week': 
                query = {
                    fromDate: moment(calendarDate).startOf('week')
                        .toDate(),
                    untilDate: moment(calendarDate).endOf('week')
                        .toDate(),
                    ...query,
                };
                break;
            case 'month':
                query = {
                    fromDate: moment(calendarDate).startOf('month')
                        .subtract(1, 'week')
                        .toDate(),
                    untilDate: moment(moment(calendarDate).add(1, 'week')).endOf('month')
                        .add(1, 'week')
                        .toDate(),
                    ...query,
                };
                break;
        }
        
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...query });
        }, 500);
    }

    getTitle = event => {
        const { predefinedQuery } = this.props;

        if(predefinedQuery.leadId || predefinedQuery.leadIds) {
            return event.product 
                ? `${event.product && event.product.name} ${(event.user && '- ' + getFullName(event.user).label + (Boolean(event.order) && `(Trening: ${event.order}/${event.totalCount})`)) || ''}`
                : `${getCategoryLabel(event).label} ${(event.user && '- ' + getFullName(event.user).label + (Boolean(event.order) && `(Trening: ${event.order}/${event.totalCount})`)) || ''}`;
        }

        return event.product 
            ? `${event.product && event.product.name} ${(event.lead && '- ' + getFullName(event.lead).label + (Boolean(event.order) && `(Trening: ${event.order}/${event.totalCount})`)) || ''}`
            : `${getCategoryLabel(event).label} ${(event.lead && '- ' + getFullName(event.lead).label + (Boolean(event.order) && `(Trening: ${event.order}/${event.totalCount})`)) || ''}`;
    }

    onSelectSlot = (slot) => {
        this.setState({ selectedSlot: slot });
    }

    onSelectEvent = (event) => {
        const { onSelectEvent, onSelectSlot } = this.props;
        
        if(!event?.resource) {
            onSelectSlot && onSelectSlot(event);
        }

        if(event?.resource) {
            if(onSelectEvent) {
                if(onSelectEvent(event)) {
                    this.setState({ selectedEvent: event });
                }
            } else {
                this.setState({ selectedEvent: event });
            }
        }
    }

    render() {
        const { location, history, events, predefinedQuery, shouldLoadData, onSelectSlot } = this.props;
        const { selectedEvent, selectedSlot } = this.state;
        const isLoading = Boolean(events && events.isLoading);
        
        if (!shouldLoadData) {
            events.elements = [];
        }

        const displayEvents = events.elements.map(event => ({
            title: this.getTitle(event),
            start: moment(event.startAt).toDate(),
            end: moment(event.endAt).toDate(),
            resource: event,
            stateColor: getCalendarEventStatus(event).stateColor,
        }));

        if(selectedSlot && onSelectSlot) {
            displayEvents.push({
                title: 'Potwierdź termin',
                start: selectedSlot.start,
                end: selectedSlot.end,
            });
        }

        return (
            <StyledComponent
                className={classnames(
                    'admin-events-calendar-2',
                    { loading: isLoading }
                )}
                styles={require('./styles')}
            >
                {isLoading && (
                    <>
                        <span className="loading-mask" />
                        <Spinner />
                    </>
                )}
                <BigCalendar
                    ref={this.calendarRef}
                    calendarProps={{
                        selectable: true,
                        onView: view => this.setState({ calendarView: view }),
                        onNavigate: date => this.setState({ calendarDate: date }),
                        onSelectSlot: slot => this.onSelectSlot(slot),
                        onSelectEvent: event => this.onSelectEvent(event),
                        events: displayEvents,
                        components: {
                            event: CalendarEvent,
                        },
                        timeslots: 1,
                        step: 30,
                    }}
                />
                
                <CalendarLegend />

                {((!onSelectSlot && selectedSlot) || selectedEvent) && (
                    <ModalEventEditor
                        location={location}
                        history={history}
                        isOpen={true}
                        onClose={() => this.setState({ selectedEvent: null, selectedSlot: null })}
                        data={selectedEvent?.resource || null}
                        onRemove={() => this.setState({ selectedEvent: null, selectedSlot: null }, () => this.loadData())}
                        predefinedState={{
                            lead: predefinedQuery?.lead || undefined,
                            startAt: selectedSlot && selectedSlot.start || undefined,
                        }}
                    />
                )}
            </StyledComponent>
        );
    }
}
