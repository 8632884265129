import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    ADMIN_USER_OFFERS,
    ADMIN_USER_OFFERS_CREATE,
    ADMIN_USER_OFFERS_MANAGE,
    ADMIN_USER_OFFERS_MIGRATE,
} from 'Consts/routes';
import { PAYMENT_TRANSACTION_STATUSES } from 'Consts/paymentTransactions';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';
import { getFormattedDate } from 'Utils/date';
import { getFullName } from 'Utils/user';
import { getTypeLabel } from 'Utils/offer';
import { formatPrice } from 'Utils/math';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import UserOffersList from 'Components/admin/userOffers/List';
import PaymentTransactionsList from 'Components/admin/paymentTransactions/List';
import IncomeList from 'Components/admin/userOffers/IncomeList';

export const TAB_USER_OFFERS = 'userOffers';
export const TAB_INCOME = 'income';
export const TAB_PAYMENT_TRANSACTIONS = 'paymentTransactions';

export default class AdminUserOffers extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-user-offers"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_USER_OFFERS.path),
                        label: 'Zamówienia',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Zamówienia"
                        controls={[{
                            visible: Boolean(!queryObject.tab || queryObject.tab === 'userOffers'),
                            key: 'createUserOffer',
                            label: 'Nowe zamówienie',
                            onClick: () => history.push(ADMIN_USER_OFFERS_CREATE.path),
                        }, {
                            visible: Boolean(!queryObject.tab || queryObject.tab === 'userOffers'),
                            key: 'migrateUserOffer',
                            label: 'Nowa migracja',
                            onClick: () => history.push(ADMIN_USER_OFFERS_MIGRATE.path),
                        }]}
                        tabs={[{
                            key: TAB_USER_OFFERS,
                            label: 'Lista zamówień',
                            children: (
                                <UserOffersList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_INCOME,
                            label: 'Przychody',
                            children: (
                                <IncomeList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_PAYMENT_TRANSACTIONS,
                            label: 'Lista płatności',
                            children: (
                                <PaymentTransactionsList
                                    location={location}
                                    history={history}
                                    predefinedQuery={{
                                        status: 'completed',
                                    }}
                                    onMapTitle={element => `${element.userOffer && element.userOffer.user && getFullName(element.userOffer.user).label}`}
                                    onMapSubtitle={element => `${element.userOffer && element.userOffer.name} (${element.userOffer && element.userOffer.offer && element.userOffer.offer.name})`}
                                    onMapAdditionals={element => [{
                                        name: 'Kwota',
                                        value: formatPrice(element.value),
                                    }, {
                                        name: 'Data',
                                        value: getFormattedDate(element.createdAt),
                                    }, {
                                        name: 'Typ oferty',
                                        value: getTypeLabel(element.userOffer && element.userOffer.offer).label,
                                    }]}
                                    onMapControls={(element, controls) => [
                                        ...controls,
                                        {
                                            type: 'button',
                                            label: 'Zamówienie',
                                            visible: Boolean(element.userOffer),
                                            to: withVariables(ADMIN_USER_OFFERS_MANAGE.path, { id: element.userOffer && element.userOffer.id }),
                                        },
                                    ]}
                                    onMapFilters={() => [{
                                        name: 'status',
                                        label: 'Status',
                                        type: 'select',
                                        inputProps: {
                                            options: [
                                                {
                                                    label: 'Wszystkie',
                                                    value: '',
                                                },
                                                ...PAYMENT_TRANSACTION_STATUSES.map(status => ({ ...status, value: status.key })),
                                            ],
                                        },
                                    }]}
                                />
                            ),
                        }]}
                    />
                </PageContent>

            </StyledComponent>
        );
    }
}
