import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelUserOffersDeletionEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
        actions: PropTypes.shape({
            remove: PropTypes.func.isRequired,
        }).isRequired,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {
            ...this.props.data, //eslint-disable-line react/destructuring-assignment
        },
    };

    onSubmit = () => {
        const { formState } = this.state;
        const { data, actions, onSuccess } = this.props;
        
        return new Promise((resolve, reject) => {
            if (window.confirm('Czy napewno chcesz usunąć zamówienie?')) {
                actions.remove({
                    id: data.id,
                    deleteReason: formState.deleteReason,
                });
                resolve();
            } else {
                reject();
            }
        }).then(() => {
            onSuccess();
            toast('Zamówienie zostało usunięte');
        });
    }

    render() {
        const { location, history, data } = this.props;

        return (
            <StyledComponent
                className="panel-user-offers-deletion-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            isVisible: true,
                            type: 'textarea',
                            name: 'deleteReason',
                            label: 'Powód usunięcia',
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}