import { USER_PAYMENT_METHOD_TYPES } from 'Consts/usersPaymentMethods';

export const getTypeLabel = paymentMethod => {
    if(!paymentMethod) {
        return { found: false, label: 'Nieznany' };
    }

    const { type } = paymentMethod;
    const option = USER_PAYMENT_METHOD_TYPES.find(paymentMethod => paymentMethod.key === type);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label };
};

