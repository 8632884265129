import moment from 'moment';

import {
    EVENT_CATEGORIES,
    EVENT_PAID_STATUSES,
    EVENT_DONE_STATUSES,
    EVENT_DONE_STATUS_NOT_DONE,
    EVENT_DONE_STATUS_DONE,
    EVENT_CATEGORY_PRIVATE,
    EVENT_CATEGORY_UNKNOWN,
    EVENT_DONE_STATUS_CANCELED,
    CANCEL_DIAGNOSTIC_REASONS,
    CANCEL_DIAGNOSTIC_REASON_UNKNOWN,
} from 'Consts/events';
import { EVENT_PAID_STATUS_CREDIT, EVENT_PAID_STATUS_PAID } from '../consts/events';
import { getFormattedDate, translateDateToDay, translateTimeUnit } from 'Utils/date';

export const getCategoryLabel = event => {
    if(!event) {
        return { found: false, label: EVENT_CATEGORY_UNKNOWN };
    }

    const { category } = event;
    const option = EVENT_CATEGORIES.find(eventCategory => eventCategory.key === category);
    if (!option) {
        return { found: false, label: EVENT_CATEGORY_UNKNOWN, stateColor: 'alert' };
    }

    return { ...option, found: true };
};

export const getCancelReasonLabel = event => {
    if(!event) {
        return { found: false, label: CANCEL_DIAGNOSTIC_REASON_UNKNOWN };
    }

    const { eventCancelType } = event;
    const option = CANCEL_DIAGNOSTIC_REASONS.find(cancelReason => cancelReason.key === eventCancelType);

    if (!option) {
        return { found: false, label: CANCEL_DIAGNOSTIC_REASON_UNKNOWN };
    }

    return { ...option, found: true };
};

export const getPaidStatusLabel = event => {
    if(!event) {
        return { found: false, label: EVENT_CATEGORY_UNKNOWN };
    }

    const { paidStatus } = event;
    const option = EVENT_PAID_STATUSES.find(eventPaidStatus => eventPaidStatus.key === paidStatus);
    
    if (!option) {
        return { found: false, label: EVENT_CATEGORY_UNKNOWN, stateColor: 'alert' };
    }

    return { ...option, found: true };
};

export const getDoneStatusLabel = event => {
    if(!event) {
        return { found: false, label: EVENT_CATEGORY_UNKNOWN };
    }

    const { doneStatus } = event;
    const option = EVENT_DONE_STATUSES.find(eventDoneStatus => eventDoneStatus.key === doneStatus);
    if (!option) {
        return { found: false, label: EVENT_CATEGORY_UNKNOWN, stateColor: 'alert' };
    }

    return { ...option, found: true };
};

export const getCalendarEventStatus = event => {
    if (!event) {
        return {
            stateColor: 'default',
        };
    }

    const { doneStatus, startAt, paidStatus, category, endAt } = event;
    const currentTime = moment().format();

    // Category: Private
    if (category == EVENT_CATEGORY_PRIVATE) {
        return {
            stateColor: 'private',
        };
    }   

    // Done 
    if (doneStatus == EVENT_DONE_STATUS_DONE) {
        return {
            stateColor: 'done',   
        };
    }

    // Not done, should be finished
    if (doneStatus == EVENT_DONE_STATUS_NOT_DONE && moment(currentTime).isAfter(endAt)) {
        return {
            stateColor: 'notDoneExpired',   
        };
    }

    // Not done, start date is before now
    if (doneStatus == EVENT_DONE_STATUS_NOT_DONE && moment(startAt).isBefore(currentTime)) {
        return {
            stateColor: 'notDone',   
        };
    }
    // Planet paid
    if (doneStatus == EVENT_DONE_STATUS_NOT_DONE && moment(startAt).isAfter(currentTime) && (paidStatus == EVENT_PAID_STATUS_PAID || paidStatus == EVENT_PAID_STATUS_CREDIT)) {
        return {
            stateColor: 'plannedPaid',
        };
    }

    // Planned not paid
    if (doneStatus == EVENT_DONE_STATUS_NOT_DONE && moment(startAt).isAfter(currentTime) && (paidStatus !== EVENT_PAID_STATUS_PAID)) {
        return {
            stateColor: 'plannedNotPaid',
        };
    }

    // Canceled
    if (doneStatus == EVENT_DONE_STATUS_CANCELED) {
        return {
            stateColor: 'canceled',
        };
    }

    return 'default';
};

export const getTitle = element => {
    if(!element.startAt && !element.endAt) {
        return 'Niezaplanowany';
    }

    const startDate = element.startAt 
        ? `Data rozpoczęcia: ${getFormattedDate(element.startAt, 'date')} ${translateDateToDay(element.startAt)} ${getFormattedDate(element.startAt, 'time')}` 
        : 'Brak daty rozpoczęcia';
    
    const durationTime = element.product?.displayDurationValue
        ? `, Czas trwania: ${element.product.displayDurationValue} ${translateTimeUnit(element.product)}`
        : '';

    return `${startDate}${durationTime}`;
};
