export const GR_HISTORY_LOGS_CATEGORY_CREATE = 'create';
export const GR_HISTORY_LOGS_CATEGORY_UPDATE = 'update';
export const GR_HISTORY_LOGS_CATEGORY_DELETE = 'delete';
export const GR_HISTORY_LOGS_CATEGORY_CUSTOM_FIELDS = 'customFields';
export const GR_HISTORY_LOGS_CATEGORY_EVENT = 'event';

export const GR_HISTORY_LOGS_CATEGORIES = [{
    value: GR_HISTORY_LOGS_CATEGORY_CREATE,
    label: 'Utworzenie kontaktu',
}, {
    value: GR_HISTORY_LOGS_CATEGORY_UPDATE,
    label: 'Aktualizacja kontaktu',
}, {
    value: GR_HISTORY_LOGS_CATEGORY_DELETE,
    label: 'Usunięcie kontaktu',
}, {
    value: GR_HISTORY_LOGS_CATEGORY_CUSTOM_FIELDS,
    label: 'Ustawienie pól dodatkowych',
}, {
    value: GR_HISTORY_LOGS_CATEGORY_EVENT,
    label: 'Wydarzenie',
}];
