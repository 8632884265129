import { connect } from 'react-redux';
import Component from './component';

import { list as listUserPaymentMethods } from 'Redux/modules/adept/userPaymentMethods/actions';
import { singleBySlug as getSingleOfferBySlug } from 'Redux/modules/public/offers/actions';

export default connect(
    state => ({
        userPaymentMethods: state.adeptUserPaymentMethods.list,
    }),
    dispatch => ({
        actions: {
            listUserPaymentMethods: dispatch(listUserPaymentMethods),
            getSingleOfferBySlug: dispatch(getSingleOfferBySlug),
        },
    }),
)(Component); 