import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE } from 'Consts/routes';

import { getFullName as getUserFullName } from 'Utils/user';
import { getFormattedDate } from 'Utils/date';
import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class AdminUserOfferDiagnosticReportsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        userOfferDiagnosticReports: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        data: null,
        predefinedState: {},
    };

    state = {};

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',    
        timestamp: undefined,
        ...this.props.predefinedQuery, //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.list({ ...queryObject });
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { predefinedQuery } = this.props;

        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(predefinedQuery)) {
            this.loadData();
        }
    }

    render() {
        const { location, history, userOfferDiagnosticReports } = this.props;
        return (
            <StyledComponent
                className="admin-user-offer-diagnostic-reports-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userOfferDiagnosticReports}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={getFormattedDate(element.createdAt)}
                            subtitle={`Autor raportu - ${getUserFullName(element.creator).label}`}
                            controls={[{
                                type: 'button',
                                label: 'Szczegóły',
                                visible: true,
                                to: withVariables(ADMIN_USER_OFFER_DIAGNOSTIC_REPORT_MANAGE.path, { id: element.id }),
                            }]}
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}