
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getFullName as getUserFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import PageContent from 'Components/layout/panel/PageContent';
import SelectCalendar from 'Components/admin/events/SelectCalendar';
import CalendarList from 'Components/admin/events/CalendarList';
import ModalEventEditor from 'Components/admin/events/ModalEditor';

export const TAB_CALENDAR = 'calendar';
export const TAB_EVENTS_LIST = 'eventsList';
export default class AdminEventsCalendar extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        profile: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    state = {
        formState: {},
        isModalCreateEventVisible: false,
    };

    render() {
        const { location, history, profile } = this.props;
        const { formState, isModalCreateEventVisible } = this.state;

        return (
            <StyledComponent
                className="page-admin-events-calendar"
                styles={require('./styles')}
            >
                <PageContent>
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Kalendarz wydarzeń"
                        tabs={[{
                            key: TAB_EVENTS_LIST,
                            label: 'Lista wydarzeń',
                            children: (
                                <CalendarList
                                    location={location}
                                    history={history}
                                    isManager={profile.isRadissonManager}
                                />
                            ),
                        }, {
                            key: TAB_CALENDAR,
                            label: 'Kalendarz',
                            visible: !profile.isRadissonManager,
                            children: (
                                <SelectCalendar
                                    location={location}
                                    history={history}
                                    onSelectSlot={(slot, lead) => {
                                        this.setState({
                                            isModalCreateEventVisible: true,
                                            formState: {
                                                ...formState,
                                                leadId: { value: lead.id, label: getUserFullName(lead).label },
                                                lead,
                                                firstEventStartAt: slot.start,
                                            }
                                        })
                                    }}
                                />
                            ),
                        }]}
                    />
                </PageContent>
                <ModalEventEditor
                    location={location}
                    history={history}
                    isOpen={isModalCreateEventVisible}
                    onClose={() => this.setState({ isModalCreateEventVisible: false })}
                    data={{
                        startAt: formState.firstEventStartAt
                    }}
                    predefinedState={{
                        lead: formState?.lead || null,
                        leadId: formState.leadId || undefined,
                    }}
                />
            </StyledComponent>
        );
    }
}
