export function priceSum(price, quantity) {
    return formatPrice(
        price * quantity
    );
}

export function grossPrice(price, taxAmount) {
    price = parseInt(price);
    return formatPrice(
        price + (price * (taxAmount / 100))
    );
}

export function grossPriceSum(price, taxAmount, quantity = 1) {
    return formatPrice(
        grossPrice(price, taxAmount) * quantity
    );
}

export function formatPrice(price, suffix = ' zł') {
    price = (Math.round(price * 100) / 100).toFixed(2);
    return price + suffix;
}
