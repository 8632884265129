import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USER_OFFERS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelUserOffersInviteUserEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
        actions: PropTypes.shape({
            invite: PropTypes.func.isRequired,
        }).isRequired,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {
            ...this.props.data, //eslint-disable-line react/destructuring-assignment
        },
    };

    onSubmit = () => {
        const { actions, onClose, data, history } = this.props;
        const { formState } = this.state;

        return actions.invite({
            ...formState,
            id: data.userOffer.data.id,
        })
            .then(() => {
                if (onClose) {
                    onClose();
                }

                history.push(
                    withVariables(
                        ADMIN_USER_OFFERS_MANAGE.path,
                        { id: data.userOffer.data.id }
                    )
                );
            });
    }

    render() {
        const { location, history, data } = this.props;

        return (
            <StyledComponent
                className="panel-user-offers-invite-user-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Imię',
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'surname',
                            label: 'Nazwisko',
                            inputProps: {
                                styleVersion: 2,
                            },
                        }, {
                            type: 'input',
                            name: 'email',
                            label: 'Adres e-mail',
                            inputProps: {
                                styleVersion: 2,
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}