import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 80vw;
    height: 100%;

    .modal-body-component {
        max-height: initial;
    }
`;
