import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';

import StyledComponent from 'Components/core/StyledComponent';
import SpinnerComponent from 'Components/layoutAdmin/Spinner/component';

export default class PanelActivityLogsDetailsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
        actions: PropTypes.shape({
            remove: PropTypes.func.isRequired,
        }).isRequired,
        onSuccess: PropTypes.func,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
    };
    static defaultProps = {
        data: null,
        isOpen: false,
        onClose: null,
    };

    state = {
        formState: {
            ...this.props.data, //eslint-disable-line react/destructuring-assignment
        },
    };

    render() {
        const { data } = this.props;

        return (
            <StyledComponent
                className="panel-activity-logs-details-editor"
                styles={require('./styles')}
            >
                
                <div className="loading">
                    {!data && (
                        <SpinnerComponent />
                    )}
                </div>
                {data && (
                    <div className="cols">
                        <div className="col">
                            <ReactJson 
                                src={data.activityLog.oldData} 
                                name="oldData"
                            />
                        </div>
                        <div className="col">
                            <ReactJson 
                                src={data.activityLog.newData} 
                                name="newData"
                            />
                        </div>
                    </div>
                )}
            </StyledComponent>
        );
    }
}