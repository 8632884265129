import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    .tooltips-legend {
        color: #5d5d5d;
        font-weight: 600;
        margin: .75em 0;
    }

    .tooltips-wrapper {
        display: flex;
    
        .tooltip-block {
            width: 25%;
            height: .75em;
        
            .color-block {
                height: 100%;
                &.done {
                    background: #1E8000;
                }

                &.planned {
                    background: #5f72ff;
                }

                &.notDone {
                    background: #D83143;
                }

                &.private {
                    background: #787878;
                }

                &.canceled {
                    background: #545454;
                }
            }

            .label {
                color: #5d5d5d;
                font-weight: 300;
                margin-top: .5em;
            }   
        }
    }
    

    @media (max-width: ${variables.tabletS}) {
        .tooltips-wrapper {
            display: flex;
            flex-wrap: wrap;

            .tooltip-block {
                display: flex;
                flex-direction: column-reverse;
                width: 100%;
                height: 2.5em;

                .label {
                    margin-bottom: .25em;
                }   
            }
        }
    }
`;
