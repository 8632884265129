import { connect } from 'react-redux';
import Component from './component';

import { list } from 'Redux/modules/admin/events/actions';

export default connect(
    state => ({
        events: state.adminEvents.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
        },
    }),
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
    })
)(Component);
