import { connect } from 'react-redux';
import Component from './component';

import {
    activate,
} from 'Redux/modules/admin/userOffers/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            activate: dispatch(activate),
        },
    }),
)(Component);