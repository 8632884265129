import {
    OFFER_TYPES,
    OFFER_VISIBILITIES,
    OFFER_DURATION_TYPES,
    OFFER_PERIOD_TYPES,
} from 'Consts/offers';

export const getTypeLabel = offer => {
    if(!offer) {
        return { found: false, label: 'Nieznany' };
    }

    const { type } = offer;
    const option = OFFER_TYPES.find(offerType => offerType.key === type);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label };
};

export const getVisibilityLabel = offer => {
    if(!offer) {
        return { found: false, label: 'Nieznany' };
    }

    const { visibility } = offer;
    const option = OFFER_VISIBILITIES.find(offerVisibility => offerVisibility.key === visibility);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label };
};

export const getDurationTypeLabel = offer => {
    if(!offer) {
        return { found: false, label: 'Nieznany' };
    }

    const { durationType } = offer;
    const option = OFFER_DURATION_TYPES.find(offerDurationType => offerDurationType.key === durationType);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label };
};

export const getPeriodTypeLabel = offer => {
    if(!offer) {
        return { found: false, label: 'Nieznany' };
    }

    const { periodType } = offer;
    const option = OFFER_PERIOD_TYPES.find(offerPeriodType => offerPeriodType.key === periodType);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label };
};

export const getName = (offer, withPrice = false) => {
    if(!offer) {
        return { found: false, label: 'Nieznany' };
    }

    const { name, totalPriceGross } = offer;
    if (!name) {
        return { found: false, label: 'Nieznana' };
    }

    if (withPrice) {
        return { found: true, label: name + ' - ' + totalPriceGross + ' zł' };
    }

    return { found: true, label: name };
};

export const getDisplayName = offer => {
    if(!offer) {
        return { found: false, label: 'Nieznany' };
    }

    const { displayName } = offer;
    if (!displayName) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: displayName };
}