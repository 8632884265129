import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layoutAdmin/modals/ModalWrapper';
import ModalBody from 'Components/layoutAdmin/modals/ModalBody';
import ModalTitle from 'Components/layoutAdmin/modals/ModalTitle';
import OfferConfirmation from 'Components/adept/userOffers/Confirmation';
import SpinnerComponent from 'Components/layoutAdmin/Spinner/component';

export default class AdminPaymentTransactionStatusModal extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        data: PropTypes.object,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        data: null,
    };
    state = {};

    render() {
        const { location, history, isOpen, onClose, data } = this.props;

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
                styleVariant="default"
            >
                <StyledComponent className="admin-modal-payment-transaction" styles={require('./styles')}>
                    <ModalBody styleVariant='default'>
                        <ModalTitle title='Status płatności' />
                        <div className="loading">
                            {!data && (
                                <SpinnerComponent />
                            )}
                            {data && (
                                <div className="data">
                                    <div className="row">
                                        <span className="label">Kod statusu:</span> <span className="value">{data.responseCode}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Oryginalny opis:</span> <span className="value">{data.originalResponseDescription}</span>
                                    </div>
                                    <div className="row">
                                        <span className="label">Opis:</span> <span className="value">{data.description}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
