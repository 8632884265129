import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/admin/userOffers/actions';
import {
    list as listCategories,
} from 'Redux/modules/admin/productCategories/actions';

export default connect(
    state => ({
        userOffers: state.adminUserOffers.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            listCategories: dispatch(listCategories),
        },
    })
)(Component);
