import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';

export default class PanelActivityLogsChangesEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        data: PropTypes.object,
        actions: PropTypes.shape({
            remove: PropTypes.func.isRequired,
        }).isRequired,
        onSuccess: PropTypes.func,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {
            ...this.props.data, //eslint-disable-line react/destructuring-assignment
        },
    };

    render() {
        const { location, history, data } = this.props;

        return (
            <StyledComponent
                className="panel-activity-logs-changes-editor"
                styles={require('./styles')}
            >
                {/* <ReactJsonViewCompare
                    oldData={data?.activityLog?.oldData}
                    newData={data?.activityLog?.newData}
                /> */}
            </StyledComponent>
        );
    }
}