import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Controls from 'Components/layout/panel/PaginatedListElementControls';

export default class AdminPaginatedListElement extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        image: PropTypes.shape({
            visible: PropTypes.bool.isRequired,
            url: PropTypes.string,
        }),
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        additionals: PropTypes.arrayOf(
            PropTypes.shape({
                visible: PropTypes.bool,
                name: PropTypes.string.isRequired,
                value: PropTypes.any,
            })
        ),
        controls: PropTypes.array,
        controlsType: PropTypes.string,
        stateType: PropTypes.oneOf(['', 'warning', 'alert', 'success']),
        disabled: PropTypes.bool,
        labels: PropTypes.arrayOf(
            PropTypes.shape({
                isVisible: PropTypes.bool.isRequired,
                label: PropTypes.string.isRequired,
                state: PropTypes.oneOf(['', 'warning', 'alert', 'success']),
            }),
        ),
        type: PropTypes.oneOf([
            'list', 'image',
        ]),
        description: PropTypes.string,
    };
    static defaultProps = {
        className: '',
        subtitle: null,
        labels: [],
        disabled: false,
        id: null,
        image: null,
        additionals: [],
        controls: [],
        stateType: '',
        additionalInfos: [],
        type: 'list',
        controlsType: 'default',
        description: null,
    };
    state = {
        
    };

    getListElementType = (type) => {
        const { id, image, title, subtitle, additionals, controls, labels, controlsType, description } = this.props;
        
        switch(type) {
            case 'list':
                return (
                    <div className="wrapper-block">
                        <div className="title-block">
                            {image && image.visible ?
                                <div className="image-content" id={`images-content-${id}`}>
                                    <img
                                        className="image"
                                        onMouseEnter={() => this.setState(() => ({ activeTooltip: image.url ? image.url : require('Theme/images/placeholders/image.jpg') }))}
                                        onMouseLeave={() => this.setState(() => ({ activeTooltip: null }))}
                                        style={{
                                            backgroundImage: `url(${image.url ? image.url : require('Theme/images/placeholders/image.jpg')})`,
                                        }}
                                    />
                                </div>
                                : null}
                            <div className="title-content">
                                {labels
                                    .filter(label => label.isVisible)
                                    .map((label, index) => (
                                        //eslint-disable-next-line react/no-array-index-key
                                        <div key={index} className={`element-label ${label.state}`}>
                                            {label.label}
                                        </div>
                                    ))}
                                <h3 className="title">{title}</h3>
                                {Boolean(subtitle) && <p className="subtitle">{subtitle}</p>}
                                {Boolean(description) && <p className="subtitle">{description}</p>}
                            </div>
                        </div>
                        <div className="details-block">
                            <div className="additionals-block">
                                {additionals
                                    .filter(additional => additional.visible !== false)
                                    .map(additional => (
                                        <p key={additional.name + additional.value} className="additional">
                                            <span className="additional-name">{additional.name}: </span>
                                            <span className="additional-value">{additional.value}</span>
                                        </p>
                                    ))}
                            </div>
                            <div className="controls-block">
                                <Controls controls={controls} controlsType={controlsType}/>
                            </div>
                        </div>
                    </div>
                );
            case 'image':
                return (
                    <div className="main-block">
                        <img className="background-block" src={image.url}/>
                        <div className="title-block">
                            <div className="title-content">
                                {labels
                                    .filter(label => label.isVisible)
                                    .map((label, index) => (
                                        //eslint-disable-next-line react/no-array-index-key
                                        <div key={index} className={`element-label ${label.state}`}>
                                            {label.label}
                                        </div>
                                    ))}
                                <h3 className="title">{title}</h3>
                                {Boolean(subtitle) && <p className="subtitle">{subtitle}</p>}
                                {Boolean(description) && <p className="subtitle">{description}</p>}
                            </div>
                        </div>
                        <div className="details-block">
                            <div className="additionals-block">
                                {additionals
                                    .filter(additional => additional.visible !== false)
                                    .map(additional => (
                                        <p key={additional.name + additional.value} className="additional">
                                            <span className="additional-name">{additional.name}: </span>
                                            <span className="additional-value">{additional.value}</span>
                                        </p>
                                    ))}
                            </div>
                            <div className="controls-block">
                                <Controls controls={controls} controlsType={controlsType}/>
                            </div>
                        </div>
                    </div>
                );
        }
    }

    render() {
        const { className, disabled, stateType, type } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'admin-paginated-list-element',
                    stateType,
                    className,
                    { disabled },
                    `type-${type}`
                )}
            >
                {this.getListElementType(type)}
            </StyledComponent>
        );
    }
}
