import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { USER_ROLE_TRAINER } from 'Consts/userRoles';
import { API_RESOURCE_USERS, API_RESOURCE_SPECIALIZATIONS, API_RESOURCE_LOCATIONS } from 'Consts/apiResources';

import { getFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import Select from 'Components/forms/MultiSelect';
import Input from 'Components/forms/Input';
import Spinner from 'Components/layoutAdmin/Spinner';

export default class AdminEventsCalendarUsersList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            listUsers: PropTypes.func.isRequired,
            listSpecializations: PropTypes.func.isRequired,
            listLocations: PropTypes.func.isRequired,
        }).isRequired,
        onMapFilters: PropTypes.func,
        predefinedQuery: PropTypes.object,
        activeUsers: PropTypes.array,
        onChangeActiveUsers: PropTypes.func,
        selectedSpecialization: PropTypes.object,
        selectedLocation: PropTypes.object,
        onChangeSpecialization: PropTypes.func,
        onChangeLocation: PropTypes.func,
        data: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
        onMapFilters: results => results,
        activeUsers: [],
        onChangeActiveUsers: () => null,
        selectedSpecialization: null,
        selectedLocation: null,
        onChangeSpecialization: () => null,
        onChangeLocation: () => null,
    };
    state = {
        search: '',
        users: [],
        specializations: [],
        locations: [],
        usersList: [],
        offerId: undefined,
    };

    componentDidMount = () => {    
        this.loadUsers();
        this.loadSpecializations();
        this.loadLocations();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { onChangeActiveUsers, selectedSpecialization, selectedLocation } = this.props;
        const { users, search } = this.state;

        if (JSON.stringify(selectedSpecialization) !== JSON.stringify(prevProps.selectedSpecialization)) {
            onChangeActiveUsers([]);
            this.loadUsers();
        }

        if (JSON.stringify(selectedLocation) !== JSON.stringify(prevProps.selectedLocation)) {
            onChangeActiveUsers([]);
            this.loadUsers();
        }

        if (
            JSON.stringify(users) !== JSON.stringify(prevState.users)
            || search !== prevState.search
        ) {
            this.filterUsers();
        }
    }

    onSelect = (user) => {
        const { onChangeActiveUsers, activeUsers } = this.props;

        if(activeUsers.find(activeUser => activeUser.id === user.id)) {
            onChangeActiveUsers(activeUsers.filter(activeUser => activeUser.id !== user.id));
        } else {
            onChangeActiveUsers([
                ...activeUsers,
                user,
            ]);
        }
    }

    filterUsers = () => {
        const { users, search } = this.state;

        let nextList = [...users];
        if(search) {
            nextList = users.filter(user => {
                const name = (user.name || '').toLowerCase();
                const surname = (user.surname || '').toLowerCase();
                const email = (user.email || '').toLowerCase();
                const query = (search || '').toLowerCase();

                return name.includes(query) || surname.includes(query) || email.includes(query);
            });
        }

        this.setState({ usersList: nextList });
    }

    loadSpecializations = () => {
        const { actions, data } = this.props;

        actions.listSpecializations({
            ignoreStore: true,
            page: 1,
            perPage: 9999,
            offerId: data?.offerId.value,
        }).then(response => {
            const specializations = response.payload[API_RESOURCE_SPECIALIZATIONS].elements;
            this.setState({ specializations });
        });
    }

    loadLocations = () => {
        const { actions } = this.props;

        actions.listLocations({
            ignoreStore: true,
            page: 1,
            perPage: 9999,
            enabled: true,
        }).then(response => {
            const locations = response.payload[API_RESOURCE_LOCATIONS].elements;
            this.setState({ locations });
        });
    }

    loadUsers = () => {
        const { actions, selectedSpecialization, selectedLocation } = this.props;
    
        actions.listUsers({ 
            page: 1,
            perPage: 9999,
            ignoreStore: true,
            role: USER_ROLE_TRAINER,
            specializationId: selectedSpecialization,
            locationId: selectedLocation,
            enabled: true,
        }).then(response => {
            const elements = response.payload[API_RESOURCE_USERS].elements;
            this.setState({ users: elements });
        });
    }

    render() {
        const { activeUsers, selectedSpecialization, onChangeSpecialization, selectedLocation, onChangeLocation } = this.props;
        const { usersList, specializations, locations, search } = this.state;

        return (
            <StyledComponent
                className="admin-events-calendar-users-list"
                styles={require('./styles')}
            >
                <div className="filters">
                    <div className="filter">
                        <div className="filter">
                            <Select
                                label="Wybierz specjalizację"
                                name="specialization"
                                options={[
                                    ...specializations.map(specialization => ({
                                        value: specialization.id,
                                        label: specialization.name,
                                    })),
                                ]}
                                value={selectedSpecialization && {
                                    label: selectedSpecialization.label,
                                    value: selectedSpecialization.id,
                                }}
                                onChange={({ value }) => onChangeSpecialization(value)}
                            />
                            <Select
                                label="Wybierz lokalizację"
                                name="location"
                                options={[
                                    ...locations.map(location => ({
                                        value: location.id,
                                        label: location.name,
                                    })),
                                ]}
                                value={selectedLocation && {
                                    label: selectedLocation.label,
                                    value: selectedLocation.id,
                                }}
                                onChange={({ value }) => onChangeLocation(value)}
                            />
                        </div>
                        <Input
                            label="Szukaj"
                            name="search"
                            value={search}
                            onChange={({ value }) => this.setState({ search: value })}
                        />
                    </div>
                </div>
                <div className="list">
                    {usersList.map(user => (
                        <div 
                            className={classnames(
                                'list-element',
                                { selected: activeUsers.find(activeUser => activeUser.id === user.id) }
                            )}
                            key={user.id}
                            onClick={() => this.onSelect(user)}
                        >
                            <span className="list-element-indicator" />
                            <div className="list-element-title">
                                {getFullName(user).label}
                            </div>
                        </div>
                    ))}
                </div>
            </StyledComponent>
        );
    }
}
