import { connect } from 'react-redux';
import Component from './component';

import {
    income,
} from 'Redux/modules/admin/userOffers/actions';

export default connect(
    state => ({
        userOffers: state.adminUserOffers.incomeList,
    }),
    dispatch => ({
        actions: {
            income: dispatch(income),
        },
    })
)(Component);
