import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .big-calendar-wrapper {
        .rbc-calendar {
            .rbc-month-view {
                min-height: 40em;
            }
            .rbc-agenda-view {
                min-height: 40em;
            }
        }
    }
`;
