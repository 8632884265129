import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layoutAdmin/modals/ModalWrapper';
import ModalBody from 'Components/layoutAdmin/modals/ModalBody';
import ModalTitle from 'Components/layoutAdmin/modals/ModalTitle';
import EventEditor from 'Components/admin/events/Editor';

export default class AdminEventsModalEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        data: PropTypes.object,
        predefinedState: PropTypes.object,
        onRemove: PropTypes.func,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        data: null,
        predefinedState: {},
        selectedUserId: '',
    };
    state = {};

    render() {
        const { location, history, isOpen, onClose, data, predefinedState, onRemove } = this.props;

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
            >
                <StyledComponent className="admin-events-modal-editor" styles={require('./styles')}>
                    <ModalBody>
                        <ModalTitle title="Wydarzenie" />
                        <EventEditor
                            location={location}
                            history={history}
                            data={data}
                            predefinedState={predefinedState}
                            displayModeModal={true}
                            onRemove={onRemove}
                        />
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
