import { connect } from 'react-redux';
import Component from './component';

import {
    payment,
} from 'Redux/modules/adept/userOffers/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            payment: dispatch(payment),
        },
    }),
)(Component);