import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';

const PanelEventsCalendarEvent = (event) => { 
    return (
        <StyledComponent
            className={classnames(
                'admin-events-calendar-event',
                `state-${event?.event?.stateColor}`
            )}
            styles={require('./styles')}
        >
            <strong> {event?.title} </strong>
        </StyledComponent>
    );
};

PanelEventsCalendarEvent.propTypes = {
    event: PropTypes.shape({
        stateColor: PropTypes.oneOf([
            'private',
            'done',
            'notDone',
            'notDoneExpired',
            'plannedPaid',
            'plannedNotPaid',
            'canceled',
        ]).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
};

PanelEventsCalendarEvent.defaultProps = {};

export default PanelEventsCalendarEvent;