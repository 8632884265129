import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import { BIG_CALENDAR_DEFAULT_STEP, BIG_CALENDAR_DEFAULT_TIMESLOTS } from 'Consts/bigCalendar';

import StyledComponent from 'Components/core/StyledComponent';

require('moment/locale/pl.js');
moment.locale('pl', {
    week: {
        dow: 1,
    },
});

export default class PanelBigCalendar extends Component {
    static propTypes = {
        calendarProps: PropTypes.object,
    };
    static defaultProps = {
        calendarProps: {},
    };
    state = {
        width: 0,
        height: 0,
    }

    calendarRef = React.createRef();

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('touchmove', this.touchScrollWhenSelectedSlot);
    }
    
    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('touchmove', this.touchScrollWhenSelectedSlot);
    }

    updateWindowDimensions = () => {
        this.setState({ 
            width: window.innerWidth,
            height: window.innerHeight,
            defaultView: window.innerWidth >= 768 ? 'week' : 'day',
        });
    };

    touchScrollWhenSelectedSlot = (event) => {
        if (document.querySelector('.rbc-slot-selection')) { // scroll only when selected slot 
            const clientY = event.touches[0].clientY;
            const pageYOffset = event.view.pageYOffset;
            const innerHeight = event.view.innerHeight;

            if (innerHeight - clientY < 50) {
                window.scrollTo(0, pageYOffset + 15);
            }

            if (clientY < 50) {
                window.scrollTo(0, pageYOffset - 15);
            }
        }
    }

    render() {
        const { calendarProps } = this.props;
        const { width } = this.state;

        const localizerObject = momentLocalizer(moment);
        localizerObject.startOfWeek = function startOfWeek() { return 1; };
        const defaultCalendarProps = {
            localizer: localizerObject,
            showMultiDayTimes: true, 
            formats: {
                timeGutterFormat: 'HH:mm',
                dayFormat: 'DD.MM',
                agendaDateFormat: 'YYYY-MM-DD',
                agendaTimeFormat: 'HH:mm',
                eventTimeRangeFormat: ({ start, end }, culture, local) => local.format(start, 'HH:mm', culture) + ' – ' + local.format(end, 'HH:mm', culture),
                agendaTimeRangeFormat: ({ start, end }, culture, local) => local.format(start, 'HH:mm', culture) + ' – ' + local.format(end, 'HH:mm', culture),
            },
            messages: {
                day: 'Dzień',
                week: 'Tydzień',
                month: 'Miesiąc',
                today: 'Dzisiaj',
                previous: 'Poprzedni',
                next: 'Następny',
            },
            timeslots: BIG_CALENDAR_DEFAULT_TIMESLOTS,
            step: BIG_CALENDAR_DEFAULT_STEP,
            ref: this.calendarRef,
            ...calendarProps,
        };

        return (
            <StyledComponent
                styles={require('./styles')}
                className="panel-big-calendar"
            >
                <StyledComponent
                    styles={require('./bigCalendar.styles')}
                    className="big-calendar-wrapper"
                >
                    {width >= 768 && (
                        <Calendar
                            defaultView='week'
                            {...defaultCalendarProps}
                        />
                    )}
                    {width < 768 && (
                        <Calendar
                            defaultView='day'
                            {...defaultCalendarProps}
                        />
                    )}
                </StyledComponent>
            </StyledComponent>
        );
    }
}
