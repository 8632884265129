import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import ValuesList from 'Components/layoutAdmin/panel/ValuesList';

export default class AdminEventsStats extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            stats: PropTypes.func.isRequired,
        }).isRequired,
        stats: PropTypes.object,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        paidStatus: undefined,
        doneStatus: undefined,
        planned: undefined,
        startAtFrom:  undefined,
        startAtTo:  undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.stats({ ...queryObject });
        }, 500);
    }

    render() {
        const { stats } = this.props;

        return (
            <StyledComponent
                className="panel-events-stats"
                styles={require('./styles')}
            >
                {stats && stats.data && (
                    <ValuesList 
                        elements={Object.keys(stats.data)
                            .map(statKey => ({
                                key: statKey,
                                label: stats.data[statKey].label,
                                value: stats.data[statKey].value,
                                type: stats.data[statKey].type,
                            }))}
                    />
                )}
            </StyledComponent>
        );
    }
}
