import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        &.type-list {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            padding: 1em;
            background-color: #ffffff;
            border-bottom: 1px solid #EEE;
            width: 100%;

            &:nth-of-type(odd) {
                background:  #f9f9f9;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &.success {
                box-shadow: inset 0 0 .1em .05em #9dfe37;
            }
            &.warning {
                box-shadow: inset 0 0 .1em .05em #fecc37;
            }
            &.alert {
                box-shadow: inset 0 0 .1em .05em #f44336;
            }
            &.disabled {
                box-shadow: 0.5em 0 0 0 red inset;
            }

            .wrapper-block {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .title-block {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .image-content {
                    width: 5em;
                    height: 5em;
                    position: relative;
                    margin-right: 1em;

                    .image {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }

                .images-slider {
                    position: relative;
                    width: 350px;
                    height: 230px;

                    img {
                        width: auto;
                        height: 230px;
                    }
                }
                .title-content {
                    margin-left: 1em;

                    .element-label {
                        font-weight: 900;
                        font-size: 1em;
                        padding: .25em .5em;
                        background-color: #333333;
                        color: white;
                        display: inline-block;
                        border-radius: .5em;
                        margin-bottom: .5em;
                        margin-right: .5em;

                        &.success {
                            background-color: ${variables.dpc_backgroundGreen};
                        }
                        &.warning {
                            background-color: #fecc37;
                        }
                        &.alert {
                            background-color: #f44336;
                        }
                        &.informational {
                            background-color: #fe9e37;
                        }
                    }
                    .title {
                        font-size: 1.1em;
                        color: #333333;
                        line-height: 120%;

                        .title-link {
                            font-size: 60%;
                            margin-left: .75em;
                            color: #666666;
                            vertical-align: middle;
                        }
                    }
                    .subtitle {
                        font-size: .8em;
                        color: ${variables.dpc_fontDark};
                    }
                }
            }
            .details-block {
                display: flex;
                align-items: stretch;
                justify-content: flex-end;

                .additionals-block {
                    padding: 1em 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;

                    .additional {
                        margin-bottom: .5em;
                        font-size: .9em;
                        text-align: right;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        .additional-name {
                            color: #999999;
                        }
                        .additional-value {
                            color: #666666;
                        }
                    }
                }
                .controls-block {
                    margin-left: 1em;
                }
            }
        }

        &.type-image {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            background-color: transparent !important;
            border-bottom: none !important;
            width: 25em;
            height: 25em;
            margin: 1em;
            transition: .3s all;

            &:hover {
                transform: scale(1.1);

                .main-block {
                    border: 1px solid #DA3143;
                    
                    .background-block {
                        filter: brightness(0.7);
                    }
                }
            }

            &:nth-of-type(odd) {
                background: transparent !important;
            }

            &:last-of-type {
                
            }

            &.success {
                box-shadow: inset 0 0 .1em .05em #9dfe37;
            }
            &.warning {
                box-shadow: inset 0 0 .1em .05em #fecc37;
            }
            &.alert {
                box-shadow: inset 0 0 .1em .05em #f44336;
            }
            &.disabled {
                box-shadow: 0.5em 0 0 0 red inset;
            }

            .main-block {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
                background: none;

                .background-block {
                    position: absolute;
                    width: 100%;
                    z-index: -1;
                    filter: brightness(0.4);
                }
                
                .title-block {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: center;
                    padding: 1em;

                    .image-content {
                        width: 5em;
                        height: 5em;
                        position: relative;
                        margin-right: 1em;

                        .image {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }

                    .images-slider {
                        position: relative;
                        width: 350px;
                        height: 230px;

                        img {
                            width: auto;
                            height: 230px;
                        }
                    }
                    .title-content {
                        margin-left: 0em;
                        text-transform: uppercase; 

                        .element-label {
                            font-weight: 900;
                            font-size: 1em;
                            padding: .25em .5em;
                            background-color: #333333;
                            color: white;
                            display: inline-block;
                            border-radius: .5em;
                            margin-bottom: .5em;
                            margin-right: .5em;

                            &.success {
                                background-color: ${variables.dpc_backgroundGreen};
                            }
                            &.warning {
                                background-color: #fecc37;
                            }
                            &.alert {
                                background-color: #f44336;
                            }
                        }
                        .title {
                            font-size: 1.25em;
                            color: #333333;
                            line-height: 120%;
                            font-weight: 600;

                            .title-link {
                                font-size: 60%;
                                margin-left: .75em;
                                color: #666666;
                                vertical-align: middle;
                            }
                        }
                        .subtitle {
                            font-size: .8em;
                            color: #999999;

                            &.text-light {
                                font-color: white;
                            }
                        }
                    }
                }
                .details-block {
                    display: flex;
                    align-items: stretch;
                    justify-content: flex-end;
                    flex-direction: column;

                    .additionals-block {
                        padding: 1em 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .additional {
                            margin-bottom: .5em;
                            font-size: .9em;
                            text-align: center;

                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            .additional-name {
                                color: #999999;
                            }
                            .additional-value {
                                color: #666666;
                            }
                        }
                    }
                    .controls-block {
                        display: flex;
                        justify-content: center;
                        margin-left: 0em;
                        margin-bottom: 2em;
                    }
                }
            }
        }
    `;
