import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USER_OFFER_PRODUCTS_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { formatPrice } from 'Utils/math';
import { getName as getProductName, getDurationTypeLabel } from 'Utils/product';
import { translateTimeUnit } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelUserOfferProductsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        userOfferProducts: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...this.props.predefinedQuery,  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { userOfferProducts, location, history } = this.props;

        return (
            <StyledComponent
                className="panel-user-offer-product-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userOfferProducts}
                    title="Lista produktów zamówienia"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        element && (
                            <PaginatedListElement
                                key={element?.id}
                                title={element?.product && getProductName(element?.product)?.label || 'Brak'}
                                additionals={[{
                                    name: 'Cena brutto',
                                    value: formatPrice(element?.priceGross),
                                }, {
                                    name: 'Stawka VAT',
                                    value: `${element?.product?.vatPercent}%` || 'Brak',
                                }, {
                                    name: 'Ilość',
                                    value: element?.quantity,
                                }, {
                                    name: 'Typ trwania',
                                    value: getDurationTypeLabel(element?.product)?.label,
                                }, {
                                    name: 'Wartość trwania',
                                    value: element?.product?.durationValue,
                                }, {
                                    name: 'Wyświetlana wartość trwania',
                                    value: element?.product?.displayDurationValue,
                                }, {
                                    name: 'Typ wyświetlanej wartości trwania',
                                    value: translateTimeUnit(element?.product),
                                }]}
                                controls={[{
                                    type: 'button',
                                    label: 'Zarządzaj',
                                    visible: true,
                                    to: withVariables(ADMIN_USER_OFFER_PRODUCTS_MANAGE.path, { id: element?.id }),
                                }]}
                            />
                        )
                    )}
                    filters={[{
                        name: 'search',
                        label: 'Szukaj',
                        type: 'text',
                    }]}
                />
            </StyledComponent>
        );
    }
}
