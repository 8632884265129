import { connect } from 'react-redux';
import Component from './component';

import {
    list,
    transfer,
} from 'Redux/modules/admin/users/actions';

export default connect(
    state => ({
        users: state.adminUsers.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            transfer: dispatch(transfer),
        },
    })
)(Component);
